import {environment} from "../../../../environments/environment";
import {CaseFileModel} from "../../incident-map/models/incident/case-file/case-file.model";
import {Injectable, NgZone} from "@angular/core";
import {map, Observable} from "rxjs";
import {EventSourceService} from "../../../core/services/event-source.service";
import {RobotStatusEnum} from "../../../core/config/enum/robot-status.enum";
import {ChecksModel, HealthCheckStepModel} from "../model/health-check-step.model";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class HealthCheckEventService extends EventSourceService {

  API_URL = `${environment.apiUrl}/robot/health-check`;
  caseFile: CaseFileModel;

  constructor(zone: NgZone, router: Router) {
    super(zone, router)
  }


  checkProcess(id:any,nextMode:RobotStatusEnum): Observable<HealthCheckStepModel|ChecksModel[]> {
    return this.connectToServerSentEvents(`${this.API_URL}/process/${id}/sse?nextMode=${nextMode}`, {}, ['HEALTH_CHECK_STEPS','HEALTH_CHECK_PROCESS']).pipe(
      map((res: any) => {
        let parsedData = JSON.parse(res.data)
        if(parsedData.checks && Array.isArray(parsedData.checks))
          return parsedData.checks
        return parsedData
      })
    )
  }
}
