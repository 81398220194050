import {Injectable} from '@angular/core';
import {interval, map, Observable} from 'rxjs';
import {decreaseTimeByOneSecond} from '../config/helper'

@Injectable({
  providedIn: 'root'


})
export class TimerService {
  private readonly defaultDuration = 40000;
  interval: any;
  decreaseTimeByOneSecond = decreaseTimeByOneSecond
  currentTime$: Observable<Date>;

  constructor() {
    this.currentTime$ = interval(1000).pipe(
      map(() => new Date())
    );
  }

  startTimerAndStoreRemainingTime() {
    let formattedTime: any
    if (!localStorage.getItem('remainingTime') || localStorage.getItem('remainingTime') == '00:00') {


      let futureTime = new Date(new Date().getTime() + this.defaultDuration);
      const currentTime = new Date();
      const timeDiffInMillis = futureTime.getTime() - currentTime.getTime();
      const remainingSeconds = Math.floor(timeDiffInMillis / 1000);
      const minutes = Math.floor(remainingSeconds / 60);
      const seconds = remainingSeconds % 60;
      formattedTime = `${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`;

    } else {
      formattedTime = localStorage.getItem('remainingTime')
    }

    this.interval = setInterval(() => {
      formattedTime = decreaseTimeByOneSecond(formattedTime)
      localStorage.setItem('remainingTime', formattedTime);
      if (formattedTime == '00:00') {
        clearInterval(this.interval);
      }
    }, 1000);


    return
  }
}

