import {TableService} from "../../../core/services/table.service";
import {environment} from "../../../../environments/environment";
import {CaseFileModel} from "../../incident-map/models/incident/case-file/case-file.model";
import {Inject, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {TableHTTPService} from "../../../core/services/table-http/table-http.service";
import {Observable} from "rxjs";
import {RobotModel} from "../model/robot.model";

@Injectable({
  providedIn: 'root'
})
export class RobotsService extends TableService<RobotModel> {

  API_URL = `${environment.apiUrl}/robot/robots`;
  caseFile: CaseFileModel;

  constructor(@Inject(HttpClient) http: HttpClient,
              @Inject(TableHTTPService) protected tablehttpservice: TableHTTPService<RobotModel>) {
    super(http, tablehttpservice);
  }

  getRobotDetail(id: any): Observable<any> {
    return this.http.get(`${this.API_URL}/${id}`);
  }

  getMyRobots(): Observable<any> {

    return this.http.get(`${this.API_URL}/my-robots`);
  }

  getMyCameras(id: string): Observable<any> {
    return this.http.get(`${this.API_URL}/cameras/${id}`)
  }

}
