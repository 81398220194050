import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PreviousUrlService {
  private previousUrl: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  public previousUrl$: Observable<string | null> = this.previousUrl.asObservable();

  setPreviousUrl(previousUrl: string) {
    this.previousUrl.next(previousUrl);
  }

  getPreviousUrl() {
    return this.previousUrl.value;
  }
}

