import {environment} from "../../../../environments/environment";
import {Injectable, NgZone} from "@angular/core";
import {map, Observable} from "rxjs";
import {EventSourceService} from "../../../core/services/event-source.service";
import {ActiveUsersInterface} from "../model/robot.model";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class ActiveUsersEventService extends EventSourceService {

  API_URL = `${environment.apiUrl}/robot/robots`;

  constructor(zone: NgZone, router: Router) {
    super(zone, router);
  }

  getRobotActiveUsere(id: string): Observable<ActiveUsersInterface[]> {
    return this.connectToServerSentEvents(`${this.API_URL}/get-active-users/${id}/sse`, {}, ['robot-active-users']).pipe(
      map((res: any) => {
        return JSON.parse(res.data) as ActiveUsersInterface[];
      })
    )
  }
}
