import {Component, Injector, OnInit} from "@angular/core";
import {BaseComponent} from "./base.component";
import {UIState} from "./models/ui-state.model";
import {NgbActiveModal, NgbModal, NgbOffcanvas} from "@ng-bootstrap/ng-bootstrap";
import {FormBuilder} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {ToastrService} from "ngx-toastr";
import {UserService} from "../services/user.service";
import {UploadImgService} from "../services/upload-img.service"
import {VerificationEmailService} from '../services/verification-email.service'
import {TimerService} from '../services/timer.service'
import {AuthService} from '../../modules/auth/services/auth.service'
import {LayoutService} from "../../_metronic/layout";
import {WeatherService} from "../services/weather.service";
import {IncidentService} from "../../modules/incident-map/services/incident.service";
import {SurveillanceService} from "../../modules/areas-sensitivity/services/surveillance.service";
import {DataService} from "../../modules/incident-map/services/data.service";
import {NotificationService} from "../services/notification.service";
import {TranslationService} from "../../modules/i18n";
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {distinctUntilChanged, filter} from 'rxjs/operators';
import {MessagingService} from "../services/messaging.service";
import {Client} from "../config/client";
import {IncidentStatusEnum} from "../config/enum/status.enum";
import {AlarmService} from "../../modules/alarm/services/alarm.service";
import {GoogleService} from "../services/google.service";
import {SplashScreenService} from "../../_metronic/partials";
import {NavigationEnd} from "@angular/router";
import {PreviousUrlService} from "../services/previous-url.service";
import {HttpCancelService} from "../services/http-cancel.service";
import {PersonService} from "../../modules/incident-map/services/person.service";
import {TrackService} from "../../modules/incident-map/services/track.service";
import {PtzSocketService} from "../socket/ptz-socket.service";
import {ConfigKey, ConfigService, ConfigType} from "../services/config.service";
import {ConfigArrayModel} from "./models/config-array.model";
import {convertUTCDateToLocalDate} from "../config/helper";
import {MapBoxService} from "../services/map-box.service";
import {DomService} from "../../modules/tele-operator/services/dom.service";
import {DomSanitizer} from "@angular/platform-browser";
import {RobotsEventService} from "../../modules/robots/services/robots.events.service";
import {RobotsService} from "../../modules/robots/services/robots.service";
import {HealthCheckEventService} from "../../modules/robots/services/health-check.events.service";
import {decodeData, encodeData} from "../config/helper";
import {ActiveUsersEventService} from "../../modules/robots/services/active-users.event.service";


@Component({
    template: ''
})
export class BaseDashboardComponent<U extends UIState = UIState> extends BaseComponent implements OnInit {
    configArray: ConfigArrayModel[] = [];

    monthAbbreviation: any = null;

    firstDay: any;

    lastDay: any;

    id: any;

    decodedData: any;

    isLoading = false;

    returnUrl: string;

    selectedLanguage: string;

    Breakpoints = Breakpoints;

    currentBreakpoint: string = '';

    currentUrl: string = '';

    zoom: number = 10;

    center: any = null;

    public modalService: NgbModal;
    public activeModal:NgbActiveModal;

    public translate: TranslateService;

    protected fb: FormBuilder;

    public offCanvasService: NgbOffcanvas;

    public toastrService: ToastrService;

    public userService: UserService;

    public uploadImgService: UploadImgService

    public verificationEmailService: VerificationEmailService

    public timerService: TimerService

    public authService: AuthService

    public layout: LayoutService;

    public weatherService: WeatherService;

    public incidentService: IncidentService;

    public surveillanceService: SurveillanceService;

    public dataService: DataService;

    public notificationService: NotificationService;

    public translationService: TranslationService;

    public breakpointObserver: BreakpointObserver;

    public messagingService: MessagingService;

    public alarmService: AlarmService;

    public googleMapService: GoogleService;

    public timeService: TimerService;

    public splashService: SplashScreenService;

    public previousService: PreviousUrlService;

    public cancelService: HttpCancelService;

    public personService: PersonService;

    public trackService: TrackService;

    public ptzService: PtzSocketService;

    public configService: ConfigService;

    public mapBoxService: MapBoxService;

    public domService: DomService;

    public sanitizer: DomSanitizer;

    public  robotsEventService : RobotsEventService

    public  healthCheckEventService : HealthCheckEventService

  public activeUsersEventService: ActiveUsersEventService

  public robotsService: RobotsService

  ngOnInit(): void {
    super.ngOnInit();
    this.checkConfigArray();
    this.getId();
    let breakpoint$ = this.breakpointObserver
      .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small, '(min-width: 500px)'])
      .pipe(
        distinctUntilChanged()
      );
    breakpoint$.subscribe(() =>
      this.breakpointChanged()
    );
    this.currentUrl = this.router.url;
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe((event) => {
      const navigationEndEvent = event as NavigationEnd;
      this.previousService.setPreviousUrl(this.currentUrl);
      this.currentUrl = navigationEndEvent.url;
    });
  }

    checkConfigArray() {
        if (this.configArray.length > 0) {
            for (let index = 0; index < this.configArray.length; index++) {
                switch (this.configArray[index].name) {
                    case 'zoom':
                        this.zoom = this.configService.getValueFromKey(this.configArray[index].configKey, this.configArray[index].configType);
                        break;
                    case 'center':
                        this.center = this.configService.getValueFromKey(this.configArray[index].configKey, this.configArray[index].configType);
                        break;
                    default:
                        break;
                }
            }
        }
    }

    constructor(protected injector: Injector) {
        super(injector);
        this.modalService = this.injector.get(NgbModal);
        this.activeModal = this.injector.get(NgbActiveModal);
        this.fb = this.injector.get(FormBuilder);
        this.translate = this.injector.get(TranslateService);
        this.offCanvasService = this.injector.get(NgbOffcanvas);
        this.toastrService = this.injector.get(ToastrService);
        this.userService = this.injector.get(UserService);
        this.uploadImgService = this.injector.get(UploadImgService)
        this.verificationEmailService = this.injector.get(VerificationEmailService)
        this.timerService = this.injector.get(TimerService)
        this.authService = this.injector.get(AuthService)
        this.layout = this.injector.get(LayoutService);
        this.weatherService = this.injector.get(WeatherService);
        this.incidentService = this.injector.get(IncidentService);
        this.surveillanceService = this.injector.get(SurveillanceService);
        this.dataService = this.injector.get(DataService);
        this.notificationService = this.injector.get(NotificationService);
        this.translationService = this.injector.get(TranslationService);
        this.breakpointObserver = this.injector.get(BreakpointObserver);
        this.messagingService = this.injector.get(MessagingService);
        this.alarmService = this.injector.get(AlarmService);
        this.selectedLanguage = this.translationService.getSelectedLanguage();
        this.googleMapService = this.injector.get(GoogleService);
        this.timeService = this.injector.get(TimerService);
        this.splashService = this.injector.get(SplashScreenService);
        this.cancelService = this.injector.get(HttpCancelService);
        this.personService = this.injector.get(PersonService);
        this.trackService = this.injector.get(TrackService);
        this.configService = this.injector.get(ConfigService);
        this.mapBoxService = this.injector.get(MapBoxService);
        this.domService = this.injector.get(DomService);
        this.sanitizer = this.injector.get(DomSanitizer);
        this.monthAbbreviation = this.datePipe.transform(new Date(), 'MMM')?.slice(0, 3);
        const year = new Date().getFullYear();
        const month = new Date().getMonth() + 1;
        this.firstDay = this.datePipe.transform(new Date(year, month - 1, 1), 'dd MMM');
        this.lastDay = this.datePipe.transform(new Date(year, month, 0), 'dd MMM');
        this.messagingService.currentMessage.subscribe(
            (res: any) => {
                if (res && res.data) {
                    this.clearData(res)
                }
            }
        );
        this.previousService = injector.get(PreviousUrlService);
        this.ptzService = injector.get(PtzSocketService);
        this.robotsEventService = injector.get(RobotsEventService);
        this.healthCheckEventService = injector.get(HealthCheckEventService);
        this.robotsService = injector.get(RobotsService);
      this.activeUsersEventService = injector.get(ActiveUsersEventService);
    }


    toastSuccess(title: string, message: string) {
        this.toastrService.success(message, title, {
            positionClass: 'toast-top-right',
        });
    }

    toastWarning(title: string, message: string) {
        this.toastrService.warning(message, title, {
            positionClass: 'toast-top-right',
        })
    }

    toastError(title: string, message: string) {
        this.toastrService.error(message, title, {
            positionClass: 'toast-top-right',
        })
    }

    getWordTranslate(key: string): string {
        let translateWord = '';
        this.translateService.get(key).subscribe(
            (res: string) => {
                translateWord = res;
            }
        )
        return translateWord;

    }

  calculateTimeDifference(currentDate: Date, pastDate: Date) {
    pastDate = convertUTCDateToLocalDate(new Date(pastDate));

    const millisecondsDiff = currentDate.getTime() - pastDate.getTime();
    const secondsDiff = Math.floor(millisecondsDiff / 1000); // Convert to seconds
    if (secondsDiff < 60) {
      if (secondsDiff < 0) {
        return `1 ${this.selectedLanguage === 'ar' ? 'دقيقة مضت' : 'm ago'}`;
      }
      return `${secondsDiff} ${this.selectedLanguage === 'ar' ? 'ثانية مضت' : 's ago'}`;
    } else if (secondsDiff < 3600) {
      const minutesDiff = Math.floor(secondsDiff / 60);
      return `${minutesDiff} ${this.selectedLanguage === 'ar' ? 'دقيقة مضت' : 'm ago'}`;
    } else if (secondsDiff < 86400) {
      const hoursDiff = Math.floor(secondsDiff / 3600);
      return `${hoursDiff} ${this.selectedLanguage === 'ar' ? 'ساعة مضت' : 'hours ago'}`;
    } else {
      const daysDiff = Math.floor(secondsDiff / 86400);
      return `${daysDiff} ${this.selectedLanguage === 'ar' ? 'يوم مضى' : 'days ago'}`;
    }
  }

    getId() {
        this.id = this.route.snapshot.paramMap.get('id');
    }
    getDecodedData() {
        this.decodedData = JSON.parse(decodeData(this.route.snapshot.paramMap.get('data') as string));
    }

    private breakpointChanged() {
        if (this.breakpointObserver.isMatched(Breakpoints.Large)) {
            this.currentBreakpoint = Breakpoints.Large;
        } else if (this.breakpointObserver.isMatched(Breakpoints.Medium)) {
            this.currentBreakpoint = Breakpoints.Medium;
        } else if (this.breakpointObserver.isMatched(Breakpoints.Small)) {
            this.currentBreakpoint = Breakpoints.Small;
        } else if (this.breakpointObserver.isMatched('(min-width: 500px)')) {
            this.currentBreakpoint = 'default';
        }
        this.cdRef.detectChanges();
    }

    clearData(data: any) {

    }

    refillData() {
    }

    /**
     *
     * @param latitude
     * @param longitude
     * @param content
     */
    addAdvanceMarker(latitude: number,
                     longitude: number,
                     content: Element): Promise<google.maps.marker.AdvancedMarkerElement | null> {
        return this.googleMapService.addAdvancedMarker(
            latitude,
            longitude,
            content)
    }

    setDefaultImage(event: any, image: string) {
        event.target.src = image;
    }


    protected readonly Client = Client;
    protected readonly IncidentStatusEnum = IncidentStatusEnum;
    protected readonly ConfigKey = ConfigKey;
    protected readonly ConfigType = ConfigType;

}
