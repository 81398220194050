import * as SLUGS from '../../../core/config/slugs';
import {formUrl} from "../../../core/config/helper";

export const SURVEILLANCE_CLIENT = {
  mapWithCamera: () => formUrl(SLUGS.SURVEILLANCE),
  patrol: () => formUrl(SLUGS.SURVEILLANCE, SLUGS.PATROL),
  cameraPinnedList: () => formUrl(SLUGS.SURVEILLANCE, SLUGS.PINNED_LIST),
  filteredCameras: () => formUrl(SLUGS.SURVEILLANCE, SLUGS.FILTERED_CAMERAS),
  patrolDetail: () => formUrl(SLUGS.SURVEILLANCE, SLUGS.PATROL, SLUGS.PATROL_DETAIL)
};
