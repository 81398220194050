import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class DomService {
  constructor(public http: HttpClient) {
  }

  getElement(id: string): HTMLElement | null {
    return document.getElementById(id);
  }

  getTextFromSvgAssets(url: string) {
    return this.http.get(url, {responseType: 'text'})
  }

  addCssClassToElement(id: string, cssClass: string) {
    let element = document.getElementById(id);
    if (element)
      element.classList.add(cssClass)
  }

  removeCssClassToElement(id: string, cssClass: string) {
    let element = document.getElementById(id);
    if (element)
      element.classList.remove(cssClass)
  }

  changeTextForElement(id: string, text: string) {
    let element = document.getElementById(id);
    if (element)
      element.textContent = text
  }

  changeColorForElement(id: string, color: string) {
    let element = document.getElementById(id);
    if (element)
      element.style.fill = `${color}`;
  }

  changeTransformForElement(id: string, value: string) {
    let element = document.getElementById(id);
    if (element)
      element.style.transform = `${value}`;
  }

  changeScaleForElement(id: string, currentValue: any, value: any) {
    let element = document.getElementById(id);
    if (element) {
      let scale = element?.getAttribute('transform');
      element.setAttribute('transform', scale ? scale.replace(`scale(${currentValue})`, `scale(${value})`) : '')
    }

  }

  changeOpacity(id: string, opacity: number) {
    let element = document.getElementById(id);
    if (element)
      element.style.opacity = `${opacity}`;
  }

  changeWidthForPath(id: string, newWidth: number, minWidth: number, maxWidth: number) {
    const svgPath = document.getElementById(id);

    if (svgPath instanceof SVGPathElement) {
      const currentD = svgPath.getAttribute("d");

      if (currentD) {
        const pathCommands = currentD.split("H");
        if (pathCommands.length > 1) {
          const splitSecondPart = pathCommands[1].split('a');
          splitSecondPart[0] = this.getDefaultValue(newWidth, minWidth, maxWidth).toString();
          pathCommands[1] = splitSecondPart.join("a");
          const newD = pathCommands.join('H');
          svgPath.setAttribute("d", newD);
        }
      } else {
        console.error("The 'd' attribute does not exist");
      }
    } else {
      console.error("SVG path element not found");
    }
  }

  getDefaultValue(value: number, minValue: number, maxValue: number) {
    if (value < minValue) {
      value = minValue;
    }
    if (value > maxValue) {
      value = maxValue;
    }
    return value;
  }

  addFilter(id: string, filter: string) {
    this.getElement(id)?.setAttribute('filter', filter)
  }
}
