export class CaseFileModel {
  criminals: number;
  suspects: number;
  victims: number;
  witnesses: number;
  peopleOfInterest: number;
  footage: number;
  weapons: number;
  objectOfInterest: number;
  vehicles: number;
}

export class CaseFileTimelineModel {
  incidentDate: string;
  incidentId: number;
  footage: FootageModel[];
}

export class FootageModel {
  path: string;
  date: string;
  type: string;

}
