import * as SLUGS from '../../../core/config/slugs';
import {formUrl} from "../../../core/config/helper";

export const MISSIONS_CLIENT = {
  missionsList: () => formUrl(SLUGS.MISSIONS),
  missionDesign: () => formUrl(SLUGS.MISSIONS, SLUGS.MISSION_DESIGN),
  mapDetails: () => formUrl(SLUGS.MISSIONS, SLUGS.MAP_DETAILS),
  mapEditor: () => formUrl(SLUGS.MISSIONS, SLUGS.MAP_DETAILS)

};

