<!--begin::Logo-->
<app-sidebar-logo class="app-sidebar-logo border-0 h-auto" id="kt_app_sidebar_logo"
  [toggleButtonClass]="toggleButtonClass" [toggleEnabled]="toggleEnabled" [toggleType]="toggleType"
  [toggleState]="toggleState">
</app-sidebar-logo>
<!--end::Logo-->

<!--begin::sidebar menu-->
<app-sidebar-menu class="app-sidebar-menu overflow-hidden flex-column-fluid">
</app-sidebar-menu>
<!--end::sidebar menu-->

