import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {ConstantVars} from '../config/constant-vars';
import {AngularFireMessaging} from "@angular/fire/compat/messaging";
import {ToastrService} from "ngx-toastr";
import {NotificationService} from "./notification.service";
import {NotificationTypeEnum} from "../config/enum/notification-type.enum";
import {Router} from "@angular/router";
import {Client} from "../config/client";

@Injectable({
  providedIn: 'root'
})
export class MessagingService {

  currentMessage = new BehaviorSubject<any>(null);
  firebaseToken: string | null = '';
  countNotification = 0;
  showListNotification = false;

  constructor(private angularFireMessaging: AngularFireMessaging,
              public constantVars: ConstantVars,
              public notificationServices: NotificationService,
              public toastrService: ToastrService,
              public router: Router
  ) {

  }

  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        this.firebaseToken = token;
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }

  getFirebaseToken() {
    if (this.firebaseToken) {
      return this.firebaseToken;
    } else {
      return undefined;
    }
  }

  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
      (value: any) => {
        const jsonDynamicData: Record<string, string> = JSON.parse(value.data.dynamicData);
        const jsonStaticData: Record<string, string> = JSON.parse(value.data.staticData);

        // Convert array-like strings to actual arrays
        const arraysDynamicData: Record<string, string> = Object.entries(jsonDynamicData).reduce((acc, [k, v]) => {
          acc[k] = v;
          return acc;
        }, {} as Record<string, string>);
        const arraysStaticData: Record<string, string> = Object.entries(jsonStaticData).reduce((acc, [k, v]) => {
          acc[k] = v;
          return acc;
        }, {} as Record<string, string>);
        value.data.dynamicData = arraysDynamicData;
        value.data.staticData = arraysStaticData;
        if (value.data.staticData.unknownGuids && value.data.staticData.personUids) {
          const formattedUnknownGuidsStr = value.data.staticData.unknownGuids
            .replace('[', '["')
            .replace(/,\s?/g, '","') // Replace comma followed by optional space with ","
            .replace(']', '"]');
          value.data.staticData.unknownGuids = JSON.parse(formattedUnknownGuidsStr);
          const formattedPersonUidsStr = value.data.staticData.personUids
            .replace('[', '["')
            .replace(/,\s?/g, '","') // Replace comma followed by optional space with ","
            .replace(']', '"]');
          value.data.staticData.personUids = JSON.parse(formattedPersonUidsStr);
        }
        this.currentMessage.next(value);
        if (value.notification) {
          this.notificationServices.updateNotificationCount()
          this.countNotification++;
          let body: string = value.notification.body;
          // Create a Map from the object
          const myMap = new Map(Object.entries(arraysDynamicData));
          myMap.forEach((value: string, key: string) => {
            body = body.replace(key, value);
          });

          let toastSuccess = this.toastrService.success(body, value.notification.title, {
            positionClass: 'toast-bottom-center',
            timeOut: 10000,
            tapToDismiss: false,
            extendedTimeOut: 8000,
          })

          // change style to default
          let toastContainer = document.getElementById('toast-container');
          if (toastContainer) {
            toastContainer.style.cssText = '';

            let toasts = toastContainer.children;
            for (let i = 0; i < toasts.length; i++) {
              let child = toasts[i] as HTMLDivElement;
              child.style.cssText = '';
            }
          }
          ;
          let image = document.getElementById('notification-list-id');
          image?.parentNode?.removeChild(image);
          this.showListNotification = false;
          // when click on toast
          toastSuccess.onTap.subscribe(
            res => {
              if (this.countNotification > 1 && !this.showListNotification) {
                this.showListNotification = true;
                let toastContainer = document.getElementById('toast-container');
                if (toastContainer) {
                  toastContainer.style.cssText = '--bs-scrollbar-size : 0px;display:block;height:30vh;bottom:90px;margin-bottom:1%;';

                  let toasts = toastContainer.children;
                  for (let i = 0; i < toasts.length; i++) {
                    let child = toasts[i] as HTMLDivElement;
                    child.style.cssText = '  background-color: #404040 !important;position:relative !important;transform : none !important;margin-top:15px !important;';
                  }
                  let body = document.getElementById('kt_app_body');

                  if (body !== null && this.countNotification > 3) {
                    let div = document.createElement('div');
                    div.style.cssText =
                      '    width: 2vw;' +
                      '    height: 2vw;' +
                      '    position: absolute !important;' +
                      '    bottom: 20px;' +
                      '    left: 50%;' +
                      '    transform: translate(-50%, -50%);' +
                      '    align-items: center;' +
                      '    display: flex;' +
                      '    justify-content: center;';
                    let image = document.createElement('img');
                    image.id = 'notification-list-id';
                    image.src = './assets/media/icons/duotune/form/close.svg';
                    image.addEventListener('click', function () {
                      let toastContainer = document.getElementById('toast-container');
                      if (toastContainer) {
                        let length = toastContainer.children.length;
                        for (let i = length - 1; i >= 0; i--) {
                          toastContainer.removeChild(toastContainer.children[i] as HTMLDivElement);
                        }
                      }
                      let image = document.getElementById('notification-list-id');
                      image?.parentNode?.removeChild(image)
                    })
                    div.appendChild(image)
                    body.appendChild(div);
                  }
                }
              } else {
                if (value.data.staticData.type === NotificationTypeEnum.UPDATE_INCIDENT_STATUS) {
                  this.toastrService.clear();
                  this.router.navigateByUrl(Client.incidentDetail(value.data.dynamicData.incidentId)).then();
                }
              }
            }
          )
          toastSuccess.onHidden.subscribe(
            res => {
              this.countNotification--;
              if (this.countNotification <= 3) {

                let image = document.getElementById('notification-list-id');
                image?.parentNode?.removeChild(image)
              }
            }
          )
        }
      });
  }

  backNotification() {
    this.angularFireMessaging.messages.subscribe(
      (payload: any) => {
        const NotificationOptions = {
          /*body: payload.notification.body,
          data: payload.data,
          icon: payload.notification.icon*/
          body: 'Background Message body.',
          title: 'message title',
          icon: './assets/media/logos/logo_icon.JPG',
          image: 'https://i.ytimg.com/vi/gXSyP9ga-ag/mqdefault.jpg',
          openURL: 'https://google.com'
        };
        navigator.serviceWorker.getRegistration('/firebase-cloud-messaging-push-scope').then((registration: any) => {
          registration.showNotification(payload.notification.title, NotificationOptions);
        });
        this.currentMessage.next(payload);
      });
  }


  // showCustomNotification(payload: any){
  //     const notifyData = payload.notification;
  //     console.log(notifyData);
  //     // Customize notification here
  //     const notificationTitle = 'title tile';
  //     const notificationOptions = {
  //         body: 'Background Message nnnnnnnnnnn.',
  //         icon : './assets/media/logos/logo_icon.JPG',
  //         image: 'https://i.ytimg.com/vi/gXSyP9ga-ag/mqdefault.jpg',
  //         badge:  './assets/media/logos/logo_icon.JPG',
  //         lang: 'ar',
  //         renotify: true,
  //     };
  //
  //   /*  navigator.serviceWorker.getRegistration('/firebase-cloud-messaging-push-scope').then(registration => {
  //         registration.showNotification(payload.notification.title, notificationOptions);
  //     });*/
  //     /*const notify: Notification = new Notification(notificationTitle, notificationOptions);
  //     console.log(notifyData.url);
  //     notify.onclick = event => {
  //         event.preventDefault();
  //         window.location.href = notifyData.url;
  //     };*/
  //
  //
  // }

  showCustomNotification(payload: any) {
    const notifyData = payload.notification;
    const notificationTitle = payload.notification.title;
    const notificationOptions = {
      body: payload.notification.body,
      icon: payload.notification.icon,
      image: payload.notification.image,
      url: payload.notification.url,
    };
    const notify: Notification = new Notification(payload);

    notify.onclick = event => {
      event.preventDefault();
      window.location.href = payload.data.url;
    };


  }
}
