// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  appVersion: 'v8.1.8',
  USERDATA_KEY: 'auth',
  isMockEnabled: false,
  apiUrl: 'https://api-microspot.micropolis.tech',
  wsRos: "ws://94.206.14.42:9090",
  ptzSocket: "ws://api-microspot.micropolis.tech/surveillance/websocket",
  robotSocket: "ws://api-microspot.micropolis.tech/robot/websocket",
  stunServer: "stun.l.google.com:19302",
  mapbox: {
    accessToken: 'pk.eyJ1IjoiYW1qb29vb2QiLCJhIjoiY2xrOWJvMnNsMHVnZjNzcm80dzU1ZHFreiJ9.reYuFvAEO6jAeJd8S6xpfw'
  },
  webrtcSocketServer: '127.0.0.1',
  webrtcSocketPort: '8443',
  googleMapKey: 'AIzaSyB8pZ_JypphDnMghivvSaL_InQ-B2wpKB8',
  appThemeName: 'Metronic',
  appPurchaseUrl: 'https://1.envato.market/EA4JP',
  appHTMLIntegration:
    'https://preview.keenthemes.com/metronic8/demo1/documentation/base/helpers/flex-layouts.html',
  appPreviewUrl: 'https://preview.keenthemes.com/metronic8/angular/demo1/',
  appPreviewAngularUrl:
    'https://preview.keenthemes.com/metronic8/angular/demo1',
  appPreviewDocsUrl: 'https://preview.keenthemes.com/metronic8/angular/docs',
  appPreviewChangelogUrl:
    'https://preview.keenthemes.com/metronic8/angular/docs/changelog',
  firebase: {
    apiKey: "AIzaSyAAT5ohRjgaj0iRlUzTOv-SKMFJUFLJRjc",
    authDomain: "microspot-production.firebaseapp.com",
    projectId: "microspot-production",
    storageBucket: "microspot-production.appspot.com",
    messagingSenderId: "759178290001",
    appId: "1:759178290001:web:30fefa27ae2dfebbf2a970",
    measurementId: "G-LNK5WZPJ2G"
  },
  appDemos: {
    demo1: {
      title: 'Demo 1',
      description: 'Default Dashboard',
      published: true,
      thumbnail: './assets/media/demos/demo1.png',
    },
    production: false,
    appVersion: 'v8.1.8',
    USERDATA_KEY: 'auth',
    isMockEnabled: false,
    // apiUrl: 'http://10.20.0.30:9946',
    wsRos: "ws://microspot.micropolis.tech:9090",
    ptzSocket: "ws://microspot.micropolis.tech:9926/surveillance/websocket",
    stunServer: "stun.l.google.com:19302",
    apiUrl: 'http://microspot.micropolis.tech:9926',
    // apiUrl: 'http://10.20.0.30:9192',
    // apiUrl: 'http://localhost:8080',
    googleMapKey: 'AIzaSyB8pZ_JypphDnMghivvSaL_InQ-B2wpKB8',
    appThemeName: 'Metronic',
    appPurchaseUrl: 'https://1.envato.market/EA4JP',
    appHTMLIntegration:
      'https://preview.keenthemes.com/metronic8/demo1/documentation/base/helpers/flex-layouts.html',
    appPreviewUrl: 'https://preview.keenthemes.com/metronic8/angular/demo1/',
    appPreviewAngularUrl:
      'https://preview.keenthemes.com/metronic8/angular/demo1',
    appPreviewDocsUrl: 'https://preview.keenthemes.com/metronic8/angular/docs',
    appPreviewChangelogUrl:
      'https://preview.keenthemes.com/metronic8/angular/docs/changelog',
    firebase: {
      apiKey: "AIzaSyDEWqrzjH13T8RonOzMt9qsUMiXmreN1zE",
      authDomain: "microspot-development.firebaseapp.com",
      projectId: "microspot-development",
      storageBucket: "microspot-development.appspot.com",
      messagingSenderId: "716089785510",
      appId: "1:716089785510:web:a1013eabad620b10984867",
      measurementId: "G-W6CYJ93SPT"
    },
    appDemos: {
      demo1: {
        title: 'Demo 1',
        description: 'Default Dashboard',
        published: true,
        thumbnail: './assets/media/demos/demo1.png',
      },

      demo2: {
        title: 'Demo 2',
        description: 'SaaS Application',
        published: true,
        thumbnail: './assets/media/demos/demo2.png',
      },

      demo3: {
        title: 'Demo 3',
        description: 'New Trend',
        published: true,
        thumbnail: './assets/media/demos/demo3.png',
      },

      demo4: {
        title: 'Demo 4',
        description: 'Intranet Application',
        published: true,
        thumbnail: './assets/media/demos/demo4.png',
      },

      demo5: {
        title: 'Demo 5',
        description: 'Support Forum',
        published: false,
        thumbnail: './assets/media/demos/demo5.png',
      },

      demo6: {
        title: 'Demo 6',
        description: 'Admin Backend',
        published: true,
        thumbnail: './assets/media/demos/demo6.png',
      },

      demo7: {
        title: 'Demo 7',
        description: 'CRM Dashboard',
        published: false,
        thumbnail: './assets/media/demos/demo7.png',
      },

      demo8: {
        title: 'Demo 8',
        description: 'Core Dashboard',
        published: false,
        thumbnail: './assets/media/demos/demo8.png',
      },

      demo9: {
        title: 'Demo 9',
        description: 'Fancy Dashboard',
        published: false,
        thumbnail: './assets/media/demos/demo9.png',
      },

      demo10: {
        title: 'Demo 10',
        description: 'Modern Dashboard',
        published: false,
        thumbnail: './assets/media/demos/demo10.png',
      },

      demo11: {
        title: 'Demo 11',
        description: 'Light Dashboard',
        published: false,
        thumbnail: './assets/media/demos/demo11.png',
      },

      demo12: {
        title: 'Demo 12',
        description: 'Reporting System',
        published: false,
        thumbnail: './assets/media/demos/demo12.png',
      },

      demo13: {
        title: 'Demo 13',
        description: 'Classic Dashboard',
        published: false,
        thumbnail: './assets/media/demos/demo13.png',
      },

      demo14: {
        title: 'Demo 14',
        description: 'Creative Dashboard',
        published: false,
        thumbnail: './assets/media/demos/demo14.png',
      },

      demo15: {
        title: 'Demo 15',
        description: 'Minimalistic Dashboard',
        published: false,
        thumbnail: './assets/media/demos/demo15.png',
      },

      demo16: {
        title: 'Demo 16',
        description: 'Modern Dashboard',
        published: false,
        thumbnail: './assets/media/demos/demo16.png',
      },

      demo17: {
        title: 'Demo 17',
        description: 'Backend System',
        published: false,
        thumbnail: './assets/media/demos/demo17.png',
      },

      demo18: {
        title: 'Demo 18',
        description: 'System Dashboard',
        published: false,
        thumbnail: './assets/media/demos/demo18.png',
      },

      demo19: {
        title: 'Demo 19',
        description: 'Light Dashboard',
        published: false,
        thumbnail: './assets/media/demos/demo19.png',
      },

      demo20: {
        title: 'Demo 20',
        description: 'Monochrome Dashboard',
        published: false,
        thumbnail: './assets/media/demos/demo20.png',
      },
    },
  }
};

