import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
 import { BaseModel } from '../../models/base.model';
 import { TableResponseModel } from '../../base/models/crud-table-model/table.model';
 import { ConstantVars  } from '../../config/constant-vars';

 @Injectable({
    providedIn: 'root'
  })

  export class TableHTTPService <T>{

    private constants = new ConstantVars();
    private httpHeaders = new HttpHeaders();

    constructor(private http: HttpClient) {
    }

    create(url:any,formData: any): Observable<BaseModel | undefined> {
      return this.http.post<BaseModel>(`${url}/${this.constants.API_URL_PATH.CREATE}`, formData)
    }

    find(url:any,params: any): Observable<TableResponseModel<T>> {
      return this.http.get<TableResponseModel<T>>(url, { params: params });
    }

   findByPost(url:any,tableState: any): Observable<TableResponseModel<T>> {
     return this.http.post<TableResponseModel<T>>(url, tableState);
   }

    getItemById(url:any,id: number): Observable <T>{
      return this.http.get<T>(`${url}/${id}`)
    }
    update(url:any,formData: any,itemId:any): Observable<any> {
      return this.http.put(`${url}/${this.constants.API_URL_PATH.UPDATE}/${itemId}`,formData)
    }

    updateStatusForItems(url:any,body :any): Observable<any>{
      return this.http.put(`${url}/${this.constants.API_URL_PATH.UPDATESTATUS}`, body)
    }

    updateStatusForItem(url:any,body:any): Observable<any>{
      return this.http.post( `${url}/${this.constants.API_URL_PATH.CHANGESTATUS}`, body)
    }

    delete(url:any,id:any): Observable<any>{
      return this.http.delete(`${url}/${id}`)
    }

    deleteItems(url:any,ids: number[] = []): Observable<any>{
      return this.http.put(`${url}/${this.constants.API_URL_PATH.DELETEITEMS}`, {ids})
    }

    publish(url:any,id: any, publish: boolean): Observable<any>{
      return this.http.post(`${url}/${this.constants.API_URL_PATH.PUBLISH}/${id}`, {publish})
    }

    active(url:any,id: any): Observable<any>{
      return this.http.get(`${url}/${this.constants.API_URL_PATH.CHANGESTATUS}/${id}`)
    }

    getAll(url:any,body = null ): Observable<TableResponseModel<T>>{
      return this.http.post<TableResponseModel<T>>(url, body)
    }

     updateItemWithPost(url:any,id: number, formData: FormData, method: string): Observable<any>{
      return this.http.post(`${url}/${method}/${id}`, formData, {headers: this.httpHeaders})
     }

     updateWithPost(url:any,formData: FormData, method: string): Observable<any>{
      return this.http.post( `${url}/${method}`, formData, {headers: this.httpHeaders})
     }




    }
