export class ConstantVars {
  readonly ROLES = {
    ADMIN_ROLE : 'ROLE_ADMIN',
    PROVIDER_ROLE : 'ROLE_SERVICE_PROVIDER',
  };
  readonly API_URL_PATH ={
    UPDATE:'update',
    DELETE:'delete',
    UPDATESTATUS:'updateStatus',
    CHANGESTATUS:'change-status',
    DELETEITEMS:'deleteItems',
    PUBLISH:'publish',
    CREATE:'create'
  };
  readonly STATUS_CODE_BODY ={
    SUCCESS_CODE:1,
    ERROR_CODE:-1
  };
}



