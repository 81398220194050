import { Injectable, Injector } from '@angular/core';
import { BaseService } from './base.service';
import { Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UploadImgService extends BaseService {
  constructor(injector: Injector) {
    super(injector);
    this.apiUrl = `${this.apiUrl}/api/upload`;
  }

  uploadImg(img: any, type: string): Observable<any> {
    const formData = new FormData();
    formData.append('file', img);
    
    switch (type) { 
      case 'photo':
        formData.append('tag', 'photo');
        break;
        case 'file':
          formData.append('tag', 'file');
          break;
    }


    let headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');

    return this.http.post(this.apiUrl, formData, { headers: headers });
  }
}
