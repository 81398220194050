<div class="p-5  radius-10 m-5 " [ngClass]="notification.isSeen ? 'bg-white-half-opactiy' : 'bg-white-opactiy'" (click)="onNotificationClick()">
  <div class="d-flex align-items-start">
    <div class="align-items-start d-flex justify-content-center me-3">
      <img class="h-20px w-30px mt-1" [src]="getNotificationImage()"/>
    </div>
    <div class="align-items-start flex-1">
      <p class="fs-18 fw-bold mb-1">{{notification.title}}</p>
      <p class="fs-16 opacity-50 m-0"  [innerHTML]="notification.message
       | highlightText:getDynamicKey():getDynamicValue()"
      ></p>
      <p class="fs-14 p-0 m-0"
         [ngClass]="notification.isSeen ? 'color-is-seen' : 'color-warning-500'">{{calculateTimeDifferenceForNoti(notification.creationDate)}}</p>
    </div>
  </div>
</div>
