import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import {throwError} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {ErrorEnum} from "../../../core/config/enum/error.enum";
import {ToastrService} from "ngx-toastr";

@Injectable()
export class ErrorCatchingInterceptorService implements HttpInterceptor {
  constructor(private router: Router, private toastr: ToastrService) {
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): any {
    return next.handle(request).pipe(
      map((res: HttpEvent<any>) => {
        let resultDate = null;
        if (res instanceof HttpResponse) {
          if (res.body && res.body.data) {
            if (res.body.code === 1) {
              resultDate = res.body.data;
            } else if (res.body.code === -1) {
              this.toastr.error('Error', 'Some thing is worng', {positionClass: 'toast-top-right'})
            }
          } else {
            resultDate = res.body;
          }
          return res.clone({
            body: resultDate
          });
        }
      }),
      catchError((error: any) => {
        if (error instanceof HttpErrorResponse) {
          const errorResponse = error as HttpErrorResponse;
          if (errorResponse.status === ErrorEnum.NOT_FOUND) {
            // this.router.navigateByUrl('error/404');
          } else if (errorResponse.status === ErrorEnum.NOT_AUTHORIZE || errorResponse.status === ErrorEnum.NOT_AUTHENTICATED) {
            this.router.navigateByUrl('auth/login');
          } else if (errorResponse.status === ErrorEnum.SERVER_ERROR) {
            this.router.navigateByUrl('error/500');
          } else if (errorResponse.status === ErrorEnum.NOT_AVAILABLE) {
            this.router.navigateByUrl('error/503');
          } else if (errorResponse.status === ErrorEnum.BAD_REQUEST) {
            this.toastr.error(error.message, 'Some thing is worng', {
              positionClass: 'toast-top-right',
              timeOut: 2000000
            })
          }
        }
        return throwError(error);
      })
    );
  }
}



