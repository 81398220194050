// ar
export const locale = {
  lang: 'ar',
  data: {
    PROJECT: {
      COMPANY: 'Microspot',
      TITLE: ' Diplomatic Affairs Portal ',
      DOC:'Login to your account'
    },
    TRANSLATOR: {
      SELECT: 'إختر لغتك',
    },
    MENU: {
      AREAS_SENSITIVITY:'المناطق',
      INCIDENTS_MONITOR:'الخريطة',
      SURVEILLANCE:'المراقبة',
      INCIDENTS:'الجرائم',
      CRIMINAL_BASE:'المجرمين',
      NEW: 'جديد',
      ACTIONS: 'Actions',
      CREATE_POST: 'Create New Post',
      PAGES: 'Pages',
      FEATURES: 'Features',
      APPS: 'Apps',
      DASHBOARD: 'لوحة التحكم',
      ERRORS: 'أخطاء',
      TRY_AGIN:"please try again",
      USERS: 'المستخدمون',
      DOCUMENT: 'المستندات',
      FACILITIES: 'المنشئات',
      SENSOR_TYPES: 'انواع الحساسات',
      SERVICE_PROVIDERS: 'مزودو الخدمة',
      TECH_TEAM: 'الفريق التقني',
      CUSTOMERS: 'الزبائن',
      CALENDAR:"التقويم",
      LOG_OUT:"تسجيل الخروج",
      ACCOUNT:"الحساب",
      DROPDOWN_USERS:{
        DIPLOMATIC_USERS:'مستخدمين القنصليات',
        EMPLOYEES:'الموظفين',
        ADMINS:'المسؤولين'
      }
    },
    AUTH: {
      GENERAL: {
        OR: 'أو',
        SUBMIT_BUTTON: 'تأكيد',
        NO_ACCOUNT: 'ليس لديك حساب؟',
        SIGNUP_BUTTON: 'إنشاء حساب',
        FORGOT_BUTTON: 'هل نسيت كلمة المرور',
        BACK_BUTTON: 'رجوع',
        SIGNIN_BUTTON:'sign in',
        PRIVACY: 'الخصوصية',
        LEGAL: 'قانوني',
        BACK_DESC:'Back to',
        CONTACT: 'اتصل بنا',
      },
      LOGIN: {
        TITLE: 'تسجيل الدخول',
        BUTTON: 'تسجيل الدخول',
        DESC:'This site is monitored and maintained by Dubai Police. For any assistance or inquiries, please contact our support team.'

      },
      FORGOT: {
        TITLE: 'Forgotten Password?',
        DESC: 'Enter your email to reset your password',
        SUCCESS: 'Your account has been successfully reset.',
        BUTTON:'Reset Password',
        LINK:'Reset it',
        ASK:'Forgot your password?'
      },
      REGISTER: {
        TITLE: 'إنشاء حساب',
        DESC: 'أدخل بياناتك لإنشاء حسابك',
        SUCCESS: 'تم تسجيل حسابك بنجاح.',
      },
      INPUT: {
        EMAIL: 'البريد الإلكتروني',
        FULLNAME: 'الاسم الكامل',
        PASSWORD: 'كلمة المرور',
        CONFIRM_PASSWORD: 'تأكيد كلمة المرور',
        USERNAME: 'اسم المستخدم',
        FORGET_PASSWORD: 'هل نسيت كلمة المرور؟',
      },
      VALIDATION: {
        INVALID: '{{name}} غير صحيح',
        REQUIRED: 'مطلوب {{name}}',
        MIN_LENGTH: 'الحد الأدنى لطول {{name}} هو {{min}}',
        MAX_LENGTH: '{{name}} يجب أن يتكون من {{min}} حرفًا على الأكثر',
        AGREEMENT_REQUIRED: 'مطلوب قبول الشروط والأحكام',
        NOT_FOUND: 'البريد الإلكتروني {{name}} غير موجود',
        INVALID_LOGIN: 'المعلومات المدخلة غير صحيحة',
        REQUIRED_FIELD: 'مطلوب',
        MIN_LENGTH_FIELD: 'الحد الأدنى لطول الحقل:',
        MAX_LENGTH_FIELD: 'الحد الأقصى لطول الحقل:',
        INVALID_FIELD: 'الحقل غير صالح',
        EMAIL_HINT: 'أدخل عنوان بريدك الإلكتروني.',
        PASSWORD_HINT: 'يجب أن تحتوي على 8 أحرف على الأقل.',
        PASSWORD_REQUIREMENTS: 'Password must contain at least one number, one special character, and be 7-15 characters long.',
        CONFIRM_PASSWORD_DESCRIPTION :"Please re-enter your password for confirmation.",
        MATCHED_PASSWOED_ERROR:"The passwords don't match.",
        REQUIRED_PASSWORD:"Passwords are required.",
        CONFIRM_PASSWORD:'Confirm Password ',
        RESET_PASSWORD:'Reset Password',
        SHOW_PASSWORD:'Show Password'
      },
      VERIFY_EMAIL: {
        TITLE: 'تحقق من صندوق البريد',
        DESC: 'لقد أرسلنا بريدًا إلى البريد الإلكتروني',
      },
      VERIFY_NUMBER :{
        TITLE: 'تحقق من صندوق الرساءل',
        DESC: 'لقد ارسلنا رسالة لهاتفك',
      },
      RESET_PASSWORD:{
        TITLE:'Set your Password',
        DEC:"Enter your new password"

      }
    },
    MODULES:{
      SERVICE_PROVIDERS: {
        NEW: 'مزود جديد',
        EDIT: 'تعديل معلومات المزود',
      },
      TECH_TEAM: {
        NEW: 'مستخدم جديد',
        EDIT: 'تعديل معلومات المستخدم',
      },
      CUSTOMERS: {
        NEW: 'زبون جديد',
        EDIT: 'تعديل معلومات الزبون',
      },
      FACILITIES: {
        NEW: 'منشئة جديدة',
        EDIT: 'تعديل معلومات المنشئة'
      },
      INPUTS:{
        ACTIONS: 'الخيارات',
        CANCEL: 'إلغاء',
        SAVE: 'حفظ',
        NAME: 'الاسم',
        ADDRESS: 'العنوان',
        TYPE: 'النوع',
        SENSORS: 'الحساسات',
        CREATON_DATE: 'تاريخ الانشاء',
        PHONE: 'رقم الهاتف',
        FACILITIES: 'المنشئات',
        FACILITY: 'المنشئة',
        CUSTOMER: 'الزبون',
        VIEW_ALL: 'عرض الكل',
      }
    },
    FOOTER :{
      FAQS:'FAQS',
      PRIVACTPOLICY:'Privact Policy',
      TERMSCONDITIONS:'Terms conditions',
      SUPPORT:'support'
    },
    ECOMMERCE: {
      COMMON: {
        SELECTED_RECORDS_COUNT: 'Selected records count: ',
        ALL: 'All',
        SUSPENDED: 'Suspended',
        ACTIVE: 'Active',
        FILTER: 'Filter',
        BY_STATUS: 'by Status',
        BY_TYPE: 'by Type',
        BUSINESS: 'Business',
        INDIVIDUAL: 'Individual',
        SEARCH: 'Search',
        IN_ALL_FIELDS: 'in all fields'
      },
      ECOMMERCE: 'eCommerce',
      CUSTOMERS: {
        CUSTOMERS: 'Customers',
        CUSTOMERS_LIST: 'Customers list',
        NEW_CUSTOMER: 'New Customer',
        DELETE_CUSTOMER_SIMPLE: {
          TITLE: 'Customer Delete',
          DESCRIPTION: 'Are you sure to permanently delete this customer?',
          WAIT_DESCRIPTION: 'Customer is deleting...',
          MESSAGE: 'Customer has been deleted'
        },
        DELETE_CUSTOMER_MULTY: {
          TITLE: 'Customers Delete',
          DESCRIPTION: 'Are you sure to permanently delete selected customers?',
          WAIT_DESCRIPTION: 'Customers are deleting...',
          MESSAGE: 'Selected customers have been deleted'
        },
        UPDATE_STATUS: {
          TITLE: 'Status has been updated for selected customers',
          MESSAGE: 'Selected customers status have successfully been updated'
        },
        EDIT: {
          UPDATE_MESSAGE: 'Customer has been updated',
          ADD_MESSAGE: 'Customer has been created'
        }
      }
    },
    PLACEHOLDER:{
      SEARCH_USERS:'Search users'
    },
    INCIDENT :{
      INCIDENT:"جريمة",
      INCIDENT_MONITOR:"مراقبة الجرائم",
      INCIDENTS_COUNT:"عدد الجرائم",
      CLASSIFICATION:{
        WANTED:"مطلوب"
      },
      DETECTED_INCIDENTS:'الجرائم المحققة'
    },
    NOTIFICATION:{
      NEW_ALERT:"إشعار جديد"
    },
    AREA:{
      AREAS:'المناطق',
      AREA_TYPE:'نوع المنطقة',
      TITLE:'المناطق',
      SHOW_ALL_TYPES:'مشاهدة كل الأنواع'
    },
    INCIDENT_TABLE:{
      INCIDENTS:'الجرائم',
      DESCRIPTION:'وصف الجريمة',
      RELATED_SUSPECT:'المشتبه بهم ذات الصلة',
      AI_ACCURACY:'الذقة',
      TRACKED:'تتبع',
      STATUS:'الحالة',
      INCIDENT_STATUS:'حالة الجريمة',
      DATE:'التاريخ',
      ALL_STATUS:'كل الحالات',
      TIMES:'مرات'
    },
    PAGINATION:{
      SHOW:'مشاهدة'
    },
    CRIMINAL_BASE:{
      CRIMINAL_BASE:'المجرمين',
      WANTED_LIST:'قائمة المطلوبين',
      SUSPECT_LIST:'قائمة المشتبهين',
      SEARCH:'بحث',
      CRIMES:'عدد الجرائم',
      ASSOCIATE_NAME:'الاسم',
      FATHER_NAME:'اسم الأب',
      MOTHER_NAME:'اسم الأم',
      ID_NO:'معرف الهوية'
    },
    INCIDENT_DETAIL:{
      INCIDENT_DETAIL:'تفاصيل الجريمة',
      INCIDENT_DESCRIPTION:'وصف الجريمة',
      SUSPECT_TRACKING:'تتبع المشتبهين',
      CASE_FILES:'ملفات الجريمة',
      INCIDENT_STATUS:'حالة الجريمة',
    },
    SURVEILLANCE: {
      SURVEILLANCE:'المراقبة',
      M_PATROLS:'السيارات',
      CAMERAS:'الكاميرات'
    },
    INCIDENT_DESCRIPTION: {
      AI_ACCURACY:'الدقة',
      DESCRIPTION:'الوصف',
      LATITUDE:'X',
      LONGITUDE:'Y',
      NEXT_TO:'إلى جانب',
      ROOM:'الغرفة',
      BUILDING:'البناء',
      ROAD:'الطريق',
      STREET:'الشارع',
      ZONE:'الساحة',
      AREA:'المنطقة',
      CITY:'المدينة',
      INCIDENT_LEVEL:'مستوى الجريمة',
      LOCATION:'الموقع',
      RELATED_PEOPLE:'الأشخاص'
    }
  }
};
