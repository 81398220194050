import {Inject, Injectable} from "@angular/core";
import {environment} from "../../../../environments/environment";
import {CaseFileModel} from "../models/incident/case-file/case-file.model";
import {HttpClient} from "@angular/common/http";
import {TableHTTPService} from "../../../core/services/table-http/table-http.service";
import {IncidentClassificationModel} from "../models/incident/map/incident-classification.model";
import {TableService} from "../../../core/services/table.service";
import {map, Observable} from "rxjs";
import {tap} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class PersonService extends TableService<IncidentClassificationModel> {
  API_URL = `${environment.apiUrl}/incident/person`;
  caseFile: CaseFileModel;

  constructor(@Inject(HttpClient) http: HttpClient,
              @Inject(TableHTTPService) protected tablehttpservice: TableHTTPService<IncidentClassificationModel>) {
    super(http, tablehttpservice);

  }

  getWantedList(): Observable<any> {
    return this.http.post(`${this.API_URL}/wanted`, this._tableState$.value)
      .pipe(
        tap((res: any) => {
          let total;
          total = res.total;
          this.patchStateWithoutFetch({
            paginator: this._tableState$.value.paginator.recalculatePaginator(
              total
            ),
          });
          return res.items
        }),
        map(
          res => {
            return res.items;
          })
      )
  }

  getTrackingForPerson(id: number, detected: boolean): Observable<any> {
    return this.http.get(`${this.API_URL}/tracking-map/${id}?known=${detected}`);
  }

}
