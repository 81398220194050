export enum RobotStatusEnum {
  MISSION_ON='MISSION_ON',
  STAND_BY = 'STAND_BY',
  UNDER_MAINTENANCE='UNDER_MAINTENANCE',
  FREE_DRIVING='FREE_DRIVING',
  EMERGENCY='EMERGENCY',
  PAUSED='PAUSED',
  CHARGING='CHARGING',
  KEY_OFF='KEY_OFF'
}
