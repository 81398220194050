<ng-container *ngIf="appContentContiner">
  <!--begin::Content container-->
  <div id="kt_app_content_container" class="app-container">
    <router-outlet></router-outlet>
  </div>
  <!--end::Content container-->
</ng-container>
<ng-container *ngIf="!appContentContiner">
  <router-outlet></router-outlet>
</ng-container>
