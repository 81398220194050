import {ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, Inject, OnInit} from '@angular/core';
import {TranslationService} from './modules/i18n';
import {locale as enLang} from './modules/i18n/vocabs/en';
import {locale as chLang} from './modules/i18n/vocabs/ch';
import {locale as esLang} from './modules/i18n/vocabs/es';
import {locale as jpLang} from './modules/i18n/vocabs/jp';
import {locale as deLang} from './modules/i18n/vocabs/de';
import {locale as frLang} from './modules/i18n/vocabs/fr';
import {locale as arLang} from './modules/i18n/vocabs/ar';
import {ThemeModeService} from './_metronic/partials/layout/theme-mode-switcher/theme-mode.service';
import {DOCUMENT} from '@angular/common';
import {environment} from "../environments/environment";
import {MessagingService} from "./core/services/messaging.service";
import {ConfigService} from "./core/services/config.service";

@Component({
    // tslint:disable-next-line:component-selector
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'body[root]',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {

    @HostListener('window:beforeunload', ['$event'])
    beforeUnloadHandler() {
        if (this.configService.configuration.value) {
            localStorage.setItem('configuration', JSON.stringify(this.configService.configuration.value));
        }
    }

    message: any = null;
    isScriptLoaded = false;

    constructor(
        private translationService: TranslationService,
        private modeService: ThemeModeService,
        @Inject(DOCUMENT) private document: Document, private cdr: ChangeDetectorRef,
        private messagingService: MessagingService,
        private configService: ConfigService,
    ) {
        this.configService.getAllConfiguration();
        this.translationService.loadTranslations(
            enLang,
            chLang,
            esLang,
            jpLang,
            deLang,
            frLang,
            arLang
        );

    }

    ngOnInit() {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${environment.googleMapKey}&libraries=places,drawing,routes,geometry&language=en&mapId=3ed95c00f684da8e`
        document.head.appendChild(script);
        script.onload = () => {
            this.isScriptLoaded = true;
            this.cdr.detectChanges();
        };
        script.onerror = () => {
            // Script failed to load, refresh the page
            console.error('Error loading Google Maps API script. Refreshing the page.');
            window.location.reload();
        };
        const self = this;


        document.addEventListener('visibilitychange', () => {
            if (document.hidden) {
                self.messagingService.currentMessage.next(true);
            } else {
                self.messagingService.currentMessage.next(true);
            }
        });

        this.messagingService.requestPermission();
        this.messagingService.receiveMessage();

        const ltrStylesheet = document.querySelector('#ltr-stylesheet') as HTMLLinkElement;
        const rtlStylesheet = document.querySelector('#rtl-stylesheet') as HTMLLinkElement;
        const ltrStylesheetSystem = document.querySelector('#ltr-stylesheet-system') as HTMLLinkElement;
        const rtlStylesheetSystem = document.querySelector('#rtl-stylesheet-system') as HTMLLinkElement;
        const linkElement = document.querySelector('link[href="styles.css"]') as HTMLLinkElement;


        this.modeService.init();
        this.translationService.currentRtlSubject.subscribe((rtl: any) => {
            if (!rtl) {

                const html = this.document.getElementsByTagName('html')[0];
                const body = this.document.getElementsByTagName('body')[0];
                html.setAttribute('direction', 'ltr');
                body.setAttribute('direction', 'ltr');
                html.setAttribute('dir', 'ltr');
                body.setAttribute('dir', 'ltr');
                ltrStylesheet.disabled = false;
                rtlStylesheet.disabled = true;
                ltrStylesheetSystem.disabled = false;
                rtlStylesheetSystem.disabled = true;


                if (linkElement) {
                    linkElement.disabled = false;
                }
            } else {
                if (linkElement) {
                    linkElement.disabled = true;
                }
                const html = this.document.getElementsByTagName('html')[0];
                const body = this.document.getElementsByTagName('body')[0];
                html.setAttribute('direction', 'rtl');
                body.setAttribute('direction', 'rtl');
                html.setAttribute('dir', 'rtl');
                body.setAttribute('dir', 'rtl');
                ltrStylesheet.disabled = true;
                rtlStylesheet.disabled = false;
                ltrStylesheetSystem.disabled = true;
                rtlStylesheetSystem.disabled = false;

                const links = document.querySelectorAll('head link');
                const lastLink = links[links.length - 1];
                if (lastLink !== rtlStylesheet) {
                    document.head.removeChild(rtlStylesheet);
                    document.head.appendChild(rtlStylesheet);
                }
            }
            this.cdr.detectChanges();
        })
    }
}
