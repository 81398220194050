import {ElementRef, Injectable} from '@angular/core';
import {animate, AnimationBuilder, style} from '@angular/animations';
import {BehaviorSubject} from "rxjs";
import {GoogleService} from "../../../../core/services/google.service";

export enum STATUS_SPLASH {
  RESTART = 1,
  START_LONG = 2,
  STOP = 3,
  DISPLAY = 4
}

@Injectable({
  providedIn: 'root',
})
export class SplashScreenService {
  isLoadingStatus: BehaviorSubject<number> = new BehaviorSubject<number>(STATUS_SPLASH.RESTART);
  // Private properties
  private el: ElementRef;
  private stopped: boolean = false;
  player: any;

  /**
   * Service constructor
   *
   * @param animationBuilder
   */
  constructor(private animationBuilder: AnimationBuilder, private googleService: GoogleService) {
  }

  /**
   * Init
   *
   * @param element
   */
  init(element: ElementRef) {
    if (this.googleService.showMapAfterLoaded.value) {
      this.player = this.animationBuilder
        .build([style({opacity: '1'}), animate(2000, style({opacity: '0'}))])
        .create(element.nativeElement);
      this.player.play();
      this.player.onDone(() => {
        setTimeout(
          () => {
            this.isLoadingStatus.next(STATUS_SPLASH.DISPLAY);
          }, 50)
      });
    } else {
      this.player = this.animationBuilder
        .build([style({opacity: '1'}), animate(2000, style({opacity: '0.9'}))])
        .create(element.nativeElement);
      this.player.play();
      this.player.onDone(() => {
        this.player = this.animationBuilder
          .build([style({opacity: '0.9'}), animate(500, style({opacity: '0'}))])
          .create(element.nativeElement);
        this.player.play()
        this.player.onDone(() => {
          setTimeout(
            () => {
              this.isLoadingStatus.next(STATUS_SPLASH.DISPLAY);
            }, 50)
        })
      });
    }

  }

  /**
   * start
   *
   * @param element
   */
  start(element: ElementRef) {
    this.player = this.animationBuilder
      .build([style({opacity: '0.5'}), animate(800000, style({opacity: '0'}))])
      .create(element.nativeElement);
    this.player.play();
  }

  stopSplash() {
    this.player.pause();
    this.isLoadingStatus.next(STATUS_SPLASH.DISPLAY);
  }

  restart() {
    this.isLoadingStatus.next(STATUS_SPLASH.RESTART);
  }

  startLong() {
    this.isLoadingStatus.next(STATUS_SPLASH.START_LONG);
  }

  stop() {
    this.isLoadingStatus.next(STATUS_SPLASH.STOP);
  }
}
