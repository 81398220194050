import {ActivatedRoute, Router} from "@angular/router";
import {ChangeDetectorRef, NgZone, Renderer2} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Subscription} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {UIState} from "./ui-state.model";
import {ConstantVars} from "../../../core/config/constant-vars";
import {DatePipe} from "@angular/common";


export abstract class AbstractBaseComponent<U extends UIState = UIState> {
  /**
   * To access url, get & fragment params
   */
  route: ActivatedRoute;

  /**
   * Angular router utility
   */
  router: Router;

  /**
   * Document handler
   */
  renderer2: Renderer2;

  /**
   * Http client to fetch data
   */
  http: HttpClient;

  /**
   * Change detection reference
   */
  cdRef: ChangeDetectorRef;

  /**
   * Math object accessor
   */
  math: Math;

  /**
   * Object accessor
   */
  object: any;

  /**
   * Observable helper
   */
  of: any;

  /**
   * Ui state
   */
  state: U;

  /**
   * To append tears on
   */
  subscription: Subscription;

  /**
   * Translate Service
   */
  translateService :TranslateService;

  /**
   *
   */
  constantVars: ConstantVars

  /**
   *
   */
  datePipe: DatePipe

  /**
   *
   */
  ngZone: NgZone;


}
