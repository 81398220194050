import {Injectable, NgZone} from '@angular/core';
import {Observable, Subscriber} from 'rxjs';
import {environment} from "../../../environments/environment";
import {RobotModel} from "../../modules/robots/model/robot.model";
import {RobotStatusEnum} from "../config/enum/robot-status.enum";
import {RobotLocationModel} from "../../modules/robots/model/robot-location.model";
import {Router} from "@angular/router";
import {Client} from "../config/client";

/**
 * Server-Sent Events service
 */
@Injectable({
  providedIn: 'root'
})
export class EventSourceService {
  private eventSource: EventSource;

  /**
   * constructor
   * @param zone - we need to use zone while working with server-sent events
   * because it's an asynchronous operations which are run outside of change detection scope
   * and we need to notify Angular about changes related to SSE events
   * @param router
   */
  constructor(private zone: NgZone, private router: Router) {
  }

  authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;

  /**
   * Method for creation of the EventSource instance
   * @param url - SSE server api path
   * @param options - configuration object for SSE
   */
  getEventSource(url: string, options: EventSourceInit): EventSource {
    return new EventSource(url, options);
  }

  /**
   * Method for establishing connection and subscribing to events from SSE
   * @param url - SSE server api path
   * @param options - configuration object for SSE
   * @param eventNames - all event names except error (listens by default) you want to listen to
   */
  connectToServerSentEvents(url: string, options: EventSourceInit, eventNames: string[] = []): Observable<Event> {
    const tokenData = localStorage.getItem(this.authLocalStorageToken);

    if (!tokenData || tokenData === '' || tokenData === ' ')
      this.router.navigateByUrl(Client.baseLogin());
    try {
      const {token} =
        JSON.parse(tokenData ?? '');
      url = url.includes('?') ? `${url}&Authorization=${token}` : `${url}?Authorization=${token}`
    } catch (e) {
      this.router.navigateByUrl(Client.baseLogin());

    }
    this.eventSource = this.getEventSource(url, options);
    return new Observable((subscriber: Subscriber<Event>) => {
      this.eventSource.onerror = error => {
        this.zone.run(() => subscriber.error(error));
      };

      eventNames.forEach((event: string) => {
        this.eventSource.addEventListener(event, data => {
          this.zone.run(() => subscriber.next(data));
        });
      });
    });


  }


  demoData() {
    let robot1 = new RobotModel()
    let robot2 = new RobotModel()
    let robot3 = new RobotModel()
    let robot4 = new RobotModel()
    let robot5 = new RobotModel()
    // robot1.init(1, RobotStatusEnum.KEY_OFF, 80, 3, 0, new RobotLocationModel(54.32505957945424, 24.4603554317756316), false, 'Robot 1', 'M-02/P', 3)
    // robot2.init(2, RobotStatusEnum.MISSION_ON, 80, 3, 20, new RobotLocationModel(54.32475957945424, 24.4603554317756316), false, 'Robot 2', 'M-02/P', 3)
    // robot3.init(3, RobotStatusEnum.CHARGING, 20, 3, 20, new RobotLocationModel(54.32445957945424, 24.4603554317756316), false, 'Robot 3', 'M-02/P', 3)
    // robot4.init(4, RobotStatusEnum.STAND_BY, 20, 3, 20, new RobotLocationModel(54.32415957945424, 24.4603554317756316), false, 'Robot 4', 'M-02/P', 3)
    // robot5.init(5, RobotStatusEnum.FREE_DRIVING, 60, 1, 20, new RobotLocationModel(54.32385957945424, 24.4603554317756316), false, 'Robot 5', 'M-02/P', 3)
    let data = [
      robot1,
      robot2,
      robot3,
      robot4,
      robot5,
    ]
    return new Observable((subscriber: Subscriber<RobotModel[]>) => {
      setInterval(() => {
        // data[0].battery = Math.floor(Math.random() * 100) + 1;
        // data[1].missionComplete = Math.floor(Math.random() * 100) + 1;
        // data[2].battery = Math.floor(Math.random() * 100) + 1;
        // data[4].connection = Math.floor(Math.random() * 5) + 1;
        // data[4].battery = Math.floor(Math.random() * 100) + 1;
        this.zone.run(() => subscriber.next(data));
      }, 5000)

    });

  }


  /**
   * Method for closing the connection
   */
  close(): void {
    if (!this.eventSource) {
      return;
    }

    this.eventSource.close();

  }
}
