import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {TableService} from "../../../core/services/table.service";
import {TableHTTPService} from "../../../core/services/table-http/table-http.service";
import {Observable} from "rxjs";
import {environment} from "../../../../environments/environment";
import {GroupingState, ITableState, PaginatorState, SortState,} from "../../../core/base/models/crud-table-model";
import {PersonalDataModel} from "../models/person/personal-data.model";

@Injectable({
  providedIn: 'root'
})
export class DataService extends TableService<PersonalDataModel> {

  API_URL = `${environment.apiUrl}/data`;

  constructor(@Inject(HttpClient) http: HttpClient,
              @Inject(TableHTTPService) protected tablehttpservice: TableHTTPService<PersonalDataModel>) {
    super(http, tablehttpservice);

  }

  getPersonalData(uid: string, detected: boolean): Observable<any> {
    const queryParams = new HttpParams().set('detected', detected);
    const urlWithParams = `${this.API_URL}/people/${uid}?${queryParams.toString()}`;
    return this.http.get(urlWithParams)
  }

  getWantedList(filter: {}, PaginatorState: PaginatorState, SortState: SortState, searchTerm: string = '', GroupingState: GroupingState): Observable<any> {
    let requestBody: ITableState = {
      filter: filter,
      paginator: PaginatorState,
      sorting: SortState,
      searchTerm: searchTerm,
      grouping: GroupingState
    };
    return this.http.post(`${this.API_URL}/people/suspect-wanted-list`, requestBody).pipe(
      (res: any) => {
        return res;
      })
  }

  exportSuspectDetail(id: any): Observable<any> {
    let httpOptions = {
      responseType: 'blob' as "json"
    }
    return this.http.get(`${this.API_URL}/people/export-suspect-detail/${id}`, httpOptions)
  }
}
