import {Injectable, Injector} from "@angular/core";
import {BaseService} from "./base.service";
import {BehaviorSubject, map} from "rxjs";

export enum ConfigKey {
  DMMZ = "Default_mobile_spot_map_zoom",
  DAMC = "Default_areas_map_center",
  DAMZ = "Default_areas_map_zoom",
  DMMC = "Default_mobile_spot_map_center"
}

export enum ConfigType {
  NUMBER = 'number',
  OBJECT = 'object',
  OBJECT_STRING = 'object_string'
}

@Injectable({
  providedIn: 'root',
})
export class ConfigService extends BaseService {

  configuration = new BehaviorSubject<any[]>([]);

  constructor(injector: Injector) {
    super(injector);
    this.apiUrl = `${this.apiUrl}/surveillance/configuration`;
    const storedConfigs = localStorage.getItem('configuration');
    if (storedConfigs) {
      const configuration: any[] = JSON.parse(storedConfigs);
      this.configuration.next(configuration);
      localStorage.removeItem('configuration');
    }
  }

  getAllConfiguration() {
      return this.http.get(`${this.apiUrl}`).pipe(
        map(
          (res: any) => {
            this.configuration.next(res)
          }
        )
      ).subscribe();
  }

  getValueFromKey(key: ConfigKey, returnType: string) {
    if(Array.isArray(this.configuration.value)){
      let data = this.configuration.value.filter(item => item.key === key);
      if(data.length > 0){
        let value = data[0].value;
        switch (returnType) {
          case ConfigType.NUMBER:
            return Number(value);
          case ConfigType.OBJECT:
            return value;
          case ConfigType.OBJECT_STRING:
            let jsonString = value.replace(/(\w+):/g, '"$1":');
            return JSON.parse(jsonString);
          default:
            return value;
        }
      }
    }
  }
}
