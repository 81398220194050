import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { Observable } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;

  constructor() { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const tokenData = localStorage.getItem(this.authLocalStorageToken);
    let headers: any = {
      'Content-Type': 'application/json; charset=utf-8',
      'Accept': 'application/json',
    };

    if (tokenData) {
      const { token } = JSON.parse(tokenData);
      headers = {
        ...headers,
        'Authorization': `Bearer ${token}`,
      };
    }

    req = req.clone({
      setHeaders: headers,
    });

    return next.handle(req);
  }
}
