import {Injectable} from "@angular/core";
import * as THREE from 'three';
import {environment} from "../../../environments/environment";
import {BehaviorSubject} from "rxjs";
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader.js';
import {DRACOLoader} from 'three/examples/jsm/loaders/DRACOLoader.js';

declare var mapboxgl: any;

@Injectable({
  providedIn: "root"
})
export class MapBoxService {
  camera = new THREE.Camera();
  scene = new THREE.Scene();
  globalMap: any;
  isMapLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);
  paddingMarker = 200;
  markers: { id: any, isIcon: Boolean, marker: any, model: any }[] = [];
  previousModel: any;
  currentModel: any;
  defaultZoom: number;

  initMap(containerName: string, lng: number, lat: number, zoom: number, pitch: number) {
    const mapBoxGl = mapboxgl;
    mapBoxGl.accessToken = environment.mapbox.accessToken;
    this.globalMap = new mapBoxGl.Map({
      container: containerName,
      style: 'mapbox://styles/amjooood/clul0aan900sq01r5a45nhwkf',
      center: [lng, lat],
      zoom: zoom,
      pitch: pitch,
      maxZoom: 19,
      minZoom: 16,
      // antialias: true // create the gl context with MSAA antialiasing, so custom layers are antialiased
    });
    this.defaultZoom = this.globalMap.getZoom()
    this.globalMap.on('style.load', () => {
      this.isMapLoaded.next(true);
    })

  }

  getModel3d(name: string, lng: number, lat: number, urlModel: string) {
    let modelOrigin = {'lng': lng, 'lat': lat};
    const modelAltitude = 0;
    const modelRotate = [Math.PI / 2, 5, 0];

    const modelAsMercatorCoordinate = mapboxgl.MercatorCoordinate.fromLngLat(
      modelOrigin,
      modelAltitude
    );

    const modelTransform = {
      translateX: modelAsMercatorCoordinate.x,
      translateY: modelAsMercatorCoordinate.y,
      translateZ: modelAsMercatorCoordinate.z,
      rotateX: modelRotate[0],
      rotateY: modelRotate[1],
      rotateZ: modelRotate[2],
      scale: modelAsMercatorCoordinate.meterInMercatorCoordinateUnits() * 8
    };


    const customLayer = {
      id: name,
      type: 'custom',
      map: this.globalMap,
      renderingMode: '3d',
      camera: new THREE.Camera(),
      scene: new THREE.Scene(),
      renderer: null as THREE.WebGLRenderer | null, // Declare renderer property with explicit type
      onAdd: function (map: any, gl: any) {
        const loader = new GLTFLoader();
        const draco = new DRACOLoader();
        draco.setDecoderConfig({type: 'js'});
        draco.setDecoderPath('https://www.gstatic.com/draco/v1/decoders/');
        loader.setDRACOLoader(draco);

        loader.load(
          urlModel,
          (gltf: any) => {
            this.scene.add(gltf.scene);
            const ambientLight = new THREE.AmbientLight(0xffffff, 1); // Soft white ambient light
            this.scene.add(ambientLight);
            const light = new THREE.PointLight(0xe3dcc8, 400, 1000, 1.6);
            light.position.set(-20, 30, 5);
            this.scene.add(light);
          }
        );

        this.renderer = new THREE.WebGLRenderer({
          canvas: map.getCanvas(),
          context: gl,
          antialias: true
        });

        this.renderer.autoClear = false;
      },
      render: function (gl: any, matrix: any) {
        const rotationX = new THREE.Matrix4().makeRotationAxis(
          new THREE.Vector3(1, 0, 0),
          modelTransform.rotateX
        );
        const rotationY = new THREE.Matrix4().makeRotationAxis(
          new THREE.Vector3(0, 1, 0),
          modelTransform.rotateY
        );
        const rotationZ = new THREE.Matrix4().makeRotationAxis(
          new THREE.Vector3(0, 0, 1),
          modelTransform.rotateZ
        );

        const m = new THREE.Matrix4().fromArray(matrix);
        const translateX = modelTransform.translateX !== undefined ? modelTransform.translateX : 0;
        const translateY = modelTransform.translateY !== undefined ? modelTransform.translateY : 0;
        const translateZ = modelTransform.translateZ !== undefined ? modelTransform.translateZ : 0;
        let scale = modelTransform.scale !== undefined ? modelAsMercatorCoordinate.meterInMercatorCoordinateUnits() * (8 + (this.map.getZoom() > 18 ? 0 : (19 - this.map.getZoom() + 2) * 2)) : 10;

        const l = new THREE.Matrix4()
          .makeTranslation(translateX, translateY, translateZ)
          .scale(new THREE.Vector3(scale, -scale, scale))
          .multiply(rotationX)
          .multiply(rotationY)
          .multiply(rotationZ);

        this.camera.projectionMatrix = m.multiply(l);
        if (this.renderer) {
          this.renderer.resetState();
          this.renderer.render(this.scene, this.camera);
        }

        this.map.triggerRepaint();
      }
    }
    return customLayer;
  }

  addModelToLayer(model3d: any, layer: string) {
    this.globalMap.addLayer(model3d, layer);
  }

  addMarker(contentHtml: any, lng: number, lat: number, isIcon = false, id = 0, model: any = null) {
    let marker = new mapboxgl.Marker(contentHtml, {offset: this.reOffsetMarker(isIcon)})
      .setLngLat([lng, lat])
      .addTo(this.globalMap)
    this.markers.push({
      id: id,
      isIcon: isIcon,
      marker: marker,
      model: model
    })
    this.globalMap.on('zoom', () => {
      marker.setOffset(this.reOffsetMarker(isIcon));
    });
  }

  reOffsetMarker(isIcon = false) {
    let newOffset = [0, 0]
    const zoom = this.globalMap.getZoom();
    if (zoom > this.defaultZoom) {
      let offset = isIcon ? -125 : -50;

      newOffset = [0, offset + ((this.defaultZoom - zoom) * 50)];

    } else {
      let offset = isIcon ? -125 : -50;
      newOffset = [0, offset + ((this.defaultZoom - zoom) * 10)];
    }

    return newOffset
  }

  removeMarker(id: any) {
    let marker = this.markers.filter(item => item.id === id)[0];
    let indexMarker = this.markers.indexOf(marker);
    this.previousModel = marker.model;
    this.markers.splice(indexMarker, 1);
    marker.marker.remove();
  }

  removeAllMarker() {
    this.markers.forEach(
      (marker, index: number) => {
        marker.marker.remove();
        if (index === this.markers.length - 1) {
          this.markers = [];
        }
      }
    )
  }

  updateModel(modelName: any, properties: any) {
    const model = this.markers.find(marker => marker.id === modelName)?.model;
    if (model) {
      if (properties.lightIntensity) {
        model.scene.children.forEach((child: any) => {
          if (child instanceof THREE.AmbientLight) {
            child.intensity = properties.lightIntensity;
          }
        });
      }
    }
  }

  getModelById(id: any) {
    return this.markers.find(marker => marker.id === id)?.model;
  }

  setCurrentModel(model: any) {
    this.currentModel = model;
  }

  setPreviousModel(model: any) {
    this.previousModel = model;
  }

  changeCenter(lng: number, lat: number) {
    this.globalMap.setCenter(
      {lng: lng, lat: lat}
    )
  }


}
