import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {DeleteDialogComponent} from './dialog/delete-dialog/delete-dialog.component';
import {FormTableComponent} from './table/form-table/form-table.component';
import {TranslateModule} from "@ngx-translate/core";
import {CRUDTableModule} from "../../_metronic/shared/crud-table/crud-table.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {WidgetFormComponent} from './form/widget-form/widget-form.component';
import {ToastrModule} from "ngx-toastr";
import {PopUpActionComponent} from "./usage-component/pop-up-action/pop-up-action.component";
import {SuccessDialogComponent} from "./dialog/success-dialog/success-dialog.component";
import {NgSelectModule} from "@ng-select/ng-select";
import {InlineSVGModule} from "ng-inline-svg-2";
import {DownloadButtonComponent} from './button/download-button/download-button.component';
import {InputWithIconComponent} from './input/input-with-icon/input-with-icon.component';
import {InputImageComponent} from './input/input-image/input-image.component';
import {InputDateComponent} from './input/input-date/input-date.component';
import {NgxDaterangepickerMd} from "ngx-daterangepicker-material";
import {DialogWithContentComponent} from './dialog/dialog-with-content/dialog-with-content.component';
import {CdkDragPlaceholder} from "@angular/cdk/drag-drop";
import {OutsideClickDirective} from "./directives/outside-click.directive";
import {SharedHeaderComponent} from './shared-header/shared-header.component';
import {IncidentTypeComponent} from './usage-component/incident-type/incident-type.component';
import {CloseButtonComponent} from './button/close-button/close-button.component';
import {CustomFormTableComponent} from "./table/custom-form-table/custom-form-table.component";
import {FolderCardComponent} from './usage-component/folder-card/folder-card.component';
import {DateRangeComponent} from './input/date-range/date-range.component';
import {MatInputModule} from "@angular/material/input";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatNativeDateModule, MatOptionModule} from "@angular/material/core";
import {MatIconModule} from "@angular/material/icon";
import {TimePickerComponent} from './input/time-picker/time-picker.component';
import {DateRangeWithLabelComponent} from './input/date-range-with-label/date-range-with-label.component';
import {FormSensitevityComponent} from './form/form-sensitevity/form-sensitevity.component';
import {MatRadioModule} from "@angular/material/radio";
import {MatSelectModule} from "@angular/material/select";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {
  ButtonNotificationComponent
} from './usage-component/notification/button-notification/button-notification.component';
import {NotificationListComponent} from './usage-component/notification/notification-list/notification-list.component';
import {SharedGoogleMapComponent} from './shared-google-map/shared-google-map.component';
import {GoogleMapsModule} from "@angular/google-maps";
import {NotificationCardComponent} from './usage-component/notification/notification-card/notification-card.component';
import {ButtonProgressComponent} from './button/button-progress/button-progress.component';
import {HighlightTextPipe} from "./pipe/highlight-text-pipe";
import {DefaultImageDirective} from "./directives/default-image.directive";
import {CountUpDirective} from "./directives/count-up-directive";
import {ImageViewerComponent} from './dialog/image-viewer/image-viewer.component';
import {TimeZonePipe} from "./pipe/time-zone.pipe";
import {ValidatorFormComponent} from "./form/validator-form/validator-form.component";
import {FilterLineComponent} from "./filter-line/filter-line.component";
import {BatteryIconComponent} from "./usage-component/battery-icon/battery-icon.component";
import {CellularIconComponent} from "./usage-component/cellular-icon/cellular-icon.component";
import {NgxMapboxGLModule} from "ngx-mapbox-gl";
import {SharedMapBoxComponent} from "./shared-map-box/shared-map-box.component";
import {AlertDialogComponent} from './alert-dialog/alert-dialog/alert-dialog.component';
import {DialogContainerComponent} from "./dialog/dialog-container/dialog-container.component";
import {OpenNewTabPipe} from "./pipe/open-new-tab.pipe";
import {SharedMapRosComponent} from "./shared-map-ros/shared-map-ros.component";
import { FlagIconComponent } from './usage-component/flag-icon/flag-icon.component';
import {VideoViewerComponent} from "./dialog/video-viewer/video-viewer.component";

@NgModule({
  declarations: [
    DeleteDialogComponent,
    FormTableComponent,
    WidgetFormComponent,
    PopUpActionComponent,
    SuccessDialogComponent,
    DownloadButtonComponent,
    InputWithIconComponent,
    InputImageComponent,
    DownloadButtonComponent,
    InputDateComponent,
    DialogWithContentComponent,
    OutsideClickDirective,
    SharedHeaderComponent,
    IncidentTypeComponent,
    CloseButtonComponent,
    CustomFormTableComponent,
    FolderCardComponent,
    DateRangeComponent,
    TimePickerComponent,
    DateRangeWithLabelComponent,
    FormSensitevityComponent,
    ButtonNotificationComponent,
    NotificationListComponent,
    SharedGoogleMapComponent,
    NotificationCardComponent,
    ButtonProgressComponent,
    HighlightTextPipe,
    DefaultImageDirective,
    CountUpDirective,
    ImageViewerComponent,
    ValidatorFormComponent,
    FilterLineComponent,
    BatteryIconComponent,
    CellularIconComponent,
    SharedMapBoxComponent,
    AlertDialogComponent,
    OpenNewTabPipe,
    DialogContainerComponent,
    SharedMapRosComponent,
    FlagIconComponent,
    VideoViewerComponent
  ],
  exports: [
    FormTableComponent,
    WidgetFormComponent,
    PopUpActionComponent,
    DownloadButtonComponent,
    DownloadButtonComponent,
    InputWithIconComponent,
    InputImageComponent,
    InputDateComponent,
    SuccessDialogComponent,
    DialogWithContentComponent,
    OutsideClickDirective,
    SharedHeaderComponent,
    IncidentTypeComponent,
    CloseButtonComponent,
    CustomFormTableComponent,
    FolderCardComponent,
    DateRangeComponent,
    TimePickerComponent,
    DateRangeWithLabelComponent,
    FormSensitevityComponent,
    ButtonNotificationComponent,
    SharedGoogleMapComponent,
    NotificationCardComponent,
    ButtonProgressComponent,
    HighlightTextPipe,
    DefaultImageDirective,
    CountUpDirective,
    TimeZonePipe,
    ValidatorFormComponent,
    TimeZonePipe,
    FilterLineComponent,
    TimeZonePipe,
    SharedMapBoxComponent,
    CountUpDirective,
    BatteryIconComponent,
    CellularIconComponent,
    AlertDialogComponent,
    OpenNewTabPipe,
    DialogContainerComponent,
    SharedMapRosComponent,
    FlagIconComponent,
    VideoViewerComponent

  ],
  imports: [
    CommonModule,
    TranslateModule,
    CRUDTableModule,
    ReactiveFormsModule,
    InlineSVGModule,
    NgSelectModule,
    FormsModule,
    NgxDaterangepickerMd.forRoot(),
    NgxMapboxGLModule.withConfig({
      accessToken: 'pk.eyJ1IjoiYWloYW1zaGlraG8iLCJhIjoiY2x1ZWxwd2FsMXN4MjJrbmkycjg0emYzZyJ9.FLTFwbQ1ev6ZzcYqAgOQ4w', // Optional, can also be set per map (accessToken input of mgl-map)
    }),
    ToastrModule.forRoot({
      iconClasses: {
        error: 'fas fa-trash',
        info: 'fa fa-info',
        success: 'toastr-check',
        warning: 'something',
      },
    }),
    CdkDragPlaceholder,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule,
    MatOptionModule,
    MatRadioModule,
    MatSelectModule,
    MatSlideToggleModule,
    GoogleMapsModule,
    TimeZonePipe
  ],
  providers: [DatePipe,]
})
export class SharedModuleModule {
}
