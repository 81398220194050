import {Inject, Injectable, Injector} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";
import {TableService} from "./table.service";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {TableHTTPService} from "./table-http/table-http.service";
import {NotiModel} from "../models/noti.model";

@Injectable({
  providedIn: 'root'
})
export class NotificationService extends TableService<NotiModel> {

  API_URL = `${environment.apiUrl}/auth/notifications`;
  private notificationCountSubject = new BehaviorSubject<number>(0);
  public notificationCount$ = this.notificationCountSubject.asObservable();

  constructor(@Inject(HttpClient) http: HttpClient,
              @Inject(TableHTTPService) protected tablehttpservice: TableHTTPService<NotiModel>) {
    super(http, tablehttpservice);

  }

  setNotificationCount(value: number) {
    this.notificationCountSubject.next(value);
  }

  updateNotificationCount() {
    this.notificationCountSubject.next(this.getNotificationCount() + 1);
  }

  getNotificationCount() {
    return +this.notificationCountSubject.value;
  }

  getUnseenCount(): Observable<any> {
    return this.http.get(`${this.API_URL}/unseen-count`)
  }

  clear(): Observable<any> {
    return this.http.delete(`${this.API_URL}/clear`)
  }

  seeAll(): Observable<any> {
    return this.http.get(`${this.API_URL}/see-all`)
  }


}
