import {Inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {TableService} from "../../../core/services/table.service";
import {environment} from "../../../../environments/environment";
import {TableHTTPService} from "../../../core/services/table-http/table-http.service";
import {BehaviorSubject, Observable} from "rxjs";
import {AreaModel} from "../models/area.model";
import {DateRangeModel} from "../models/date-range.model";
import {AddSensitivityAreaRequestModel} from "../models/request/add-sensitivity-area.request.model";
import {SpotFilterModel} from "../../surveillance/models/spot-filter.model";
import {PaginatorState} from "../../../core/base/models/crud-table-model";
import {SpotModel} from "../../surveillance/models/spot.model";


@Injectable({
  providedIn: 'root'
})
export class SurveillanceService extends TableService<AreaModel> {

  API_URL = `${environment.apiUrl}/surveillance`;
  currentSpots = new BehaviorSubject<SpotModel[]>([]);
  currentSpots$ = this.currentSpots.asObservable();

  constructor(@Inject(HttpClient) http: HttpClient,
              @Inject(TableHTTPService) protected tablehttpservice: TableHTTPService<AreaModel>,
  ) {
    super(http, tablehttpservice);

  }


  addSensitivityArea(request: AddSensitivityAreaRequestModel): Observable<any> {
    return this.http.post(`${this.API_URL}/matrix/add`, request)
  }

  updateSensitivityArea(id: string, request: AddSensitivityAreaRequestModel): Observable<any> {
    return this.http.put(`${this.API_URL}/matrix/update/${id}`, request)
  }

  getAreasTree(areaTypeId: any, name: string | null = null): Observable<any> {
    return this.http.get(`${this.API_URL}/areas/list${areaTypeId === '0' ? '' : '?areaTypes=' + areaTypeId}${name ? areaTypeId === '0' ? '?name=' + name : '&name=' + name : ''}`)
  }

  getStreets(id: any, dateRange: DateRangeModel) {
    return this.http.get(this.getUrlWithParam(`${this.API_URL}/areas/incidents-by-area-street/${id}`, dateRange))
  }

  getNationality(id: any, dateRange: DateRangeModel) {
    return this.http.get(this.getUrlWithParam(`${this.API_URL}/areas/incidents-by-area-nationality/${id}`, dateRange))
  }

  getIncidentsByEthnicity(id: any, dateRange: DateRangeModel) {
    return this.http.get(this.getUrlWithParam(`${this.API_URL}/areas/incidents-by-area-ethnicity/${id}`, dateRange))
  }

  getEthnicity(): Observable<any> {
    return this.http.get(`${this.API_URL}/ai/ethnicity`)
  }

  getIncident(id: any, dateRange: DateRangeModel, incidentStatus: string | null) {
    let url = this.getUrlWithParam(`${this.API_URL}/areas/incidents-by-area/${id}`, dateRange);
    incidentStatus !== null ? url = `${url}status=${incidentStatus}` : ''
    return this.http.get(url)
  }

  getChart(id: any, dateRange: DateRangeModel, incidentClassification: string | null = null) {
    let url = this.getUrlWithParam(`${this.API_URL}/areas/incidents-by-area-daily-insight/${id}`, dateRange);
    incidentClassification !== null ? url = `${url}classification=${incidentClassification}` : ''
    return this.http.get(url)
  }

  getSensitiveArea(id: any): Observable<any> {
    return this.http.get(`${this.API_URL}/areas/matrix-by-area/${id}`)
  }

  deleteSensitiveArea(id: any): Observable<any> {
    return this.http.delete(`${this.API_URL}/matrix/${id}`)
  }

  getAllBehaviours(): Observable<any> {
    return this.http.get(`${this.API_URL}/behaviours`)
  }

  getAllNationalities(): Observable<any> {
    return this.http.get(`${this.API_URL}/nationalities`)
  }

  getUrlWithParam(url: string, items: DateRangeModel) {
    let returnedUrl = url + "?";
    (Object.keys(items) as (keyof typeof items)[]).map((key) => {
      returnedUrl = `${returnedUrl}${key}=${items[key]}&`;
    });
    return returnedUrl;
  }

  getArea(id: any): Observable<any> {
    return this.http.get(`${this.API_URL}/areas/${id}`)
  }

  getTypes(): Observable<any> {
    return this.http.get(`${this.API_URL}/area-types`)
  }

  lightAction(value: any) {
    return this.http.post(`${this.API_URL}/areas/control`, value)
  }

  getSpotCategories(): Observable<any> {
    return this.http.get(`${this.API_URL}/spot-categories/all`);
  }

  getSpotTags(): Observable<any> {
    return this.http.get(`${this.API_URL}/spot-tags/all`);
  }

  getAllSpot(filter: SpotFilterModel): Observable<any> {
    return this.http.post(`${this.API_URL}/spots/filtered-list`, filter);
  }

  pinUnpinSpot(id: number) {
    return this.http.post(`${this.API_URL}/spots/pin-unpin/${id}`, {})
  }

  unpinAll() {
    return this.http.post(`${this.API_URL}/spots/unpin-all`, {})
  }

  getAllSpotPinned(paginator: PaginatorState) {
    return this.http.post(`${this.API_URL}/spots/pinned-list`, {paginator})
  }

}
