import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'highlightText'
})
export class HighlightTextPipe implements PipeTransform {
  transform(value: string, searchString: string[], id: any[]): string {
    if (!searchString || !value) {
      return value;
    }
      for(let i = 0; i < searchString.length; i++){
        const regex = new RegExp(searchString[i], 'gi');
        value=value.replace(regex, `<span class="highlighted-text ${searchString[i]} ${id[i]} ">${id[i]}</span>`);
      }
    return value;
  }
}

