import * as SLUGS from '../../../core/config/slugs';
import {formUrl} from "../../../core/config/helper";

export const FORGOT_PASSWORD = {
  baseForgotPassword: () => formUrl(SLUGS.AUTH,SLUGS.FORGOT_PASSWORD),
};

export const LOGIN = {
    baseLogin: () => formUrl(SLUGS.AUTH,SLUGS.LOGIN),
  };
  
