import {RobotStatusEnum} from "../../../core/config/enum/robot-status.enum";
import {RobotLocationModel} from "./robot-location.model";
import {RobotEnum} from "./robot.enum";
import {RobotRealTimeDataInterface} from "../../tele-operator/model/panel/robot-real-time-data.interface";

const CLASS_MISSION = 'marker-patrol-mission';
const CLASS_CONTROL = 'marker-patrol-control';
const CLASS_EMERGENCY = 'marker-patrol-emergence';
const CLASS_MAINTENANCE = 'marker-patrol-maintenance';
const CLASS_PAUSED = 'marker-patrol-paused';
const CLASS_CHARGING = 'marker-patrol-charging';
const CLASS_STAND_BY = 'marker-patrol-stand';
const CLASS_KEY_OFF = 'marker-patrol';
const BATTERY_BLACK = 'battery-marker-black';
const BATTERY_WHITE = 'battery-marker';

const CELLULAR_WHITE = 'marker-cellular-bar-white';
const CELLULAR_BLACK = 'marker-cellular-bar';


const BACKGROUND_ON = '#014EFF';
const BACKGROUND_EMERGENCY = '#C41E31';
const BACKGROUND_MAINTENANCE = '#D8971D';
const BACKGROUND_PAUSED = '#000000';
const BACKGROUND_CHARGING = '#000000';
const BACKGROUND_STAND_BY = '#0EFAA2';
const BACKGROUND_KEY_OFF = '#000000';
const BACKGROUND_FREE_DRIVING = '#20C451';

const TEXT_ON = '#014EFF';
const TEXT_EMERGENCY = '#C41E31';
const TEXT_MAINTENANCE = '#D8971D';
const TEXT_PAUSED = '#000000';
const TEXT_CHARGING = '#000000';
const TEXT_STAND_BY = '#0EFAA2';
const TEXT_KEY_OFF = '#000000';
const TEXT_FREE_DRIVING = '#20C451';

const CELLULAR_CLASS = {
  [RobotStatusEnum.KEY_OFF]: '',
  [RobotStatusEnum.MISSION_ON]: CELLULAR_WHITE,
  [RobotStatusEnum.UNDER_MAINTENANCE]: CELLULAR_BLACK,
  [RobotStatusEnum.PAUSED]: CELLULAR_WHITE,
  [RobotStatusEnum.FREE_DRIVING]: CELLULAR_BLACK,
  [RobotStatusEnum.EMERGENCY]: CELLULAR_WHITE,
  [RobotStatusEnum.STAND_BY]: CELLULAR_BLACK,
  [RobotStatusEnum.CHARGING]: CELLULAR_WHITE,
}

const BATTERY_CLASS = {
  [RobotStatusEnum.KEY_OFF]: '',
  [RobotStatusEnum.MISSION_ON]: BATTERY_WHITE,
  [RobotStatusEnum.UNDER_MAINTENANCE]: BATTERY_BLACK,
  [RobotStatusEnum.PAUSED]: BATTERY_BLACK,
  [RobotStatusEnum.FREE_DRIVING]: BATTERY_BLACK,
  [RobotStatusEnum.EMERGENCY]: BATTERY_WHITE,
  [RobotStatusEnum.STAND_BY]: BATTERY_BLACK,
  [RobotStatusEnum.CHARGING]: BATTERY_WHITE,
}

const ICON_URL = {
  [RobotStatusEnum.KEY_OFF]: '../../../assets/media/microspot-icon/patrol/key-off-small.svg',
  [RobotStatusEnum.MISSION_ON]: '../../../assets/media/microspot-icon/patrol/mission-icon.svg',
  [RobotStatusEnum.UNDER_MAINTENANCE]: '../../../assets/media/microspot-icon/patrol/maintenance-icon.svg',
  [RobotStatusEnum.PAUSED]: '../../../assets/media/microspot-icon/patrol/pause-icon.svg',
  [RobotStatusEnum.FREE_DRIVING]: '../../../assets/media/microspot-icon/patrol/right-icon.svg',
  [RobotStatusEnum.EMERGENCY]: '../../../assets/media/microspot-icon/patrol/emergency-icon.svg',
  [RobotStatusEnum.STAND_BY]: '../../../assets/media/microspot-icon/patrol/right-icon.svg',
  [RobotStatusEnum.CHARGING]: '../../../assets/media/microspot-icon/patrol/charging-icon.svg',
}
const BACKGROUND_CLASS = {
  [RobotStatusEnum.KEY_OFF]: BACKGROUND_KEY_OFF,
  [RobotStatusEnum.MISSION_ON]: BACKGROUND_ON,
  [RobotStatusEnum.UNDER_MAINTENANCE]: BACKGROUND_MAINTENANCE,
  [RobotStatusEnum.PAUSED]: BACKGROUND_PAUSED,
  [RobotStatusEnum.FREE_DRIVING]: BACKGROUND_FREE_DRIVING,
  [RobotStatusEnum.EMERGENCY]: BACKGROUND_EMERGENCY,
  [RobotStatusEnum.STAND_BY]: BACKGROUND_STAND_BY,
  [RobotStatusEnum.CHARGING]: BACKGROUND_CHARGING,
}
const TEXT_CLASS = {
  [RobotStatusEnum.KEY_OFF]: TEXT_KEY_OFF,
  [RobotStatusEnum.MISSION_ON]: TEXT_ON,
  [RobotStatusEnum.UNDER_MAINTENANCE]: TEXT_MAINTENANCE,
  [RobotStatusEnum.PAUSED]: TEXT_PAUSED,
  [RobotStatusEnum.FREE_DRIVING]: TEXT_FREE_DRIVING,
  [RobotStatusEnum.EMERGENCY]: TEXT_EMERGENCY,
  [RobotStatusEnum.STAND_BY]: TEXT_STAND_BY,
  [RobotStatusEnum.CHARGING]: TEXT_CHARGING,
}



export class RobotModel {
  id: any;
  robotMasterData : RobotMasterDataInterface
  robotRealTimeData : RobotRealTimeDataInterface
  robotDrivingModeData : RobotDrivingModeDataInterface
  selected: boolean;
  activeUser?:ActiveUsersInterface
  constructor(
  ) {

  }

  get background() {
    return BACKGROUND_CLASS[this.robotDrivingModeData.robotMode];
  }
  get connection() {
    return Math.floor( this.robotRealTimeData.connectionQuality / 20);
  }
  get status() {
    return this.robotDrivingModeData.robotMode;
  }  get battery() {
    return this.robotRealTimeData.batteryInfo.percentage;
  }
  get missionComplete(){
    return this.robotRealTimeData.distanceInfo.distancePercentage
  }
  get location(){
    return new RobotLocationModel(this.robotRealTimeData.gpsInfo.longitude,this.robotRealTimeData.gpsInfo.latitude)
  }

  get drivingMode(){
    return this.robotRealTimeData.drivingMode;
  }

  get name(){
    return this.robotMasterData.name
  }
  get type(){
    return this.robotMasterData.robotType.name
  }
  setActiveUser(user:ActiveUsersInterface){
    this.activeUser = user
  }
  init(
    id: any,
  robotMasterData : RobotMasterDataInterface,
  robotRealTimeData : RobotRealTimeDataInterface,
  robotDrivingModeData : RobotDrivingModeDataInterface
){
   this.id = id;
   this.robotMasterData = robotMasterData
    this.robotRealTimeData = robotRealTimeData
    this.robotDrivingModeData = robotDrivingModeData
  }
  get backgroundText() {
    return TEXT_CLASS[this.robotDrivingModeData.robotMode];
  }

  getClassByStatus() {
    switch (this.robotDrivingModeData.robotMode) {
      case RobotStatusEnum.CHARGING:
        return CLASS_CHARGING;
      case RobotStatusEnum.MISSION_ON:
        return CLASS_MISSION;
      case RobotStatusEnum.KEY_OFF:
        return CLASS_KEY_OFF;
      case RobotStatusEnum.PAUSED:
        return CLASS_PAUSED;
      case RobotStatusEnum.EMERGENCY:
        return CLASS_EMERGENCY;
      case RobotStatusEnum.STAND_BY:
        return CLASS_STAND_BY;
      case RobotStatusEnum.FREE_DRIVING:
        return CLASS_CONTROL;
      case RobotStatusEnum.UNDER_MAINTENANCE:
        return CLASS_MAINTENANCE
      default:
        return '';
    }
  }

  getBatteryClassBasedOnStatus() {
    return BATTERY_CLASS[this.robotDrivingModeData.robotMode];

  }

  getCellular() {
    return CELLULAR_CLASS[this.robotDrivingModeData.robotMode];
  }

  getIconUrl() {
    return ICON_URL[this.robotDrivingModeData.robotMode];
  }

  getClassBatteryBasedOnStatus() {

  }

  getSelected() {
    return this.selected;
  }

  setSelected(selected: boolean) {
    this.selected = selected;
  }
  public  updateValue(key: keyof this,value:any){
    this[key] = value
  }

  static fieldsForUpdateMarker(){
    return [RobotEnum.ROBOT_REALTIME_DATA]
  }
  static fieldsForUpdateActiveUsers(){
    return [RobotEnum.ROBOT_DRIVING_MODE_DATA]
  }
}

export interface RobotMasterDataInterface{
  name : string;
  port : number;
  ipAddress : string;
  model : string;
  robotType: RobotTypeInterface;
  serialNumber : string;
}

export interface RobotTypeInterface{
  id:number;
  name:string;
  robotCategory:any;
  code:string;
}

export interface RobotDrivingModeDataInterface{
  robotMode : RobotStatusEnum;
  helpMessages : any;
  robotModeActions : any[];
  emergencyCause : string | null

}

export interface ActiveUsersInterface{
  id : string;
  name : string;
  photo: string;
  actionName:string;
}
