export enum NotificationTypeEnum {
  PATROL_STATUS = 'PATROL_STATUS',
  INCIDENT_REMINDER = 'INCIDENT_REMINDER',
  NEW_INCIDENT = 'NEW_INCIDENT',
  UPDATE_INCIDENT_STATUS = 'UPDATE_INCIDENT_STATUS',
  AREA_SENSITIVITY_SETTING_EXPIRATION = 'AREA_SENSITIVITY_SETTING_EXPIRATION',
  GENERAL = 'GENERAL',
  POTENTIAL_BREAK_IN = 'POTENTIAL_BREAK_IN',
  NEW_TRACKING = 'NEW_TRACKING',
  API_KEY_ABOUT_TO_EXPIRE = "API_KEY_ABOUT_TO_EXPIRE",

}
