// USA
export const locale = {
  lang: 'en',
  data: {
    PROJECT: {
      COMPANY: 'Microspot',
      TITLE: ' Diplomatic Affairs Portal ',
      DOC: 'Login to your account',
      TITLE_EXTERNAL: 'Welcome to the Diplomatic Gateway'
    },
    TRANSLATOR: {
      SELECT: 'Select your language',
    },
    MENU: {
      AREAS_SENSITIVITY: 'Areas & Sensitivity',
      INCIDENTS_MONITOR: 'Incidents Monitor',
      SURVEILLANCE: 'Surveillance',
      INCIDENTS: 'Incidents',
      CRIMINAL_BASE: 'Criminal Base',
      MAP: 'Map',
      NEW: 'new',
      ACTIONS: 'Actions',
      CREATE_POST: 'Create New Post',
      PAGES: 'Pages',
      FEATURES: 'Features',
      APPS: 'Apps',
      DASHBOARD: 'Dashboard',
      ERRORS: 'Errors',
      TRY_AGIN: "please try again",
      USERS: 'Users',
      DOCUMENT: 'Document',
      FACILITIES: 'Facilities',
      SENSOR_TYPES: 'Sensor Types',
      SERVICE_PROVIDERS: 'Service Providers',
      TECH_TEAM: 'Technical Team',
      CUSTOMERS: 'Customers',
      CALENDAR: "Calendar",
      LOG_OUT: "Log Out",
      ACCOUNT: "Account",
      DROPDOWN_USERS: {
        DIPLOMATIC_USERS: 'Diplomatic users',
        EMPLOYEES: 'Employees',
        ADMINS: 'Admins'
      },
      ROBOTS: 'My Robots',
      MISSIONS: 'Maps & Missions'

    },
    AUTH: {
      GENERAL: {
        OR: 'Or',
        SUBMIT_BUTTON: 'Submit',
        NO_ACCOUNT: 'Don\'t have an account?',
        SIGNUP_BUTTON: 'Sign Up',
        SIGNIN_BUTTON: 'sign in',
        FORGOT_BUTTON: 'Forgot Password',
        BACK_BUTTON: 'Back',
        BACK_DESC: 'Back to',
        PRIVACY: 'Privacy',
        LEGAL: 'Legal',
        CONTACT: 'Contact',
        AGRED_TO: 'agreed to',
        TERMS: 'terms ',
        OF_USE_AND: 'of use and ',
        PRIVACY_STATEMENTS: 'privacy statements'
      },
      LOGIN: {
        TITLE: 'Login Account',
        BUTTON: 'Login',
        DESC: 'This site is monitored and maintained by Dubai Police. For any assistance or inquiries, please contact our support team.'
      },
      FORGOT: {
        TITLE: 'Forgotten Password?',
        DESC: 'Enter your email to reset your password',
        SUCCESS: 'Your account has been successfully reset.',
        BUTTON: 'Reset Password',
        LINK: 'Reset it',
        ASK: 'Forgot your password?'
      },
      REGISTER: {
        TITLE: 'create a new account',
        DESC: 'We recommended using a trusted personal email for interacting with government websites.',
        DESC_BUTTON: 'Enter your details to create your account',
        SUCCESS: 'Your account has been successfuly registered.',
        BUTTON: 'SIGN IN ',
        BUTTON_CREATE: 'CREATE USER ',
        ERRO: 'The registration details are incorrect'

      },
      INPUT: {
        EMAIL: 'Email',
        FULLNAME: 'Fullname',
        PASSWORD: 'Password',
        CONFIRM_PASSWORD: 'Confirm Password',
        USERNAME: 'Username',
        FORGET_PASSWORD: 'Forget Password?',
      },
      VALIDATION: {
        INVALID: '{{name}} is not valid',
        REQUIRED: '{{name}} is required',
        MIN_LENGTH: '{{name}} minimum length is {{min}}',
        MAX_LENGTH: '{{name}} should have maximum {{max}} symbols',
        AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
        NOT_FOUND: 'The requested {{name}} is not found',
        INVALID_LOGIN: 'The login detail is incorrect',
        REQUIRED_FIELD: 'Required field',
        MIN_LENGTH_FIELD: 'Minimum field length:',
        MAX_LENGTH_FIELD: 'Maximum field length:',
        INVALID_FIELD: 'Field is not valid',
        EMAIL_HINT: 'Enter your email address.',
        PASSWORD_HINT: 'Must be at least 8 characters.',
        PASSWORD_REQUIREMENTS: 'Password must contain at least one number, one special character, and be 7-15 characters long.',
        CONFIRM_PASSWORD_DESCRIPTION: "Please re-enter your password for confirmation.",
        MATCHED_PASSWOED_ERROR: "The passwords don't match.",
        REQUIRED_PASSWORD: "Passwords are required.",
        CONFIRM_PASSWORD: 'Confirm Password ',
        RESET_PASSWORD: 'Update your password',
        SHOW_PASSWORD: 'Show Password'
      },
      VERIFY_EMAIL: {
        TITLE: ' Please check your email',
        DESC: 'we sent to this email',
      },
      VERIFY_NUMBER: {
        TITLE: 'Please check your number',
        DESC: 'we sent to this number ',
      },
      RESET_PASSWORD: {
        TITLE: 'Set your Password',
        DEC: "Enter your new password"
      }
    },
    MODULES: {
      SERVICE_PROVIDERS: {
        NEW: 'New Provider',
        EDIT: 'Edit Provider',
      },
      TECH_TEAM: {
        NEW: 'New User',
        EDIT: 'Edit User',
      },
      CUSTOMERS: {
        NEW: 'New Customer',
        EDIT: 'Edit Customer',
      },
      FACILITIES: {
        NEW: 'New Facility',
        EDIT: 'Edit Facility'
      },
      INPUTS: {
        ACTIONS: 'Actions',
        CANCEL: 'Cancel',
        SAVE: 'Save',
        NAME: 'Name',
        ADDRESS: 'Address',
        TYPE: 'Type',
        SENSORS: 'Sensors',
        CREATON_DATE: 'Creation Date',
        PHONE: 'Phone Number',
        FACILITIES: 'Facilities',
        FACILITY: 'Facility',
        CUSTOMER: 'Customer',
        VIEW_ALL: 'View All',
      }
    },
    FOOTER: {
      FAQS: 'FAQS',
      PRIVACTPOLICY: 'Privact Policy',
      TERMSCONDITIONS: 'Terms conditions',
      SUPPORT: 'support'
    },
    ECOMMERCE: {
      COMMON: {
        SELECTED_RECORDS_COUNT: 'Selected records count: ',
        ALL: 'All',
        SUSPENDED: 'Suspended',
        ACTIVE: 'Active',
        FILTER: 'Filter',
        BY_STATUS: 'by Status',
        BY_TYPE: 'by Type',
        BUSINESS: 'Business',
        INDIVIDUAL: 'Individual',
        SEARCH: 'Search',
        IN_ALL_FIELDS: 'in all fields'
      },
      ECOMMERCE: 'eCommerce',
      CUSTOMERS: {
        CUSTOMERS: 'Customers',
        CUSTOMERS_LIST: 'Customers list',
        NEW_CUSTOMER: 'New Customer',
        DELETE_CUSTOMER_SIMPLE: {
          TITLE: 'Customer Delete',
          DESCRIPTION: 'Are you sure to permanently delete this customer?',
          WAIT_DESCRIPTION: 'Customer is deleting...',
          MESSAGE: 'Customer has been deleted'
        },
        DELETE_CUSTOMER_MULTY: {
          TITLE: 'Customers Delete',
          DESCRIPTION: 'Are you sure to permanently delete selected customers?',
          WAIT_DESCRIPTION: 'Customers are deleting...',
          MESSAGE: 'Selected customers have been deleted'
        },
        UPDATE_STATUS: {
          TITLE: 'Status has been updated for selected customers',
          MESSAGE: 'Selected customers status have successfully been updated'
        },
        EDIT: {
          UPDATE_MESSAGE: 'Customer has been updated',
          ADD_MESSAGE: 'Customer has been created'
        }
      }
    },
    PLACEHOLDER: {
      SEARCH_USERS: 'Search users'
    },
    INCIDENT: {
      INCIDENT_MONITOR: "Incidents Monitor",
      INCIDENT: "Incident",
      INCIDENTS_COUNT: "Incidents count",
      CLASSIFICATION: {
        WANTED: "Wanted"
      },
      DETECTED_INCIDENTS: 'Detected Incidents'
    },
    NOTIFICATION: {
      NEW_ALERT: "New Alert"
    },
    AREA: {
      AREAS: 'Areas',
      AREA_TYPE: 'Area Type',
      TITLE: 'Areas & Sensitivity',
      SHOW_ALL_TYPES: 'Show all types'
    },
    INCIDENT_TABLE: {
      INCIDENTS: 'Incidents',
      DESCRIPTION: 'Incident Description',
      RELATED_SUSPECT: 'Related Suspect',
      AI_ACCURACY: 'AI Accuracy',
      TRACKED: 'Tracked',
      STATUS: 'Status',
      INCIDENT_STATUS: 'Incident Status',
      DATE: 'Date',
      ALL_STATUS: 'All Status',
      TIMES: 'Times'
    },
    PAGINATION: {
      SHOW: 'Show'
    },
    CRIMINAL_BASE: {
      CRIMINAL_BASE: 'Criminal Base',
      WANTED_LIST: 'Wanted List',
      SUSPECT_LIST: 'Suspect List',
      SEARCH: 'Search',
      CRIMES: 'Crimes',
      ASSOCIATE_NAME: 'Associate Name',
      FATHER_NAME: 'Father Name',
      MOTHER_NAME: 'Mother Name',
      ID_NO: 'ID NO.'
    },
    INCIDENT_DETAIL: {
      INCIDENT_DETAIL: 'Incident Details',
      INCIDENT_DESCRIPTION: 'Incident Description',
      SUSPECT_TRACKING: 'Suspect Tracking',
      CASE_FILES: 'Case Files',
      INCIDENT_STATUS: 'Incident Status',
    },
    SURVEILLANCE: {
      SURVEILLANCE: 'Surveillance',
      M_PATROLS: 'M PATROLS',
      CAMERAS: 'Cameras'
    },
    INCIDENT_DESCRIPTION: {
      AI_ACCURACY: 'AI Accuracy',
      DESCRIPTION: 'Description',
      LATITUDE: 'Latitude',
      LONGITUDE: 'Longitude',
      NEXT_TO: 'Next to',
      ROOM: 'Room',
      BUILDING: 'Building',
      ROAD: 'Road',
      STREET: 'Street',
      ZONE: 'Zone',
      AREA: 'Area',
      CITY: 'City',
      INCIDENT_LEVEL: 'Incident Level',
      LOCATION: 'Location',
      RELATED_PEOPLE: 'Related People'
    }
  }
};
