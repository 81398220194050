import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {SplashScreenService, STATUS_SPLASH} from './splash-screen.service';

@Component({
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.scss'],
})
export class SplashScreenComponent implements OnInit {
  @ViewChild('splashScreen', {static: true}) splashScreen: ElementRef;
  showElement = true;

  constructor(private splashScreenService: SplashScreenService, private cdRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.splashScreenService.isLoadingStatus.subscribe(
      (res: STATUS_SPLASH) => {
        if (res === STATUS_SPLASH.DISPLAY) {
          this.showElement = true;
          this.cdRef.detectChanges();
        } else if (res === STATUS_SPLASH.RESTART) {
          this.showElement = false;
          this.splashScreenService.init(this.splashScreen);
          this.cdRef.detectChanges();
        } else if (res === STATUS_SPLASH.START_LONG) {
          this.showElement = false;
          this.splashScreenService.start(this.splashScreen);
          this.cdRef.detectChanges();
        } else {
          this.splashScreenService.stopSplash();
          this.cdRef.detectChanges();
        }
      }
    )
  }
}
