import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-close-button',
  templateUrl: './close-button.component.html',
  styleUrls: ['./close-button.component.scss']
})
export class CloseButtonComponent {

  @Output() closeClick: EventEmitter<any> = new EventEmitter<any>();
  @Input() onTop = false;

}
