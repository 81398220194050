export const PageSizes = [3, 5, 10, 15, 50, 100];

export interface IPaginatorState {
  start: number;
  length: number;
  total: number;

  recalculatePaginator(total: number): IPaginatorState;
}

export class PaginatorState implements IPaginatorState {
  start = 0;
  length = PageSizes[2];
  currentPage = 1
  total = 0;
  pageSizes: number[] = [];

  recalculatePaginator(total: number): PaginatorState {
    this.total = total;
    return this;
  }

  init() {
    this.start = 0;
    this.length = PageSizes[2];
    this.total = 0;
    this.pageSizes = [];
    this.currentPage=1
  }
}

export interface IPaginatorView {
  paginator: PaginatorState;

  ngOnInit(): void;

  paginate(paginator: PaginatorState): void;
}
