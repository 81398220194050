import {FORGOT_PASSWORD, LOGIN} from '../../modules/auth/config/auth.clinet'
import {INCIDENT_MAP_CLIENT} from "../../modules/incident-map/config/incidet-map.client";
import {AREAS_SENSITIVITY_CLIENT} from "../../modules/areas-sensitivity/config/areas-sensitivity.client";
import {INCIDENTS_CLIENT} from "../../modules/incidents/config/incidents.client";
import {CRIMINALS_CLIENT} from "../../modules/criminal-base/config/criminals.client";
import {SURVEILLANCE_CLIENT} from "../../modules/surveillance/config/surveillance.client";
import {ALARM_CLIENT} from "../../modules/alarm/config/alarm.client";
import {NOTIFICATIONS_CLIENT} from "../../modules/notifications/config/notifications.client";
import {ROBOTS_CLIENT} from "../../modules/robots/config/robots.client";
import {MISSIONS_CLIENT} from "../../modules/missions/config/mission.client";
import {TELE_OPERATOR_CLIENT} from "../../modules/tele-operator/config/tele-operator.client";

export const Client = {
    ...FORGOT_PASSWORD,
    ...LOGIN,
    ...INCIDENT_MAP_CLIENT,
    ...AREAS_SENSITIVITY_CLIENT,
    ...INCIDENTS_CLIENT,
    ...CRIMINALS_CLIENT,
    ...SURVEILLANCE_CLIENT,
    ...ALARM_CLIENT,
    ...NOTIFICATIONS_CLIENT,
    ...ROBOTS_CLIENT,
    ...MISSIONS_CLIENT,
    ...TELE_OPERATOR_CLIENT
};
