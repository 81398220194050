import {Injectable} from "@angular/core";
import {MyWebSocketService} from "./my-web-socket.service";
import {environment} from "../../../environments/environment";
import {SocketTypeEnum} from "../models/socket-type.enum";

@Injectable({
  providedIn: 'root'
})
export class PtzSocketService extends MyWebSocketService {

  constructor() {
    super();
    this.SOCKET_URL = environment.ptzSocket;
    this.SOCKET_TYPE = SocketTypeEnum.CAMERA_CONTROL;

  }
}
