import {Injectable, Injector} from '@angular/core';
import { Observable } from 'rxjs';
import {BaseService} from "./base.service";
import {HttpHeaders} from "@angular/common/http";
@Injectable({
  providedIn: 'root'
})
export class WeatherService extends BaseService {

  constructor(injector : Injector) {
    super(injector)
    this.apiUrl = `https://api.open-meteo.com/v1/forecast`
  }

  getWeather(lat:any,lng:any,currentWeather:boolean) : Observable<any>{
    let headers = new HttpHeaders();
    headers.set('Access-Control-Allow-Origin','*');
    return this.http.get("https://api.open-meteo.com/v1/forecast?latitude=25.0324867&longitude=55.1884918&current_weather=true",{headers:headers});
  }

}

