import {Component, Injector, OnDestroy, OnInit} from "@angular/core";
import {BaseDashboardComponent} from "./base-dashboard.component";
import {
  GroupingState,
  IFilterView,
  IGroupingView,
  ISearchView,
  ISortView,
  PaginatorState,
  SortState
} from "./models/crud-table-model";
import {FormControl, FormGroup} from "@angular/forms";
import {Subscription} from "rxjs";
import {PopUpActionEnum} from "../config/enum/pop-up-action.enum";
import {debounceTime, distinctUntilChanged} from "rxjs/operators";
import {TableModel} from "../../modules/shared-module/models/table.model";
import {FilterModel} from "./models/filter.model";

@Component({
  template: '',
})
export class BaseTableComponent extends BaseDashboardComponent implements OnDestroy, OnInit,
  ISortView,
  IFilterView,
  IGroupingView,
  ISearchView,
  IFilterView {

  paginator: PaginatorState;
  sorting: SortState;
  grouping: GroupingState;
  isLoading: boolean;
  filterGroup: FormGroup;
  searchGroup: FormGroup;
  total = 0
  eventAction: any;
  configTable: TableModel;
  fieldFilterForm: FilterModel[] = [];
  globalIndex = -1;


  protected subscriptions: Subscription[] = [];

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.configTable?.haveSearchTerm) {
      this.searchForm();
    }
    if (this.fieldFilterForm.length !== 0) {
      this.filterForm();
    }
  }


  sort(column: string): void {
  }

  btnActionClick(event: any) {
    this.eventAction = event;
    switch (event.action) {
      case PopUpActionEnum.EDIT:
        this.edit()
        break;
      case PopUpActionEnum.DELETE:
        this.delete()
        break;
      case PopUpActionEnum.DETAILS:
        this.details()
        break;
      default:
        break;
    }
  }

  edit() {
  }

  delete() {
  }

  details() {

  }

  //  Function for form
  searchForm() {
    this.searchGroup = this.fb.group({
      searchTerm: [''],
    });
    const searchEvent = this.searchGroup.controls.searchTerm.valueChanges
      .pipe(
        /*
      The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator,
      we are limiting the amount of server requests emitted to a maximum of one every 150ms
      */
        debounceTime(150),
        distinctUntilChanged()
      )
      .subscribe((val) =>
        // you need to make override for that
        this.search(val)
      );
    this.subscriptions.push(searchEvent);
  }

  filterForm() {
    this.filterGroup = this.fb.group(
      this.toFormGroup()
    );
    Object.keys(this.filterGroup.controls).forEach(
      key => {
        const control = this.filterGroup.controls[key] as FormControl;
        if (control instanceof FormControl) {
          this.subscriptions.push(
            control.valueChanges.subscribe(
              () => {
                this.filter();
              }
            )
          )
        }
      }
    )
  }

  filter() {
    const filter: { [key: string]: any } = {}
    Object.keys(this.filterGroup.controls).forEach(
      key => {
        const value = this.filterGroup.get(key)?.value;
        if (value !== '-1') {
          let isExit = false;
          this.fieldFilterForm.forEach(
            field => {
              if (field.formControlName === key) {
                isExit = true;
              }
            }
          )
          if (value && isExit) {
            filter[key] = value;
          }
        }
      }
    )
    this.pushFilterToService(filter);
  }

  pushFilterToService(filter: any) {

  }

  toFormGroup() {
    const group: any = {};

    this.fieldFilterForm.forEach(field => {
      group[field.formControlName] = new FormControl(field.value ? field.value : '',)
    });
    return group;
  }

  search(searchTerm: string): void {
  }

  getStatus(i: any) {
    return i === this.globalIndex;
  }
}
