import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Observable, of, Subscription} from 'rxjs';
import {catchError, finalize, map, switchMap} from 'rxjs/operators';
import {UserModel} from '../models/user.model';
import {AuthModel} from '../models/auth.model';
import {AuthHTTPService} from './auth-http';
import {environment} from 'src/environments/environment';
import {Router} from '@angular/router';
import {MessagingService} from "../../../core/services/messaging.service";
import {ConfigService} from "../../../core/services/config.service";

export type UserType = UserModel | undefined;

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  // private fields
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;
  private deviceId = 'device_id';
  // public fields
  currentUser$: Observable<UserType>;
  isLoading$: Observable<boolean>;
  currentUserSubject: BehaviorSubject<UserType>;
  isLoadingSubject: BehaviorSubject<boolean>;

  get currentUserValue(): UserType {
    return this.currentUserSubject.value;
  }

  set currentUserValue(user: UserType) {
    this.currentUserSubject.next(user);
  }

  constructor(
    private authHttpService: AuthHTTPService,
    private router: Router,
    private messagingService: MessagingService,
    private configService: ConfigService
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.currentUserSubject = new BehaviorSubject<UserType>(undefined);
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
    const subscr = this.getUserByToken().subscribe();
    this.unsubscribe.push(subscr);
  }

  // public methods
  login(email: string, password: string): Observable<UserType> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.login(email, password).pipe(
      map((auth: any) => {
        // const myId = crypto.randomUUID();
        localStorage.setItem(this.deviceId, "123qweasdzxc");
        const result = this.setAuthFromLocalStorage(auth);
      }),
      switchMap(() => this.getUserByToken()),
      catchError((err) => {
        console.error('err', err);
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  logout() {
    this.configService.configuration.next([]);
    localStorage.removeItem(this.authLocalStorageToken);
    localStorage.removeItem(this.deviceId);
    this.messagingService.firebaseToken = null;
    this.currentUserSubject.next(undefined)
    this.router.navigate(['auth/login'], {
      queryParams: {},
    });
  }

  refreshToken() {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.refreshToken) {
      return of(undefined);
    }
    this.isLoadingSubject.next(true);
    return this.authHttpService.refreshToken(auth).pipe(
      map((auth: AuthModel) => {
        const result = this.setAuthFromLocalStorage(auth);
      }),
      switchMap(() => this.getUserByToken()),
      catchError((err) => {
        console.error('err', err);
        this.logout();
        return of(undefined)
      }),
      finalize(() => this.isLoadingSubject.next(false))
    )
  }

  getUserByToken(): Observable<UserType> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.token) {
      return of(undefined);
    }

    this.isLoadingSubject.next(true);
    return this.authHttpService.getUserByToken(auth.token).pipe(
      map((user: UserType) => {
        if (user) {
          this.currentUserSubject.next(user);
        } else {
          this.logout();
        }
        return user;
      }), catchError((err) => {
        this.logout();
        return of(undefined)
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  // need create new user then login
  registration(user: UserModel): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.createUser(user).pipe(
      map(() => {
        this.isLoadingSubject.next(false);
      }),
      switchMap(() => this.login(user.email, user.password)),
      catchError((err) => {
        console.error('err', err);
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  forgotPassword(email: string): Observable<boolean> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .forgotPassword(email)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  // private methods
  private setAuthFromLocalStorage(auth: AuthModel): boolean {
    //amer
    // store auth authToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    if (auth && auth.token) {
      localStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth));
      return true;
    }
    return false;
  }

  getAuthFromLocalStorage(): AuthModel | undefined {
    try {
      const lsValue = localStorage.getItem(this.authLocalStorageToken);
      //amer
      if (!lsValue) {
        return undefined;
      }

      const authData = JSON.parse(lsValue);
      return authData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  verifyToken(token: string): Observable<boolean> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .verifyToken(token)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  resetPassword(password: string, token: string): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .resetPassword(password, token)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  createEmailForNewUser(email: string): Observable<boolean> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .createEmailForNewUser(email)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

  putUserFirebaseToken(): Observable<any> {
    const auth = this.getAuthFromLocalStorage();


    const deviceId = localStorage.getItem('device_id');

    const firebaseToken = this.messagingService.getFirebaseToken();
    if (!firebaseToken) {
      return of(undefined);
    }
    this.isLoadingSubject.next(true);
    return this.authHttpService.putUserFirebaseToken(auth?.token, firebaseToken, deviceId).pipe(
      map((value: any) => {
        if (value) {
        } else {
          this.logout();
        }
        return value;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }
}
