export class UIState {

  /**
   * Is http something is loading
   */
  private _isLoading = false;

  /**
   * Are form fields disabled
   */
  private _isDisabled = false;

  constructor(isLoading: boolean = false, isDisabled: boolean = false) {
    this._isLoading = isLoading;
    this._isDisabled = isDisabled;
  }

  /**
   * Set is loading state
   * @param _isLoading boolean
   */

  setIsLoading(_isLoading: boolean = true): void {
    this._isLoading = _isLoading;
  }

  get isLoading(): boolean {
    return this._isLoading;
  }

  /**
   * Set is disabled state
   * @param _isDisabled boolean
   */
  setIsDisabled(_isDisabled: boolean = true): void {
    this._isDisabled = _isDisabled;
  }

  get isDisabled(): boolean {
    return this._isDisabled;
  }
}
