import {Component, Injector, OnInit} from '@angular/core';
import {NotiModel} from "../../../../../core/models/noti.model";
import {BaseTableComponent} from "../../../../../core/base/base-table.component";

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss']
})
export class NotificationListComponent extends BaseTableComponent implements OnInit {
  notifications: NotiModel[] = [];

  constructor(injector: Injector) {
    super(injector);
    this.incidentService.setDefaults()
    this.notificationService.paginator.init()
    this.paginator = this.notificationService.paginator
    this.notificationService.fetch();
    this.notificationService.isLoading$.subscribe(
      res => {
        this.isLoading = res;
      }
    )
  }

  ngOnInit() {
    super.ngOnInit();
    this.seeAll();
    this.notificationService.resetItems()
    this.notificationService.items$.subscribe(
      res=>{
        this.notifications = [...this.notifications,...res]
      }
    )
  }

  scrollEnd($event: any) {
    this.paginator.start += 10;
    this.notificationService.patchState({paginator: this.paginator})
  }

  close() {
    this.offCanvasService.dismiss();
  }

  clear() {
    this.notificationService.clear().subscribe(
      res => {
        this.offCanvasService.dismiss();
      }
    )
  }

  seeAll() {
    this.notificationService.seeAll().subscribe(
      res => {
      }
    )
  }

  fullScreen() {
    this.offCanvasService.dismiss();
    this.router.navigateByUrl(this.Client.notifications());
  }


  protected readonly event = event;
}
