<div class="d-flex flex-column flex-center bgi-no-repeat rounded-top px-9 py-10"
  [style.background-image]="'url(./assets/media/misc/menu-header-bg.jpg)'">
  <h3 class="text-white fw-bold mb-3">Quick Links</h3>
  <span class="badge bg-primary py-2 px-3">25 pending tasks</span>
</div>

<div class="row g-0">
  <div class="col-6">
    <a class="
        d-flex
        flex-column flex-center
        h-100
        p-6
        bg-hover-light
        border-end border-bottom
        cursor-pointer
      ">
      <app-keenicon name="euro" class="fs-3x text-primary mb-2"></app-keenicon>
      <span class="fs-5 fw-bold text-gray-800 mb-0">Accounting</span>
      <span class="fs-7 text-gray-400">eCommerce</span>
    </a>
  </div>

  <div class="col-6">
    <a class="
        d-flex
        flex-column flex-center
        h-100
        p-6
        bg-hover-light
        border-bottom
        cursor-pointer
      ">
      <app-keenicon name="sms" class="fs-3x text-primary mb-2"></app-keenicon>
      <span class="fs-5 fw-bold text-gray-800 mb-0">Administration</span>
      <span class="fs-7 text-gray-400">Console</span>
    </a>
  </div>

  <div class="col-6">
    <a class="
        d-flex
        flex-column flex-center
        h-100
        p-6
        bg-hover-light
        border-end
        cursor-pointer
      ">
      <app-keenicon name="abstract-41" class="fs-3x text-primary mb-2"></app-keenicon>
      <span class="fs-5 fw-bold text-gray-800 mb-0">Projects</span>
      <span class="fs-7 text-gray-400">Pending Tasks</span>
    </a>
  </div>

  <div class="col-6">
    <a class="
        d-flex
        flex-column flex-center
        h-100
        p-6
        bg-hover-light
        cursor-pointer
      ">
      <app-keenicon name="briefcase" class="fs-3x text-primary mb-2"></app-keenicon>
      <span class="fs-5 fw-bold text-gray-800 mb-0">Customers</span>
      <span class="fs-7 text-gray-400">Latest cases</span>
    </a>
  </div>
</div>

<div class="py-2 text-center border-top">
  <a routerLink="/crafted/pages/profile" class="btn btn-color-gray-600 btn-active-color-primary">
    View All
    <app-keenicon name="arrow-right" class="fs-5"></app-keenicon>
  </a>
</div>
