import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {SocketTypeEnum} from "../models/socket-type.enum";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class MyWebSocketService {
  webSocket: WebSocket;
  messageSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  SOCKET_URL = '';
  SOCKET_TYPE: SocketTypeEnum | '' = '';
  authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;


  constructor() {
  }

  connect() {
    this.webSocket = new WebSocket(this.SOCKET_URL);
  }

  sendMessage(message: string) {
    if (this.webSocket.readyState === WebSocket.OPEN) {
      this.webSocket.send(message);
    } else {
      console.error('WebSocket is not open. Unable to send message.');
    }
  }

  getMessage(): Observable<string> {
    return this.messageSubject.asObservable();
  }

  disConnect() {
    this.webSocket.close();
  }

  addTokenForUrl() {
    const tokenData = localStorage.getItem(this.authLocalStorageToken);

    const {token} = JSON.parse(tokenData ?? '');

    this.SOCKET_URL = `${this.SOCKET_URL}?Authorization=${token}`
  }
}
