<!-- Search toolbar -->
<div class="d-flex align-items-center" [ngClass]="toolbarButtonMarginClass">
  <div class="btn btn-icon btn-active-light-primary position-relative" [ngClass]="toolbarButtonHeightClass"
       data-kt-menu-trigger="click" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end"
       data-kt-menu-flip="bottom">
    <app-keenicon name="magnifier" class="" [ngClass]="toolbarButtonIconSizeClass"></app-keenicon>
  </div>
  <!--  <app-search-result-inner></app-search-result-inner>-->
</div>

<!-- Activities -->
<div class="d-flex align-items-center" [ngClass]="toolbarButtonMarginClass">
  <!-- begin::Drawer toggle -->
  <div class="btn btn-icon btn-active-light-primary" [ngClass]="toolbarButtonHeightClass" id="kt_activities_toggle">
    <app-keenicon name="chart-simple" class="" [ngClass]="toolbarButtonIconSizeClass"></app-keenicon>
  </div>
  <!-- end::Drawer toggle -->
</div>

<!-- NOTIFICATIONS -->
<div class="d-flex align-items-center" [ngClass]="toolbarButtonMarginClass">
  <div class="btn btn-icon btn-active-light-primary position-relative" [ngClass]="toolbarButtonHeightClass"
       data-kt-menu-trigger="click" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end"
       data-kt-menu-flip="bottom">
    <app-keenicon name="element-plus" class="" [ngClass]="toolbarButtonIconSizeClass"></app-keenicon>
  </div>
  <app-notifications-inner></app-notifications-inner>
</div>

<!-- CHAT -->
<div class="d-flex align-items-center" [ngClass]="toolbarButtonMarginClass">
  <!-- begin::Menu wrapper -->
  <div class="btn btn-icon btn-active-light-primary position-relative" [ngClass]="toolbarButtonHeightClass"
       id="kt_drawer_chat_toggle">
    <app-keenicon name="message-text-2" class="" [ngClass]="toolbarButtonIconSizeClass"></app-keenicon>
    <span class="
        bullet bullet-dot
        bg-success
        h-6px
        w-6px
        position-absolute
        translate-middle
        top-0
        start-50
        animation-blink
      "></span>
  </div>
  <!-- end::Menu wrapper -->
</div>

<!-- Quick links -->
<div class="d-flex align-items-center" [ngClass]="toolbarButtonMarginClass">
  <div class="btn btn-icon btn-active-light-primary" [ngClass]="toolbarButtonHeightClass" data-kt-menu-trigger="click"
       data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end" data-kt-menu-flip="bottom">
    <app-keenicon name="element-11" class="" [ngClass]="toolbarButtonIconSizeClass"></app-keenicon>
  </div>
  <app-quick-links-inner></app-quick-links-inner>
</div>

<!-- begin::Theme mode -->
<app-theme-mode-switcher class="d-flex align-items-center" [ngClass]="toolbarButtonMarginClass"
                         toggleBtnClass="{`btn-active-light-primary btn-custom ${toolbarButtonHeightClass}`}">
</app-theme-mode-switcher>
<!-- end::Theme mode -->

<!-- begin::User -->
<div class="d-flex align-items-center" [ngClass]="toolbarButtonMarginClass" id="kt_header_user_menu_toggle">
  <!-- begin::Toggle -->
  <div class="cursor-pointer symbol" [ngClass]="toolbarUserAvatarHeightClass" data-kt-menu-trigger="click"
       data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end" data-kt-menu-flip="bottom">
    <img src="./assets/media/avatars/300-1.jpg" alt="metronic"/>
  </div>
  <!-- end::Toggle  -->
  <app-user-inner></app-user-inner>
</div>
<!-- end::User -->

<ng-container *ngIf="headerLeft === 'menu'">
  <div class="d-flex align-items-center d-lg-none ms-2 me-n3" title="Show header menu">
    <div class="
        btn btn-icon btn-active-light-primary
        w-30px
        h-30px
        w-md-40px
        h-md-40px
      " id="kt_header_menu_mobile_toggle">
      <app-keenicon name="text-align-left" class="fs-1"></app-keenicon>
    </div>
  </div>
</ng-container>
