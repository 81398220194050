import {Injectable} from "@angular/core";
import {MarkerClusterer} from "@googlemaps/markerclusterer";
import {BehaviorSubject} from "rxjs";

const minZoom: number = 5;

@Injectable({
  providedIn: 'root',
})
export class GoogleService {

  googleMap: google.maps.Map | any;
  googleMapInstance: any;
  cluster: MarkerClusterer;
  advanceMarker: google.maps.marker.AdvancedMarkerElement[] = [];
  direction: any;
  centerBetweenPoints: any;
  center = {lat: 25.1300, lng: 55.2500};
  showMapAfterLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  polygons: google.maps.Polygon[] = [];

  /**
   *
   * @param index
   * @param zoom
   */
  initMap(index: string, zoom: number): void {
    const mapDisable = document.getElementById('disable-map');
    if (!this.googleMap) {
      if (mapDisable) {
        this.googleMap = new google.maps.Map(mapDisable, {
          center: this.center,
          zoom: 12,
          mapId: '3ed95c00f684da8e',
          disableDefaultUI: false,
          fullscreenControl: false,
          panControl: true,
          zoomControl: false,
          streetViewControl: false,
          mapTypeControl: false,
          minZoom: 2,
          maxZoom: 30
        });

        this.googleMapInstance = this.googleMap.getDiv().children[0];


      }
    }
    this.getMap(index, zoom)
  }

  /**
   *
   * @param index
   * @param zoom
   */
  getMap(index: string, zoom: number) {
    if (this.direction)
      this.direction.setMap(null)
    this.googleMap.setZoom(zoom);
    this.googleMap.panTo(this.center);
    this.clearMarker();
    const map = document.getElementById('map-' + index);
    const newDiv = document.createElement('div');
    newDiv.setAttribute('class', 'map-container');
    newDiv.style.cssText = 'height: 500px; width: 500px; position: relative; overflow: hidden;';
    map?.appendChild(newDiv);
    if (map) {
      newDiv.append(this.googleMapInstance);
      this.setupMarkerClustering();
    }
    if (!this.showMapAfterLoaded.value) {
      google.maps.event.addListenerOnce(this.googleMap, 'tilesloaded', () => {
        this.showMapAfterLoaded.next(true)
      });
    }
  }

  resetMap() {
    this.clearMarker();
    this.setupMarkerClustering();
    this.googleMap = null;
    this.googleMapInstance = null;
    if (this.direction)
      this.direction.setMap(null);
  }

  /**
   *
   * @param latitude
   * @param longitude
   * @param content
   */
  addAdvancedMarker(latitude: number,
                    longitude: number,
                    content: Element,
  ): Promise<google.maps.marker.AdvancedMarkerElement | null> {

    return this.getAdvancedMarker()
      .then(() => {
        let marker = new google.maps.marker.AdvancedMarkerElement({
          position: {lat: latitude, lng: longitude},
          map: this.googleMap,
          content: content,
        });
        this.advanceMarker.push(marker)
        return marker;
      })
      .catch((error) => {
        console.error("Error loading marker library:", error);
        return null;
      });
  }

  smoothZoom(zoom: number, max: number) {
    if (zoom > max) {
      return;
    }
    let z = google.maps.event.addListener(this.googleMap, 'zoom_changed', (event: any) => {
      google.maps.event.removeListener(z);
      this.smoothZoom(zoom + 0.5, max);
    });
    setTimeout(() => {
      this.googleMap.setZoom(zoom)
    }, 50); // 80ms is what I found to work well on my system -- it might not work well on all systems
  }


  async getAdvancedMarker() {
    const markerLibrary: any = await google.maps.importLibrary("marker");
    const {AdvancedMarkerElement} = markerLibrary;
    return AdvancedMarkerElement;
  }

  clearMarker() {
    for (let i = 0; i < this.advanceMarker.length; i++) {
      const advancedMarker = this.advanceMarker[i];
      advancedMarker.content = null;
      advancedMarker.map = null;
      this.cluster?.removeMarker(this.advanceMarker[i])
    }
    this.advanceMarker = [];
  }

  promiseClearMarker(): Promise<any> {
    return new Promise<any>(
      (resolve: any) => {
        for (let i = 0; i < this.advanceMarker.length; i++) {
          const advancedMarker = this.advanceMarker[i];
          advancedMarker.content = null;
          advancedMarker.map = null;
          this.cluster?.removeMarker(this.advanceMarker[i])
        }
        this.advanceMarker = [];
        resolve();
      }
    )
  }

  setupMarkerClustering(): any {

    this.cluster = new MarkerClusterer(
      {
        map: this.googleMap,
        markers: this.advanceMarker,
        renderer: this.renderer,
        algorithmOptions: {maxZoom: 25}

      });
    this.cluster.addListener('click', (cluster: any) => {
      this.changeZoomByClickOnCluster(cluster);
    });
  }

  /**
   * Svg for cluster
   */
  svg = window.btoa(`<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
              <rect width="230" height="230" fill="#89949a" rx="50" ry="50" />
              </svg>`);

  /**
   * For change the style of cluster
   */
  renderer = {
    render: (marker: any) => {
      let countFromMarker = 0
      marker.markers.forEach(
        (item: any) => {
          if (item.content.getAttribute('count')) {
            countFromMarker += parseInt(item.content.getAttribute('count'), 10) - 1;
          }
        }
      )
      const {count, position} = marker;

      return new google.maps.Marker({
        label: {text: String(count + countFromMarker), color: "white", fontSize: "16px"},
        position,
        icon: {
          url: `data:image/svg+xml;base64,${this.svg}`,
          scaledSize: new google.maps.Size(50, 50),
        },
        zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
      });
    }
  };

  /**
   *
   * @param point1
   * @param point2
   * @description To make direction between two points
   */
  addDirections(point1: google.maps.Point, point2: google.maps.Point) {
    let directionService = new google.maps.DirectionsService();
    let directionRenderer = new google.maps.DirectionsRenderer();
    this.direction = directionRenderer;
    let haight = new window.google.maps.LatLng(point1.x, point1.y);
    let oceanBeach = new window.google.maps.LatLng(point2.x, point2.y);
    directionRenderer.setOptions({
      markerOptions: {
        icon: {
          url: '',
          scaledSize: new google.maps.Size(0, 0)
        }
      },
      preserveViewport: true
    })
    directionRenderer.setMap(this.googleMap)
    let request = {
      origin: haight,
      destination: oceanBeach,
      travelMode: google.maps.TravelMode.DRIVING
    };
    directionService.route(request, function (response, status) {
      if (status == 'OK') {
        directionRenderer.setDirections(response);
        if (response) {
          const route = response.routes[0];
        }
      }
    })
  }

  // Functions to change zoom
  /**
   *
   * @param event array of markers
   */
  changeZoomByClickOnCluster(event: any) {

    let markersInCluster = event.markers;
    let totalLat = 0;
    let totalLng = 0;
    markersInCluster.forEach(function (marker: any) {
      totalLat += marker.position.lat;
      totalLng += marker.position.lng;
    });
    let clusterLat = totalLat / markersInCluster.length;
    let clusterLng = totalLng / markersInCluster.length;
    const maxDistance = this.calculateMaxDistance(markersInCluster);
    const zoomLevel = this.calculateZoomLevelFromDistance(maxDistance);
    let currentZoom = this.googleMap.getZoom()
    if (currentZoom) {
      this.googleMap.panTo({lat: clusterLat, lng: clusterLng});
      setTimeout(
        () => {
          if (currentZoom)
            this.smoothZoom(currentZoom, zoomLevel)
        }, 100
      )
    }
  }

  getCenterBetweenMultiMarkers(markers: any) {
    let totalLat = 0;
    let totalLng = 0;
    markers.forEach(function (marker: any) {
      totalLat += marker.position.lat;
      totalLng += marker.position.lng;
    });
    if (markers.length > 0) {
      let Lat = totalLat / markers.length;
      let Lng = totalLng / markers.length;
      this.googleMap.panTo({lat: Lat, lng: Lng});
    }

  }

  /**
   *
   * @param markers
   * @description Max distance between two markers
   */
  calculateMaxDistance(markers: any) {
    let point1;
    let point2;
    let maxDistance = 0;
    for (const marker of markers) {
      for (const otherMarker of markers) {
        const distance = this.calculateDistance(marker.position, otherMarker.position);
        if
        (distance > maxDistance) {
          point1 = marker.position;
          point2 = otherMarker.position;
          this.calculateMidpoint(point1, point2)
          maxDistance = distance;
        }
      }
    }
    return maxDistance;
  }

  /**
   *
   * @param point1
   * @param point2
   */
  calculateMidpoint(point1: any, point2: any) {
    const lat1 = point1.lat;
    const lng1 = point1.lng;
    const lat2 = point2.lat;
    const lng2 = point2.lng;
    const midLat = (lat1 + lat2) / 2;
    const midLng = (lng1 + lng2) / 2;
    this.centerBetweenPoints = new google.maps.LatLng(midLat, midLng);
  }

  /**
   *
   * @param position1
   * @param position2
   */
  calculateDistance(position1: any, position2: any) {
    return google.maps.geometry.spherical.computeDistanceBetween(position1, position2);
  }

  /**
   *
   * @param distance
   */
  calculateZoomLevelFromDistance(distance: any) {
    const earthRadius = 6371000; // Earth's radius in meters
    const metersPerPixel = 156543.03392 * Math.cos(0) / Math.pow(2, 21);
    const zoomLevel = Math.round(
      Math.log2((earthRadius * 2 * Math.PI) / (metersPerPixel * distance))
    );
    return Math.max(0, zoomLevel - 2);
  }

  /**
   *
   */
  zoomOut() {
    let zoomNow = this.googleMap.getZoom();
    if (typeof zoomNow === 'number') {
      if (zoomNow + 0.5 >= minZoom)
        this.googleMap.setZoom(zoomNow + 1);
    }
  }

  /**
   *
   */
  zoomIn() {
    let zoomNow = this.googleMap.getZoom();
    if (typeof zoomNow === 'number') {
      if (zoomNow - 0.5 >= minZoom)
        this.googleMap.setZoom(zoomNow - 1);
    }
  }

  resetZoom() {
    this.googleMap.setZoom(12)
  }


  ///////// Draw Poly /////////
  drawPoly(options: google.maps.PolygonOptions, coords: any[]) {
    let poly = new google.maps.Polygon({
      paths: coords,
      ...options
    });
    poly.setMap(this.googleMap);
    this.polygons.push(poly);
    return poly;
  }

  removePolygons() {
    this.polygons.map(
      poly => {
        poly.setMap(null);
      }
    )
    this.polygons = [];
  }

}
