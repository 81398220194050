import {APP_INITIALIZER, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {HttpClientInMemoryWebApiModule} from 'angular-in-memory-web-api';
import {ClipboardModule} from 'ngx-clipboard';
import {TranslateModule} from '@ngx-translate/core';
import {InlineSVGModule} from 'ng-inline-svg-2';
import {NgbActiveModal, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {environment} from 'src/environments/environment';
import {ConstantVars} from "./core/config/constant-vars";
// #fake-start#
import {FakeAPIService} from './_fake/fake-api.service';
import {ErrorCatchingInterceptorService} from "./modules/errors/interceptor/error-catching-interceptor.service";

// #fake-end#
import {ToastrModule} from 'ngx-toastr';
import {AngularFireMessagingModule} from "@angular/fire/compat/messaging";
import {AngularFireModule} from "@angular/fire/compat";

import {AuthInterceptor} from './modules/auth/services/interceptor/auth-interceptor.service';
import {LoggerModule, NgxLoggerLevel} from "ngx-logger";
import {DatePipe} from "@angular/common";
import {MatNativeDateModule} from "@angular/material/core";
import {MatSelectModule} from "@angular/material/select";
import {MatDialogModule} from "@angular/material/dialog";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {LayoutModule} from "./_metronic/layout";
import {AuthService} from "./modules/auth";
import {HttpCancelInterceptor} from "./modules/errors/interceptor/http-cancel-interceptor.service";


function appInitializer(authService: AuthService) {
  return () => {
    return new Promise((resolve: any) => {
      authService.getUserByToken().subscribe(res => {
      }).add(resolve);
    });
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    MatSelectModule,
    BrowserModule,
    ReactiveFormsModule, FormsModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot(),
    ToastrModule.forRoot({
      closeButton: true,
      timeOut: 15000, // 15 seconds
      progressBar: true,
      positionClass: 'toast-top-center',
    }),
    LoggerModule.forRoot({
      // serverLoggingUrl: '/api/logs',
      level: NgxLoggerLevel.DEBUG,
      serverLogLevel: NgxLoggerLevel.ERROR
    }),
    HttpClientModule,
    ClipboardModule,
    // #fake-start#
    environment.isMockEnabled
      ? HttpClientInMemoryWebApiModule.forRoot(FakeAPIService, {
        passThruUnknownUrl: true,
        dataEncapsulation: false,
      })
      : [],
    // #fake-end#
    AppRoutingModule,
    InlineSVGModule.forRoot(),
    NgbModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    MatNativeDateModule,
    MatDialogModule,
    MatDatepickerModule,
    LayoutModule

  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [AuthService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorCatchingInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      multi: true,
      provide: HTTP_INTERCEPTORS,
      useClass: HttpCancelInterceptor
    },
    ConstantVars,
    DatePipe,
    NgbActiveModal
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
