import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {Client} from '../../../../../core/config/client';
import {TranslationService} from "../../../../../modules/i18n";
import {AuthService} from "../../../../../modules/auth";
import {LayoutService} from "../../../core/layout.service";

@Component({
  selector: 'app-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.scss']
})
export class SidebarMenuComponent implements OnInit {

  email: string;


  constructor(public authService: AuthService, public translateService: TranslationService, public cdRef: ChangeDetectorRef, public layoutService: LayoutService) {
  }

  selectedLanguage = '';

  ngOnInit(): void {
    this.selectedLanguage = this.translateService.getSelectedLanguage();
    this.authService.currentUser$.subscribe(
      (res: any) => {
        if (res)
          this.email = res.profile.email;
        this.cdRef.detectChanges();
      }
    )
  }

  logOut() {
    this.authService.logout();
    this.layoutService.toogle.next(false)
  }

  protected readonly Client = Client;

  changeLanguage() {
    this.translateService.setLanguage(this.selectedLanguage)
  }
}
