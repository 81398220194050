<div class="h-100">
  <div class="position-close-button">
    <app-close-button (closeClick)="close()"></app-close-button>
  </div>

  <div class="d-flex flex-column justify-content-between h-90vh pt-5">

    <div class="align-items-start p-5 ">
      <div class="d-flex align-items-center justify-content-between">
        <div class="align-items-start fs-22 fw-500">
          <img class="h-2rem me-2 " [src]="'./assets/media/microspot-icon/alert.svg'"/>
          New alert
        </div>
        <div class="align-items-end" *ngIf="(notificationService.items$ | async)?.length !== 0">
          <button class="btn bg-white-opactiy padding-width color-error-500 fs-14 me-5"
                  (click)="clear()">Clear All
          </button>
          <button class="btn bg-white-opactiy padding-width  fs-14"
                  (click)="fullScreen()"><span class="svg-icon svg-icon-3 me-5"
                                               [inlineSVG]="'./assets/media/microspot-icon/full-screen-white.svg'"></span>Full
            Screen
          </button>
        </div>
      </div>
    </div>
    <div class="h-80vh overflow-y-auto align-items-end scroll-color" (scrollend)="scrollEnd($event)"
         *ngIf="notifications.length !== 0">
      <ng-container *ngFor="let notification of notifications;last as isLast;">
        <app-notification-card (notiClick)="close()" [notification]="notification"></app-notification-card>
        <div class="p-5 radius-10 m-5 justify-content-center align-items-center d-flex" *ngIf="isLoading &&  isLast">
          <div
            class="spinner-grow"
            style="width: 3rem; height: 3rem;"
            role="status"
          >
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </ng-container>

    </div>
    <div class="h-80vh align-items-center d-flex" *ngIf="notifications.length === 0">
      <div class="d-flex p-5 w-100 h-40px align-items-center justify-content-center text-center">
        <p class="opacity-40 fs-16 m-0">Not available notifications.</p>
      </div>
    </div>
  </div>

</div>
