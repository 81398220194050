import {Component, EventEmitter, Injector, Input, Output} from '@angular/core';
import {NotiModel} from "../../../../../core/models/noti.model";
import {BaseDashboardComponent} from "../../../../../core/base/base-dashboard.component";
import {NotificationTypeEnum} from "../../../../../core/config/enum/notification-type.enum";
import {Client} from "../../../../../core/config/client";
import {convertUTCDateToLocalDate} from "../../../../../core/config/helper";

@Component({
  selector: 'app-notification-card',
  templateUrl: './notification-card.component.html',
  styleUrls: ['./notification-card.component.scss']
})
export class NotificationCardComponent extends BaseDashboardComponent {
  @Input() notification: NotiModel;
  @Output() notiClick: EventEmitter<any> = new EventEmitter<any>();

  constructor(injector: Injector) {
    super(injector);
  }

  calculateTimeDifferenceForNoti(notiDate: any) {
    notiDate = convertUTCDateToLocalDate(new Date(notiDate))
    let currentDate = new Date();
    let pastDate = new Date(notiDate);
    const millisecondsDiff = currentDate.getTime() - pastDate.getTime();
    const secondsDiff = Math.floor(millisecondsDiff / 1000); // Convert to seconds

    if (secondsDiff < 60) {
      return '1 Min';
    } else if (secondsDiff < 3600) {
      const minutesDiff = Math.floor(secondsDiff / 60);
      return `${minutesDiff} ${this.selectedLanguage === 'ar' ? 'دقيقة مضت' : 'Min'}`;
    } else if (secondsDiff < 86400) {
      const hoursDiff = Math.floor(secondsDiff / 3600);
      return `${hoursDiff} ${this.selectedLanguage === 'ar' ? 'ساعة مضت' : 'Hours'}`;
    } else {
      const daysDiff = Math.floor(secondsDiff / 86400);
      return `${daysDiff} ${this.selectedLanguage === 'ar' ? 'يوم مضى' : 'Days'}`;
    }
  }

  getNotificationImage() {
    switch (this.notification.notificationType) {
      case NotificationTypeEnum.AREA_SENSITIVITY_SETTING_EXPIRATION:
        return './assets/media/microspot-icon/location.svg';
      case NotificationTypeEnum.NEW_INCIDENT:
        return './assets/media/microspot-icon/wanted.svg';
      case NotificationTypeEnum.UPDATE_INCIDENT_STATUS:
        return './assets/media/microspot-icon/wanted.svg';
      case NotificationTypeEnum.PATROL_STATUS:
        return './assets/media/microspot-icon/battery.svg';
      case NotificationTypeEnum.UPDATE_INCIDENT_STATUS:
        return './assets/media/microspot-icon/location.svg';
      case NotificationTypeEnum.NEW_TRACKING:
        return './assets/media/microspot-icon/track-select.svg'
    }
  }

  getDynamicKey() {
    return Object.keys(this.notification.data.dynamicData)
  }

  getDynamicValue() {
    return Object.values(this.notification.data.dynamicData)
  }

  onNotificationClick() {
    switch (this.notification.notificationType) {
      case NotificationTypeEnum.NEW_INCIDENT:
      case NotificationTypeEnum.UPDATE_INCIDENT_STATUS:
        this.notiClick.emit(true);
        if (this.notification.data.staticData['incidentId']) {
          this.router.navigateByUrl(Client.incidentDetail(this.notification.data.staticData['incidentId']));
        }
        break;
      default:
        this.notiClick.emit(true);
        break;
    }
  }
}
