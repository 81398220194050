import {TableService} from "../../../core/services/table.service";
import {environment} from "../../../../environments/environment";
import {CaseFileModel} from "../../incident-map/models/incident/case-file/case-file.model";
import {Inject, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {TableHTTPService} from "../../../core/services/table-http/table-http.service";
import {Observable} from "rxjs";
import {AlarmModel} from "../models/alarm.model";

@Injectable({
  providedIn: 'root'
})
export class AlarmService extends TableService<AlarmModel> {

  API_URL = `${environment.apiUrl}/iot/microspot-integration/alarms`;
  caseFile: CaseFileModel;

  constructor(@Inject(HttpClient) http: HttpClient,
              @Inject(TableHTTPService) protected tablehttpservice: TableHTTPService<AlarmModel>) {
    super(http, tablehttpservice);
  }

  getAlarmDetail(id: any): Observable<any> {
    return this.http.get(`${this.API_URL}/${id}`);
  }

  getAlarms(status: string[]): Observable<any> {
    let statuses = {
      statuses: status
    }
    return this.http.post(`${this.API_URL}`, statuses);
  }

  updateStatus(id: string, status: string) {
    return this.http.post(`${this.API_URL}/status-update`, {id: id, status: status})
  }
}
