import {Injectable, Injector} from '@angular/core';
import {BaseService} from "./base.service";
import { Observable, of } from 'rxjs';
import { HttpHeaders,HttpParams } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class VerificationEmailService extends BaseService {
  constructor(injector : Injector) {
    super(injector)
    this.apiUrl = `${this.apiUrl}/api/auth/request-verification`
  }
  resendEmailVerification(email:string): Observable<any>{
    const queryParams = new HttpParams().set('email', email);
    const urlWithParams = `${this.apiUrl}?${queryParams.toString()}`;
    
    return this.http.get(urlWithParams, {});
  }
}

