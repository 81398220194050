<!--begin::Menu wrapper-->
<div id="kt_app_sidebar_menu_wrapper"
     class="app-sidebar-wrapper h-100 hover-scroll-overlay-y my-5 w-100 d-flex flex-column justify-content-between overflow-y-auto"
     data-kt-scroll="true"
     data-kt-scroll-activate="true" data-kt-scroll-height="auto"
     data-kt-scroll-dependencies="#kt_app_sidebar_logo, #kt_app_sidebar_footer"
     data-kt-scroll-wrappers="#kt_app_sidebar_menu" data-kt-scroll-offset="5px" data-kt-scroll-save-state="true"
     style="box-shadow: 0px 3px 50px #00000080;"
>
  <!--begin::Menu-->
  <div class="menu menu-column menu-rounded menu-sub-indention " id="#kt_app_sidebar_menu" data-kt-menu="true"
       data-kt-menu-expand="false">
    <div class="menu-item" [routerLink]="Client.baseIncidentMap()" routerLinkActive="active"
         (click)="layoutService.toogle.next(false)">
      <a class="menu-link without-sub cursor-pointer">
        <span class="activated"></span>
        <span class="menu-icon">
          <span
            [inlineSVG]="'./assets/media/microspot-icon/location.svg'"
            class="svg-icon svg-icon-2"
          ></span>
        </span>
        <span class="menu-title cursor-pointer fs-18 " translate="MENU.INCIDENTS_MONITOR"></span>
      </a>
    </div>
    <div class="menu-item" [routerLink]="Client.areasMap()" routerLinkActive="active"
         (click)="layoutService.toogle.next(false)">
      <a class="menu-link without-sub cursor-pointer">
        <span class="activated"></span>
        <span class="menu-icon">
          <span
            [inlineSVG]="'./assets/media/microspot-icon/setting.svg'"
            class="svg-icon svg-icon-2"
          ></span>
        </span>
        <span class="menu-title cursor-pointer fs-18 " translate="MENU.AREAS_SENSITIVITY"></span>
      </a>
    </div>
    <div class="menu-item" [routerLink]="Client.mapWithCamera()" routerLinkActive="active"
         (click)="layoutService.toogle.next(false)">
      <a class="menu-link without-sub cursor-pointer">
        <span class="activated"></span>
        <span class="menu-icon">
          <span
            [inlineSVG]="'./assets/media/microspot-icon/surviliance-white.svg'"
            class="svg-icon svg-icon-2"
          ></span>
        </span>
        <span class="menu-title cursor-pointer fs-18 " translate="MENU.SURVEILLANCE"></span>
      </a>
    </div>
    <div class="menu-item" [routerLink]="Client.incidents()" routerLinkActive="active"
         (click)="layoutService.toogle.next(false)">
      <a class="menu-link without-sub cursor-pointer">
        <span class="activated"></span>
        <span class="menu-icon">
          <span
            [inlineSVG]="'./assets/media/microspot-icon/incidents-white.svg'"
            class="svg-icon svg-icon-2"
          ></span>
        </span>
        <span class="menu-title  cursor-pointer fs-18 " translate="MENU.INCIDENTS"></span>
      </a>
    </div>
    <div class="menu-item menu-item-last" [routerLink]="Client.criminals()" routerLinkActive="active"
         (click)="layoutService.toogle.next(false)">
      <a class="menu-link without-sub cursor-pointer">
        <span class="activated"></span>
        <span class="menu-icon">
          <span
            [inlineSVG]="'./assets/media/microspot-icon/person-wanted-white.svg'"
            class="svg-icon svg-icon-2"
          ></span>
        </span>
        <span class="menu-title cursor-pointer fs-18 " translate="MENU.CRIMINAL_BASE"></span>
      </a>
    </div>

    <span class="position-relative pb-10">
      <hr class="position-fixed w-100 right-0">
    </span>
    <!--    Patrol-->
    <div class="menu-item">
          <span
            [inlineSVG]="'./assets/media/microspot-icon/m_portal.svg'"
          ></span>
    </div>

    <div class="menu-item " [routerLink]="Client.robotsMapList()" routerLinkActive="active"
         (click)="layoutService.toogle.next(false)">
      <a class="menu-link without-sub cursor-pointer">
        <span class="activated"></span>
        <span class="menu-icon">
          <span
            [inlineSVG]="'./assets/media/microspot-icon/my_robots.svg'"
            class="svg-icon svg-icon-2"
          ></span>
        </span>
        <span class="menu-title cursor-pointer fs-18 " translate="MENU.ROBOTS"></span>
      </a>
    </div>
    <div class="menu-item " [routerLink]="Client.missionsList()" routerLinkActive="active"
         (click)="layoutService.toogle.next(false)">
      <a class="menu-link without-sub cursor-pointer">
        <span class="activated"></span>
        <span class="menu-icon">
          <span
            [inlineSVG]="'./assets/media/microspot-icon/missions.svg'"
            class="svg-icon svg-icon-2"
          ></span>
        </span>
        <span class="menu-title cursor-pointer fs-18 " translate="MENU.MISSIONS"></span>
      </a>
    </div>
  </div>
  <div class="  w-100 pb-2 ">
    <div class="bg-white-opactiy p-3 ">
      <small class="text-white opacity-50">
        {{"MENU.ACCOUNT" | translate}}
      </small>
      <p class="fs-16 text-white m-0 account">{{email}}</p>
    </div>
    <div class="mt-3 w-100 logout">
      <button
        class="btn p-2 w-100 fs-18 bg-custom-primary-500 text-white justify-content-center text-center radius-10 logout"
        (click)="logOut()">
        {{"MENU.LOG_OUT" | translate}}
      </button>
    </div>
    <div class="pt-5 w-100 d-flex align-items-center">
      <img *ngIf="selectedLanguage === 'en'" class="w-2vh radius-50 object-fit-cover"
           src="./assets/media/flags/united-kingdom.svg">
      <img *ngIf="selectedLanguage === 'ar'" class="w-2vh radius-50 object-fit-cover"
           src="./assets/media/flags/united-arab-emirates.svg">

      <select class="border-0 bg-none p-5 fs-18 text-white w-100 select-side-bar" [(ngModel)]="selectedLanguage"
              (change)="changeLanguage()">
        <option value="en" class="bg-black text-white fw-bold">
          English
        </option>
        <option value="ar" class="bg-black text-white fw-bold">
          Arabic
        </option>
      </select>
    </div>
    <div class="justify-content-center d-flex text-center pt-7">
      <p class="opacity-20 fs-14 text-white ">Microspot . 2023</p>
    </div>
  </div>
</div>
