import {Directive, ElementRef, EventEmitter, HostListener, Output,} from '@angular/core';

@Directive({
  selector: '[outsideClick]',
})
export class OutsideClickDirective {
  @Output()
  outsideClick: EventEmitter<MouseEvent> = new EventEmitter();

  @HostListener('document:mousedown', ['$event'])
  onClick(event: MouseEvent): void {
    let checkSelect = false;
    let checkSideBar = false;
    if (typeof (event.target as Element)?.className === 'string' && !(event.target as Element)?.className?.includes('logout')) {
      if (typeof (event.target as Element)?.className === 'string' && (event.target as Element)?.className?.includes('select-side-bar')) {
        checkSelect = true;
      }
      if (checkSelect) {
      } else {
        if (typeof (event.target as Element)?.className === 'string' && ((event.target as Element)?.className?.includes('app-sidebar-wrapper'))) {
          checkSideBar = true;
        }
        if (checkSideBar) {
        } else if (typeof (event.target as Element)?.className === 'string' && (
          ((event.target as Element)?.className?.includes('app-sidebar-wrapper') ||
            !(event.target as Element)?.className?.includes('menu-title') ||
            (event.target as Element)?.className?.includes('menu-item') ||
            (event.target as Element)?.className?.includes('menu-link')
          ))) {

          this.outsideClick.emit(event);
        }
      }
    }


  }

  constructor(private elementRef: ElementRef) {
  }
}
