import {Injectable, Injector} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {BaseService} from "./base.service";
import {TableHTTPService} from "./table-http/table-http.service";
@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseService {

  constructor(injector : Injector) {
    super(injector)
    this.apiUrl = `${this.apiUrl}/api/users`
  }

  changeStatus(id:any) : Observable<any>{
    return this.tableService.active(this.apiUrl,id)
  }

}

