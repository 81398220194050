import {ActivatedRoute, Router} from '@angular/router';
import {ChangeDetectorRef, Component, Injector, NgZone, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {of} from 'rxjs';
import {TranslateService} from "@ngx-translate/core";
import {UIState} from "./models/ui-state.model";
import {AbstractBaseComponent} from "./models/abstract-base.component";
import {DatePipe} from "@angular/common";
@Component({template: ''})
export class BaseComponent<U extends UIState = UIState> extends AbstractBaseComponent implements OnInit, OnDestroy {



  constructor(protected injector: Injector) {
    super();
    this.route = this.injector.get(ActivatedRoute);
    this.router = this.injector.get(Router);
    this.renderer2 = this.injector.get(Renderer2);
    this.cdRef = this.injector.get(ChangeDetectorRef);
    this.http = injector.get(HttpClient);
    this.math = Math;
    this.of = of;
    this.object = Object;
    this.state = new UIState();
    this.translateService = this.injector.get(TranslateService);
    this.datePipe = this.injector.get(DatePipe);
    this.ngZone = this.injector.get(NgZone);
  }


  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
