export const CALENDAR = 'calendar';
export const USERS = 'users';
export const ADMINS = 'admins';
export const EMPLOYEES = 'employees';
export const DIPLOMATIC_USERS = 'diplomatic-users';
export const EXTERNAL = 'external';
export const AUTH = 'auth';
export const LOGIN = 'login'
export const FORGOT_PASSWORD = 'forgot-password'
export const PROFILE = 'profile'
export const VERIFY_ACCOUNT = 'verify-account'
export const DOCUMENT = 'document';
export const INCIDENT = 'incident';
export const INCIDENTS = 'incidents';
export const CRIMINALS = 'criminals';

export const MAP = 'map';
export const AREAS_SENSITIVITY = 'areas-sensitivity';
export const AREAS = 'areas';
export const INCIDENT_DETAIL = 'incident-detail';
export const SUSPECT_DETAIL = 'suspect-detail';

export const TRACKING_HISTORY = 'tracking-history';
export const LIST = 'list';

export const LIVE = 'live';
export const SURVEILLANCE = 'surveillance'

export const PINNED_LIST = 'pinned-list';
export const FILTERED_CAMERAS = 'filtered-cameras';

export const PATROL = 'patrol';
export const PATROL_DETAIL = 'patrol-detail';

export const ALARM = 'alarm';

export const NOTIFICATIONS = 'notifications';


export const ROBOTS = 'robots';
export const HMI_LOADING = 'hmi-loading';
export const MISSIONS = 'missions';
export const MISSION_DESIGN = 'mission-design'
export const MAP_DETAILS = 'map-details'
export const MAP_EDITOR = 'map-editor'

export const TELE_OPERATOR = 'tele-operator';
export const PREVIEW = 'preview';
