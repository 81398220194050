export enum StatusEnum{
  ACTIVE = 'Active',
  IN_ACTIVE = 'InActive'
}
export enum ButtonDownloadType {
  DOC='doc',
  PDF='pdf',
  IMG='img'
}

export enum IncidentStatusEnum {
  NEW = 'New',
  CANCEL = 'Cancel',
  ACTIVE = 'Active'
}
