export function formUrl(...slugs: string[]) {
  return '/' + slugs.join('/');
}

export function encryptContact(contact: string, start: number, end: number): string {
  // Email
  if (contact.length <= start + end) {
    return contact;
  }
  if (contact.includes('@')) {
    const atIndex = contact.indexOf('@');
    const username = contact.slice(0, atIndex);
    const domain = contact.slice(atIndex);

    if (username.length <= start + end) {
      return contact;
    }
    const visibleChars = username.slice(0, start);
    const hiddenChars = '*'.repeat(username.length - start - end);
    const visibleEndChars = username.slice(-end);

    const maskedUsername = visibleChars + hiddenChars + visibleEndChars;
    return maskedUsername + domain;

  }
  //phone
  else if (contact.includes('+')) {
    const atIndex = contact.indexOf(' ');
    const visibleChars = contact.slice(0, start + atIndex);
    const hiddenChars = '*'.repeat(contact.length - start - end);
    const visibleEndChars = contact.slice(-end);
    return visibleChars + hiddenChars + visibleEndChars;
  }
  //else
  else {
    const visibleChars = contact.slice(0, start);
    const hiddenChars = '*'.repeat(contact.length - start - end);
    const visibleEndChars = contact.slice(-end);

    return visibleChars + hiddenChars + visibleEndChars;

  }

}

export function decreaseTimeByOneSecond(time: any): string {
  const [minutes, seconds] = time.split(':').map(Number);
  let totalSeconds = minutes * 60 + seconds;
  if (totalSeconds > 0) {
    totalSeconds -= 1;
  }

  const updatedMinutes = Math.floor(totalSeconds / 60);
  const updatedSeconds = totalSeconds % 60;

  const formattedMinutes = updatedMinutes.toString().padStart(2, '0');
  const formattedSeconds = updatedSeconds.toString().padStart(2, '0');

  return `${formattedMinutes}:${formattedSeconds}`;
}

export function getLocale() {
  return localStorage.getItem('language') ?? 'en';
}

export function convertUTCDateToLocalDate(date: any) {
  return new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
}

export function calculateTimeDifference(currentDate: Date, pastDate: Date, selectedLanguage: string = "en") {
  pastDate = convertUTCDateToLocalDate(new Date(pastDate));
  const millisecondsDiff = currentDate.getTime() - pastDate.getTime();
  const secondsDiff = Math.floor(millisecondsDiff / 1000); // Convert to seconds
  if (secondsDiff < 60) {
    if (secondsDiff < 0) {
      return `1 ${selectedLanguage === 'ar' ? 'دقيقة مضت' : 'm ago'}`;
    }
    return `${secondsDiff} ${selectedLanguage === 'ar' ? 'ثانية مضت' : 's ago'}`;
  } else if (secondsDiff < 3600) {
    const minutesDiff = Math.floor(secondsDiff / 60);
    return `${minutesDiff} ${selectedLanguage === 'ar' ? 'دقيقة مضت' : 'm ago'}`;
  } else if (secondsDiff < 86400) {
    const hoursDiff = Math.floor(secondsDiff / 3600);
    return `${hoursDiff} ${selectedLanguage === 'ar' ? 'ساعة مضت' : 'hours ago'}`;
  } else {
    const daysDiff = Math.floor(secondsDiff / 86400);
    return `${daysDiff} ${selectedLanguage === 'ar' ? 'يوم مضى' : 'days ago'}`;
  }
}

// export function getClusterLiveMarkerWithImage(count: number, labelClassName: string, time: any, images: any[]) {
//     const labelElement = document.createElement('div');
//     labelElement.setAttribute("count", count.toString())
//     const divImageElement = document.createElement('div');
//     divImageElement.style.cssText = "display:flex;justify-content:center;padding-bottom:5px;width:8rem";
//     let imageList = ``;
//     images.forEach((imageSrc, index) => {
//         imageSrc = imageSrc.replace('10.20.0.30', '94.206.14.42')
//         imageList += `<swiper-slide class="w-100 p-0 m-0 h-100 d-block">
//                     <div class="h-100 w-100 ">
//                       <img class=" radius-5 h-100 w-100 object-fit-cover" loading="eager" src="${imageSrc}">
//                     </div>
//                   </swiper-slide>`
//     });
//
//     labelElement.innerHTML = `
//   <swiper-container style="width:8rem !important;height:75px;display:block" class="mySwiper radius-5 pb-2"  navigation="false" space-between="0"
//     centered-slides="true" autoplay-delay="2500" autoplay-disable-on-interaction="true">
//     ${imageList}
//   </swiper-container>
// <div class="w-100 h-100 justify-content-center d-grid pt-2">
//                                 <p class="${labelClassName} fs-14 cursor-pointer">${time}</p>
//                                 <div class="w-100 d-flex justify-content-center ">
//                                   <p class='h-30px m-0 w-30px fs-16 fw-500 bg-black radius-50 cursor-pointer align-content-center justify-content-center d-grid' >${count}</p>
//                                 </div>
//                               </div>`;
//     labelElement.prepend(divImageElement);
//     return labelElement;
// }

// export function getClusterLiveMarkerWithoutImage(count: number, labelClassName: string, time: any) {
//     const labelElement = document.createElement('div');
//     labelElement.setAttribute("count", count.toString())
//     labelElement.innerHTML = `<div class="w-100 h-100 justify-content-center d-grid ">
//                                 <p class="${labelClassName} cursor-pointer fs-14">${time}</p>
//                                 <div class="w-100 d-flex justify-content-center ">
//                                    <p class='h-30px m-0 w-30px fs-16 fw-500 bg-black radius-50 cursor-pointer align-content-center justify-content-center d-grid' >${count}</p>
//                                 </div>
//                               </div>`;
//     return labelElement;
// }

// export function getClusterMarker(count: number) {
//     const labelElement = document.createElement('div');
//     labelElement.setAttribute("count", count.toString())
//     labelElement.innerHTML = `<div class="w-100 h-100 justify-content-center d-grid ">
//                                 <div class="w-100 d-flex justify-content-center ">
//                                    <p class='h-30px m-0 w-30px fs-16 fw-500 bg-black radius-50 cursor-pointer align-content-center justify-content-center d-grid' >${count}</p>
//                                 </div>
//                               </div>`;
//     return labelElement;
// }

// export function getMarkerLiveWithImage(labelClassName: string, time: any, images: any[]) {
//     const labelElement = document.createElement('div');
//     const divImageElement = document.createElement('div');
//     divImageElement.style.cssText = "display:flex;justify-content:center;padding-bottom:5px;width:8rem";
//     let imageList = ``;
//     images.forEach((imageSrc, index) => {
//         imageSrc = imageSrc.replace('10.20.0.30', '94.206.14.42')
//         imageList += `<swiper-slide class="w-100 p-0 m-0 h-100 d-block">
//                     <div class="h-100 w-100 ">
//                       <img class=" radius-5 h-100 w-100 object-fit-cover" loading="eager" src="${imageSrc}">
//                     </div>
//                   </swiper-slide>`
//     });
//     images.forEach((imageSrc, index) => {
//         imageSrc = imageSrc.replace('10.20.0.30', '94.206.14.42')
//         imageList += `<swiper-slide class="w-100 p-0 m-0 h-100 d-block">
//                     <div class="h-100 w-100 ">
//                       <img class=" radius-5 h-100 w-100 object-fit-cover" loading="eager" src="${imageSrc}">
//                     </div>
//                   </swiper-slide>`
//     });
//
//     labelElement.innerHTML = `
//   <swiper-container style="width:8rem !important;height:75px;display:block" class="mySwiper radius-5 pb-2"   navigation="false" space-between="0"
//     centered-slides="true" showPagination="false" autoplay-delay="1500" autoplay-disable-on-interaction="true">
//     ${imageList}
//   </swiper-container>
// <div class="w-100 h-100 justify-content-center d-grid">
//                                 <p class="${labelClassName} fs-14 cursor-pointer mb-1r">${time}</p>
//                               </div>`;
//     labelElement.prepend(divImageElement);
//
//     return labelElement;
// }

// export function getMarkerLiveWithImageAndWithOutLabel(image: any = null, id = 0) {
//     const labelElement = document.createElement('div');
//     labelElement.innerHTML = `<div id="marker-${id}" class="w-100 h-100 justify-content-center d-grid">
//                                 <img  class="w-100 h-40px object-fit-cover radios-10 pb-2 cursor-pointer" src="${image}">
//                               </div>`;
//     return labelElement;
// }
//
// export function getMarkerLiveWithOutImage(labelClassName: string, time: any) {
//     const labelElement = document.createElement('div');
//     labelElement.innerHTML = `<div class="w-100 h-100 justify-content-center d-grid ">
//                                 <p class="${labelClassName} cursor-pointer fs-14 mb-1">${time}</p>
//                               </div>`;
//     return labelElement;
// }
//
// export function getMarkerForNewIncident(labelClassName: string, time: any) {
//     const labelElement = document.createElement('div');
//     labelElement.innerHTML = `<div class="w-100 h-100 justify-content-center d-grid ">
//                                 <p class="${labelClassName} cursor-pointer fs-14 mb-1">${time}</p>
//                               </div>`;
//     return labelElement;
// }
//
// export function getMarkerForTracking(labelClassName: string, time: any) {
//     const labelElement = document.createElement('div');
//     labelElement.innerHTML = `<div class="w-100 h-100 justify-content-center d-grid ">
//                                 <p class="${labelClassName} cursor-pointer fs-14 mb-1">${time}</p>
//                                 <div class="w-100 justify-content-center d-flex align-content-center">
//                                 <img class="h-40px" src="../../../assets/media/microspot-icon/dark-location.svg">
//                                 </div>
//                               </div>`;
//     return labelElement;
// }
//
// export function getMarkerForPerson(labelClassName: string, time: any, image: string) {
//     const labelElement = document.createElement('div');
//     labelElement.innerHTML = `<div class="w-100 h-100 justify-content-center d-grid ">
//                                 <div class="d-flex align-content-center h-100">
//                                 <div class="h-100 w-25px me-1">
//                                 <img class="w-100 h-30px object-fit-cover radius-5 me-1 " src="${image}" ">
//
// </div>
// <div>
//                                 <p class="${labelClassName} cursor-pointer fs-14 mb-1">${time}</p>
// <div class="w-100 justify-content-center d-flex align-content-center">
//                                 <img class="h-50px" src="../../../assets/media/microspot-icon/dark-location.svg">
//                                 </div>
//                                 </div>
//                                 </div>
//                               </div>`;
//     return labelElement;
// }
//
// export function getMarkerForUnknownPerson(labelClassName: string, time: any) {
//     const labelElement = document.createElement('div');
//     labelElement.innerHTML = `<div class="w-100 h-100 justify-content-center d-grid ">
//                                 <div class="d-flex align-content-center h-100">
//                          <div class="h-30px radius-5 w-25px bg-black mx-1 d-grid align-content-center justify-content-center">
//                                 <img class="w-15px h-20px" src="../../../assets/media/microspot-icon/person-wanted.svg"">
//
// </div>
// <div>
//                                 <p class="${labelClassName} cursor-pointer mb-1 fs-14">${time}</p>
// <div class="w-100 justify-content-center d-flex align-content-center">
//                                 <img class="h-50px" src="../../../assets/media/microspot-icon/dark-location.svg">
//                                 </div>
//                                 </div>
//                                 </div>
//                               </div>`;
//     return labelElement;
// }
//
// export function getMarkerForWantedPerson(labelClassName: string, time: any, image: string) {
//     const labelElement = document.createElement('div');
//     labelElement.innerHTML = `<div class="w-100 h-100 justify-content-center d-grid ">
//                                 <div class="d-flex align-content-center h-100">
//                                     <div class="h-100 w-25px me-1">
//                                         <img class="w-100 h-30px object-fit-cover radius-5 me-1 " src="${image}" ">
//                                     </div>
//                                 <div>
//                                 <p class="${labelClassName} cursor-pointer fs-14 mb-1">${time}</p>
//                                     <div class="w-100 justify-content-center d-flex align-content-center">
//                                         <img class="h-50px" src="../../../assets/media/microspot-icon/dark-location.svg">
//                                     </div>
//                                 </div>
//                                            <div class="h-30px radius-5 w-25px bg-error-600 mx-1 d-grid align-content-center justify-content-center">
//                                 <img class="w-15px h-20px" src="../../../assets/media/microspot-icon/wanted.svg"">
//                                 </div>
//                                 </div>
//                               </div>`;
//     return labelElement;
// }
//
// export function getMarkerForAlarm(labelClassName: string, time: any) {
//     const labelElement = document.createElement('div');
//     labelElement.innerHTML = `<div class="w-100 h-100 justify-content-center d-grid ">
//                                 <p class="${labelClassName} cursor-pointer fs-14 mb-1">${time}</p>
//                                 <div class="w-100 justify-content-center d-flex align-content-center">
//                                 <img class="h-50px" src="../../../assets/media/microspot-icon/alarm-with-background.png">
//                                 </div>
//                               </div>`;
//     return labelElement;
// // }
//
// export function getMarkerForIncidentTracking(time: any, image: string[] = []) {
//     const labelElement = document.createElement('div');
//     let images: string;
//     if (image.length === 0) {
//         labelElement.innerHTML = `<div class="w-100 h-100 justify-content-center cursor-pointer d-grid position-relative">
//
//                                 <div class="d-flex align-content-center h-100">
//
//                                     <div>
//                                 <p class="marker-label-tra
//                                 cking cursor-pointer fs-14 mb-1">${time}</p>
//                                 <div class="w-100 justify-content-center d-flex align-content-center">
//                                 <img class="h-50px" src="../../../assets/media/microspot-icon/track-not-select.svg">
//                                 </div>
//                                 </div>
//                                 </div>
//                               </div>`;
//     } else if (image.length === 1) {
//         images = `<img class="w-100 h-30px object-fit-cover radius-5 me-1 " src="${image}">`
//         labelElement.innerHTML = `<div class="w-100 h-100 justify-content-center cursor-pointer d-grid position-relative">
//
//                                 <div class="d-flex align-content-center h-100">
//                                 <div class="h-100 w-25px me-1">
//                                     ${images}
//                                     </div>
//                                     <div>
//                                 <p class="marker-label-tracking cursor-pointer fs-14 mb-1">${time}</p>
//                                 <div class="w-100 justify-content-center d-flex align-content-center">
//                                 <img class="h-50px" src="../../../assets/media/microspot-icon/track-not-select.svg">
//                                 </div>
//                                 </div>
//                                 </div>
//                               </div>`;
//     } else {
//         images = image.map(img => `<img class="w-45px h-45px object-fit-cover radius-5 me-1 " src="${img}" (error)="event.target.src ='../../../assets/media/microspot-icon/person-error.svg'">`).join('');
//         labelElement.innerHTML = `<div class="w-100 h-100 justify-content-center cursor-pointer d-grid track-hover position-relative">
//                             <div class="position-absolute box-images bg-dark-opacity-40 p-3 radius-5" style="left: 100%">
//                             <div class="d-flex align-content-center">
//                             ${images}
//                                 <div>
//                                 </div>
//                                 <div class="d-flex align-content-center h-100">
//                                     <div>
//                                 <p class="marker-label-tracking cursor-pointer fs-14 mb-1">${time}</p>
//                                 <div class="w-100 justify-content-center d-flex align-content-center">
//                                 <img class="h-50px" src="../../../assets/media/microspot-icon/track-not-select.svg">
//                                 </div>
//                                 </div>
//                                 </div>
//                               </div>`;
//
//     }
//
//     return labelElement;
// }
//
// export function getMarkerForSelectedTracking(time: any, image: string[] = []) {
//     const labelElement = document.createElement('div');
//     let images: string;
//     if (image.length === 1) {
//         images = `<img class="w-100 h-30px object-fit-cover radius-5 me-1 " src="${image}">`
//         labelElement.innerHTML = `<div class="w-100 h-100 justify-content-center cursor-pointer d-grid position-relative">
//
//                                 <div class="d-flex align-content-center h-100">
//                                 <div class="h-100 w-25px me-1">
//                                 ${images}
//                                     </div>
//                                 <div>
//                                 <p class="marker-label-tracking cursor-pointer fs-14 mb-1">${time}</p>
//                                     <div class="w-100 justify-content-center d-flex align-content-center">
//                                 <img class="h-50px" src="../../../assets/media/microspot-icon/track-select.svg">
//                                 </div>
//                                 </div>
//                                 </div>
//                               </div>`;
//     } else {
//         images = image.map(img => `<img class="w-45px h-45px object-fit-cover radius-5 me-1 " src="${img}" (error)="event.target.src ='../../../assets/media/microspot-icon/person-error.svg'">`).join('');
//         labelElement.innerHTML = `<div class="w-100 h-100 justify-content-center cursor-pointer d-grid track-hover position-relative">
//                             <div class="position-absolute box-images bg-dark-opacity-40 p-3 radius-5" style="left: 100%">
//                                 <div class="d-flex align-content-center">
//                                     ${images}
//                                  </div>
//                                     </div>
//                                 <div class="d-flex align-content-center h-100">
//                                 <div>
//                                 <p class="marker-label-tracking cursor-pointer fs-14 mb-1">${time}</p>
//                                 <div class="w-100 justify-content-center d-flex align-content-center">
//                                     <img class="h-50px" src="../../../assets/media/microspot-icon/track-select.svg">
//                                 </div>
//                                 </div>
//                                 </div>
//                               </div>`;
//
//     }
//
//     return labelElement;
// }
//
// export function getMarkerImage(image: string) {
//     const labelElement = document.createElement('div');
//     labelElement.innerHTML = `<div class="w-100 h-100 justify-content-center d-grid ">
//                                 <div class="w-100 justify-content-center d-flex align-content-center">
//                                 <img class="h-50px" src="${image}">
//                                 </div>
//                               </div>`;
//     return labelElement;
// }

// export function getMarkerForPatrol(title: string) {
//   const labelElement = document.createElement('div');
//   labelElement.innerHTML = `<div class="w-100 h-100 justify-content-center d-grid ">
//                                 <div class="w-100 justify-content-center d-flex align-content-center">
//                                 <img class="h-40px" src="../../../assets/media/microspot-icon/patrol-border.svg">
//                                 </div>
//                                                                 <p class="patrol-border cursor-pointer fs-14 m-0">${title}</p>
//                               </div>`;
//   return labelElement;
// }


export function blendMultipleHexColors(colors: string[]): string {
  if (colors.length === 0) {
    return '#000000';
  }
  const hexToRgb = (hex: string): number[] => [
    parseInt(hex.slice(1, 3), 16),
    parseInt(hex.slice(3, 5), 16),
    parseInt(hex.slice(5, 7), 16)
  ];

  // Initialize blended color components
  let blendedColor: number[] = [0, 0, 0];

  // Blend colors iteratively
  colors.forEach(color => {
    const currentColor = hexToRgb(color);
    blendedColor = blendedColor.map((channel, index) => channel + currentColor[index]);
  });

  // Calculate the average of blended color components
  blendedColor = blendedColor.map(channel => Math.round(channel / colors.length));

  // Convert blended color components to hex color string
  const rgbToHex = (rgb: number[]): string =>
    "#" + rgb.map(channel => channel.toString(16).padStart(2, "0")).join("");

  return rgbToHex(blendedColor);
}


// Html for marker patrol
// export function getMarkerForKeyOffPatrol(labelClassName: string, type: any, name: any, text: any) {
//   const labelElement = document.createElement('div');
//   labelElement.innerHTML = `<div class="w-100 h-100 justify-content-center d-grid ">
//                                 <p class="${labelClassName} cursor-pointer fs-18 mb-1">
//                                 <span class="fw-medium">${type}</span>
//                                 <span class="fw-bold mb-2 mx-2">.</span>
//                                 <span class="fw-bold me-2">${name}</span>
// <span class="opacity-50">${text}</span></p>
//                                <div class="w-100 justify-content-center d-flex align-content-center image-marker-patrol-detail">
//                             <img style="background: black;border-radius: 25px" class="h-35px p-2 " src="../../../assets/media/microspot-icon/patrol/key-off-small.svg">
//                                 </div>
//                               </div>`;
//   return labelElement;
// }
//
//
// export function getMarkerForPatrolRobot(ClassName: string,
//                                         batteryClass: string,
//                                         type: any, name: any,
//                                         internetSpeed: number,
//                                         batteryNumber: number,
//                                         url: string,
//                                         connectionLevel: number,
//                                         background = '',
//                                         backgroundText = '',
//                                         patrolNet = 'marker-cellular-bar',
//                                         opacity = 1
// ) {
//   const labelElement = document.createElement('div');
//   labelElement.innerHTML = `<div class="w-100 h-100 justify-content-center d-grid ">
//                                 <p class="${ClassName} cursor-pointer  mb-1" >
//                                 <span class="fw-400">${type}</span>
//                                 <span class="fw-bold  mx-2" style="height: 5px;width: 5px;background: ${backgroundText};border-radius: 25px"></span>
//                                 <span class="fw-bold  me-6">${name}</span>
// <span class=" fs-16 fw-500 me-3">${internetSpeed} Mbps</span>
// <span class=" fs-16 me-6 pb-1">
// <span class="marker-cellular-icon" style="opacity: ${opacity}">
//                                 ${Array(connectionLevel).fill('').map(() => `
//                                     <span class="${patrolNet} active"></span>
//                                 `).join('')}
//                                 ${Array(5 - connectionLevel).fill('').map(() => `
//                                     <span class="${patrolNet}"></span>
//                                 `).join('')}
//                               </span>
//
// </span>
// <small class="fs-16 fw-500 me-2" style="letter-spacing: -2px;">${batteryNumber}%</small>
// <span style="--battery-width: ${batteryNumber}%;opacity: ${opacity}" class="${batteryClass}"></span>
// </p>
//                                 <div class="w-100 justify-content-center d-flex align-content-center image-marker-patrol-detail">
//                             <img style="background: ${background};border-radius: 25px" class="h-35px p-2 " src="${url}">
//                                 </div>
//                               </div>`;
//   return labelElement;
// }
//
// export function getMarkerForPatrolRobotCharging(ClassName: string,
//                                                 batteryClass: string,
//                                                 type: any, name: any,
//                                                 internetSpeed: number,
//                                                 batteryNumber: number,
//                                                 url: string,
//                                                 connectionLevel: number,
//                                                 background = '',
//                                                 backgroundText = '',
//                                                 patrolNet = 'marker-cellular-bar',
//                                                 opacity = 1
// ) {
//   const labelElement = document.createElement('div');
//   labelElement.innerHTML = `<div class="w-100 h-100 justify-content-center d-grid ">
//                                 <p class="${ClassName} cursor-pointer  mb-1" >
//                                                                 <span class="me-5"> <img  class="h-30px" src="../../../assets/media/microspot-icon/robot/bgCharging.svg" alt=""/></span>
//
//                                 <span class="fw-400">${type}</span>
//                                 <span class="fw-bold  mx-2" style="height: 5px;width: 5px;background: ${backgroundText};border-radius: 25px"></span>
//                                 <span class="fw-bold  me-6">${name}</span>
// <span class=" fs-16 fw-500 me-3">${internetSpeed} Mbps</span>
// <span class=" fs-16 me-6 pb-1">
// <span class="marker-cellular-icon" style="opacity: ${opacity}">
//                                 ${Array(connectionLevel).fill('').map(() => `
//                                     <span class="${patrolNet} active"></span>
//                                 `).join('')}
//                                 ${Array(5 - connectionLevel).fill('').map(() => `
//                                     <span class="${patrolNet}"></span>
//                                 `).join('')}
//                               </span>
//
// </span>
//
// <small class="fs-16 fw-500 me-1" style="letter-spacing: -2px;color: #1EC451">${batteryNumber}%</small>
// <span style="--battery-width: ${batteryNumber}%;opacity: ${opacity}" class="${batteryClass}"></span>
// </p>
//                                 <div class="w-100 justify-content-center d-flex align-content-center image-marker-patrol-detail">
//                             <img style="background: ${background};border-radius: 25px" class="h-35px p-2 " src="${url}">
//                                 </div>
//                               </div>`;
//   return labelElement;
// }
//
// export function getIcon(url: string) {
//   const labelElement = document.createElement('div');
//   labelElement.innerHTML = `<div  class="w-100 h-100 justify-content-center d-grid ">
//                                                                <div class="w-100 justify-content-center d-flex align-content-center image-marker-patrol">
//
//                             <img style="background: black;border-radius: 25px;" class="h-30px p-2" src="${url}">
//                                 </div>
//                               </div>`;
//   return labelElement;
// }
//
// export function getIconWithoutBackground(url: string) {
//   const labelElement = document.createElement('div');
//   labelElement.innerHTML = `<div  class="w-100 h-100 justify-content-center d-grid ">
//                                                                <div class="w-100 justify-content-center d-flex align-content-center image-marker-patrol">
//
//                             <img  class="h-40px " src="${url}">
//                                 </div>
//                               </div>`;
//   return labelElement;
// }
//
// export function getIconWithColorBackground(url: string, color: string) {
//   const labelElement = document.createElement('div');
//   labelElement.innerHTML = `<div  class="w-100 h-100 justify-content-center d-grid ">
//                                                                <div class="w-100 justify-content-center d-flex align-content-center image-marker-patrol">
//
//                             <img style="background: ${color};border-radius: 25px;" class="h-30px p-2" src="${url}">
//                                 </div>
//                               </div>`;
//   return labelElement;
// }

export function encodeData(data: string) {
  return window.btoa(data)
}

export function decodeData(data: string) {
  return window.atob(data)
}

