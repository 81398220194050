// tslint:disable:variable-name
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, of, Subscription} from 'rxjs';
import {catchError, finalize, tap} from 'rxjs/operators';
import {
  BaseModel,
  GroupingState,
  ITableState,
  PaginatorState,
  SortState,
  TableResponseModel
} from "../base/models/crud-table-model";
import {environment} from '../../../environments/environment';
import {AuthModel} from '../../modules/auth/models/auth.model';
import {TableHTTPService} from '../services/table-http/table-http.service'

const DEFAULT_STATE: ITableState = {
  filter: {},
  paginator: new PaginatorState(),
  sorting: new SortState(),
  searchTerm: '',
  grouping: new GroupingState(),
};

export abstract class TableService<T> {
  // Private fields
  private _items$ = new BehaviorSubject<T[]>([]);
  private _isLoading$ = new BehaviorSubject<boolean>(false);
  private _isFirstLoading$ = new BehaviorSubject<boolean>(true);
  public _tableState$ = new BehaviorSubject<ITableState>(DEFAULT_STATE);
  protected _errorMessage = new BehaviorSubject<string>('');
  private _subscriptions: Subscription[] = [];
  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;
  protected tableHttpService: TableHTTPService<T>;

  // Getters
  get items$() {
    return this._items$.asObservable();
  }

  get isLoading$() {
    return this._isLoading$.asObservable();
  }

  get isFirstLoading$() {
    return this._isFirstLoading$.asObservable();
  }

  get errorMessage$() {
    return this._errorMessage.asObservable();
  }

  get subscriptions() {
    return this._subscriptions;
  }

  // State getters
  get paginator() {
    return this._tableState$.value.paginator;
  }

  get filter() {
    return this._tableState$.value.filter;
  }

  get sorting() {
    return this._tableState$.value.sorting;
  }

  get searchTerm() {
    return this._tableState$.value.searchTerm;
  }

  get grouping() {
    return this._tableState$.value.grouping;
  }

  resetItems() {
    this._items$ = new BehaviorSubject<T[]>([]);
  }

  // API URL has to be overrided
  API_URL = `${environment.apiUrl}/endpoint`;

  http: HttpClient

  constructor(http: HttpClient, tableHttpService: TableHTTPService<T>
  ) {
    this.http = http;
    this.tableHttpService = tableHttpService;
    this.setDefaults();
  }

  // CREATE
  // server should return the object with ID
  create(formData: any): Observable<BaseModel | undefined> {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    return this.tableHttpService.create(this.API_URL, formData).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('CREATE ITEM', err);
        return of(undefined);
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  // READ (Returning filtered list of entities)
  /*  find(tableState: ITableState| string): Observable<TableResponseModel<T>> {
      const url = this.API_URL;
      this._errorMessage.next('');
      return this.http.post<TableResponseModel<T>>(url, tableState, {headers: this.httpHeaders}).pipe(
        catchError(err => {
          this._errorMessage.next(err);
          return of({ items: [], total: 0 });
        })
      );
    }*/
  // setPaginator(p: PaginatorState) {
  //   const paginator = {
  //     page: p.page > 0 ? p.page - 1 : p.page,
  //     size: p.size,
  //   }
  //   return paginator;
  // }

  find(tableState: ITableState): Observable<TableResponseModel<T>> {
    this._errorMessage.next('');
    return this.tableHttpService.findByPost(this.API_URL, tableState).pipe(catchError(err => {
        this._errorMessage.next(err);
        return of({items: [], total: 0});
      })
    );
  }

  getItemById(id: number): Observable<undefined | T> {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    return this.tableHttpService.getItemById(this.API_URL, id).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('GET ITEM BY IT', id, err);
        return of(undefined);
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  // UPDATE
  update(formData: FormData): Observable<any> {
    const itemId = formData.get('id');
    this._isLoading$.next(true);
    this._errorMessage.next('');
    return this.tableHttpService.update(this.API_URL, formData, itemId).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('UPDATE ITEM', itemId, err);
        return of(undefined);
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  // UPDATE Status
  updateStatusForItems(ids: number[], status: number): Observable<any> {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const body = {ids, status};
    return this.tableHttpService.updateStatusForItems(this.API_URL, body).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('UPDATE STATUS FOR SELECTED ITEMS', ids, status, err);
        return of(undefined);
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  updateStatusForItem(data: any): Observable<any> {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const body = data;
    return this.tableHttpService.updateStatusForItem(this.API_URL, body).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('UPDATE STATUS FOR SELECTED ITEM', status, err);
        return of([]);
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  // DELETE
  delete(id: any): Observable<any> {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    return this.tableHttpService.delete(this.API_URL, id).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('DELETE ITEM', id, err);
        return of({});
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  // delete list of items
  deleteItems(ids: number[] = []): Observable<any> {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    return this.tableHttpService.deleteItems(ids).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('DELETE SELECTED ITEMS', ids, err);
        return of([]);
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  // PUBLISH
  publish(id: any, publish: boolean): Observable<any> {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    return this.tableHttpService.publish(this.API_URL, id, publish).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('PUBLISHED ITEM', id, err);
        return of({});
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  // PUBLISH
  active(id: any, status: string): Observable<any> {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    return this.tableHttpService.active(this.API_URL, id).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('ACTIVATED ITEM', id, err);
        return of({});
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  public fetch() {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const request = this.find(this._tableState$.value)
      .pipe(
        tap((res: TableResponseModel<T>) => {
          let total;
          if (res.items) {
            this._items$.next(res.items);
            total = res.total;
          } else {
            this._items$.next([]);
            total = 0;
          }

          this.patchStateWithoutFetch({
            paginator: this._tableState$.value.paginator.recalculatePaginator(
              total
            ),
          });
        }),
        catchError((err) => {
          this._errorMessage.next(err);
          return of({
            items: [],
            total: 0
          });
        }),
        finalize(() => {
          this._isLoading$.next(false);
          const itemIds = this._items$.value.map((el: T) => {
            const item = (el as unknown) as BaseModel;
            return item.id;
          });
          this.patchStateWithoutFetch({
            grouping: this._tableState$.value.grouping.clearRows(itemIds),
          });
        })
      )
      .subscribe();
    this._subscriptions.push(request);
  }

  public setDefaults() {
    this.patchStateWithoutFetch({filter: {}});
    this.patchStateWithoutFetch({sorting: new SortState()});
    this.patchStateWithoutFetch({grouping: new GroupingState()});
    this.patchStateWithoutFetch({searchTerm: ''});
    this.patchStateWithoutFetch({
      paginator: new PaginatorState()
    });
    this._isFirstLoading$.next(true);
    this._isLoading$.next(true);
    this._tableState$.next(DEFAULT_STATE);
    this._errorMessage.next('');
  }

  // Base Methods
  public patchState(patch: Partial<ITableState>, withFetch = true) {
    this.patchStateWithoutFetch(patch);
    if (withFetch)
      this.fetch();
  }

  public patchStateWithoutFetch(patch: Partial<ITableState>) {
    const newState = Object.assign(this._tableState$.value, patch);
    this._tableState$.next(newState);
  }

  // me //

  getAll(body = null): Observable<TableResponseModel<T>> {
    this._errorMessage.next('');
    return this.tableHttpService.getAll(body).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('FIND ITEMS', err);
        return of({items: [], total: 0});
      }),
      finalize(() => {
        this._isLoading$.next(false);
        const itemIds = this._items$.value.map((el: T) => {
          const item = (el as unknown) as BaseModel;
          return item.id;
        });
        return itemIds;
      })
    );
  }

  getAuthToken(): AuthModel | undefined {
    try {
      const authData = JSON.parse(
        localStorage.getItem(this.authLocalStorageToken) as string
      );
      if (authData) {
        return authData.token;
      }
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  // UPDATE
  updateItemWithPost(id: number, formData: FormData, method: string): Observable<any> {
    const itemId = id;
    this._isLoading$.next(true);
    this._errorMessage.next('');
    return this.tableHttpService.updateItemWithPost(this.API_URL, id, formData, method).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('UPDATE ITEM', itemId, err);
        return of(undefined);
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  // UPDATE
  updateWithPost(formData: FormData, method: string): Observable<any> {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    return this.tableHttpService.updateWithPost(this.API_URL, formData, method).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('UPDATE ITEM', err);
        return of(undefined);
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }
}
