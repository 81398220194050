import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {TableService} from "../../../core/services/table.service";
import {environment} from "../../../../environments/environment";
import {TableHTTPService} from "../../../core/services/table-http/table-http.service";
import {map, Observable} from "rxjs";
import {GroupingState, ITableState, PaginatorState, SortState,} from "../../../core/base/models/crud-table-model";
import {CaseFileModel} from "../models/incident/case-file/case-file.model";
import {tap} from "rxjs/operators";
import {IncidentClassificationModel} from "../models/incident/map/incident-classification.model";

@Injectable({
  providedIn: 'root'
})
export class IncidentService extends TableService<IncidentClassificationModel> {

  API_URL = `${environment.apiUrl}/incident/incidents`;
  caseFile: CaseFileModel;

  constructor(@Inject(HttpClient) http: HttpClient,
              @Inject(TableHTTPService) protected tablehttpservice: TableHTTPService<IncidentClassificationModel>) {
    super(http, tablehttpservice);

  }


  // @LoggingDecorator(new LoggingService())
  getIncidentGroupedByClassification(filter: {}, PaginatorState: PaginatorState, SortState: SortState, searchTerm: string = '', GroupingState: GroupingState): Observable<any> {
    let requestBody: ITableState = {
      filter: filter,
      paginator: PaginatorState,
      sorting: SortState,
      searchTerm: searchTerm,
      grouping: GroupingState
    };
    return this.http.post(`${this.API_URL}/view-incidents-map`, requestBody);
  }

  getChartDate(): Observable<any> {
    return this.http.get(`${this.API_URL}/monthly-incident-count-line`)
  }

  getIncidentDetail(id: any): Observable<any> {
    return this.http.get(`${this.API_URL}/${id}`);
  }

  getTrackForMap(id: any, uid?: any, guid?: any): Observable<any> {
    let params = new HttpParams();

    if (uid) {
      params = params.append('uid', uid);
    }

    if (guid) {
      params = params.append('guid', guid);
    }

    return this.http.get(`${this.API_URL}/tracking-map/${id}`, { params });
  }

  getCaseFileByType(id: any, type: string): Observable<any> {
    return this.http.get(`${this.API_URL}/case-files/${type}/${id}`)
  }

  getAllStatus(): Observable<any> {
    return this.http.get(`${this.API_URL}/get-status`)
  }

  updateStatus(id: any, incidentStatus: string): Observable<any> {
    return this.http.put(`${this.API_URL}/change-incident-status/${id}`, {
      incidentStatus: incidentStatus
    })
  }

  getCaseFiles(id: any) {
    this.http.get(`${this.API_URL}/case-files/${id}`).pipe(
      map((data: any) => {
        let caseFile = new CaseFileModel();
        caseFile = data;
        return caseFile;
      })
    ).subscribe(
      (res: CaseFileModel) => {
        this.caseFile = res
      }
    )
  }

  getIncidentsByLatAndLng(lat: number, lng: number, data: any) {
    return this.http.post(`${this.API_URL}/multi-incident-pin?longitude=${lng}&latitude=${lat}`, data)
  }

  getIncidentListByClassification(): Observable<any> {
    return this.http.post(`${this.API_URL}`, this._tableState$.value)
      .pipe(
        tap((res: any) => {
          let total;
          total = res.total;

          this.patchStateWithoutFetch({
            paginator: this._tableState$.value.paginator.recalculatePaginator(
              total
            ),
          });
          return res.items
        }),
        map(res => {
            return res.items;
          }
        )
      )
  }
}
