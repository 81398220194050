<app-splash-screen #splashScreenComponent></app-splash-screen>
<div class="d-flex flex-column flex-root app-root" id="kt_app_root">
  <div class="app-page flex-column flex-column-fluid" id="kt_app_page">

    <div class="app-wrapper flex-column flex-row-fluid" [ngClass]="dispaly ? 'blur-menu-dark-background' : ''"
         id="kt_app_wrapper">
      <ng-container *ngIf="appSidebarDisplay">
        <ng-container>
          <app-sidebar outsideclick (outsideClick)="outMenuClick($event)" [@menuAnimation]="dispaly ? 'open' : 'closed'"
                       #ktSidebar id="kt_app_sidebar"
                       class="app-sidebar flex-column custom-sidebar radius-15 p-7 w-auto" style="min-width: 20vh"
                       [ngClass]="appSidebarDefaultClass">
          </app-sidebar>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="appSidebarPanelDisplay">
        <!-- TODO: app sidebar panel -->
      </ng-container>
      <div class="app-main flex-column flex-row-fluid" id="kt_app_main">
        <div class="d-flex flex-column flex-column-fluid">
          <ng-container *ngIf="appToolbarDisplay">
          </ng-container>
          <app-content id=" kt_app_content" class="app-content" [ngClass]="contentCSSClasses"
                       [contentContainerCSSClass]="contentContainerCSSClass" [appContentContiner]="appContentContiner"
                       [appContentContainerClass]="appContentContainerClass">
          </app-content>
        </div>
      </div>
    </div>
  </div>
</div>

<app-scripts-init></app-scripts-init>
<ng-container>
  <app-scroll-top id="kt_scrolltop" class="scrolltop" data-kt-scrolltop="true"></app-scroll-top>
</ng-container>
<!--<app-activity-drawer></app-activity-drawer>-->
<app-messenger-drawer></app-messenger-drawer>
<app-main-modal></app-main-modal>
<app-invite-users-modal></app-invite-users-modal>
<app-upgrade-plan-modal></app-upgrade-plan-modal>
