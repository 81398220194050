import {Routes} from '@angular/router';
import * as SLUGS from '../core/config/slugs';

const Routing: Routes = [
    {
        path: 'dashboard',
        loadChildren: () =>
            import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
    },
    {
        path: '',
        redirectTo: 'incident/map',
        pathMatch: 'full',
    },
    {
        path: '**',
        redirectTo: 'error/404',
    },
    {
        path: SLUGS.INCIDENT,
        loadChildren: () => import('../modules/incident-map/incident-map.module').then(
            m => m.IncidentMapModule
        )
    },
    {
        path: SLUGS.INCIDENTS,
        loadChildren: () => import('../modules/incidents/incidents.module').then(
            m => m.IncidentsModule
        )
    },
    {
        path: SLUGS.CRIMINALS,
        loadChildren: () => import('../modules/criminal-base/criminal-base.module').then(
            m => m.CriminalBaseModule
        )
    },
    {
        path: SLUGS.AREAS_SENSITIVITY,
        loadChildren: () => import('../modules/areas-sensitivity/areas-sensitivity.module').then(
            m => m.AreasSensitivityModule
        )
    },
    {
        path: SLUGS.SURVEILLANCE,
        loadChildren: () => import('../modules/surveillance/surveillance.module').then(
            m => m.SurveillanceModule
        )
    },
    {
        path: SLUGS.ALARM,
        loadChildren: () => import('../modules/alarm/alarm.module').then(
            m => m.AlarmModule
        )
    },
    {
        path: SLUGS.NOTIFICATIONS,
        loadChildren: () => import('../modules/notifications/notifications.module').then(
            m => m.NotificationsModule
        )
    },
    {
        path: SLUGS.ROBOTS,
        loadChildren: () => import('../modules/robots/robots.module').then(
            m => m.RobotsModule
        )
    },
    {
        path: SLUGS.MISSIONS,
        loadChildren: () => import('../modules/missions/missions.module').then(
            m => m.MissionsModule
        )
    },
    {
        path: SLUGS.TELE_OPERATOR,
        loadChildren: () => import('../modules/tele-operator/tele-operator.module').then(
            m => m.TeleOperatorModule
        )
    }
];

export {Routing};
