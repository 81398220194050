import {Injectable, Injector} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {environment} from "../../../environments/environment";
import {TableHTTPService} from "./table-http/table-http.service";
@Injectable({
  providedIn: 'root'
})
export class BaseService {

  public http: HttpClient;
  public apiUrl = environment.apiUrl;
  public tableService : TableHTTPService<any>;
  constructor(injector : Injector) {
    this.http = injector.get(HttpClient);
    this.tableService = injector.get(TableHTTPService);
  }

}

