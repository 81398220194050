import {environment} from "../../../../environments/environment";
import {CaseFileModel} from "../../incident-map/models/incident/case-file/case-file.model";
import {Injectable, NgZone} from "@angular/core";
import {map, Observable} from "rxjs";
import {EventSourceService} from "../../../core/services/event-source.service";
import {ActiveUsersInterface, RobotModel} from "../model/robot.model";
import {TeleOperatorInterface} from "../../tele-operator/model/tele-operator.interface";
import {Router} from "@angular/router";
import {RobotStatusEnum} from "../../../core/config/enum/robot-status.enum";

@Injectable({
  providedIn: 'root'
})
export class RobotsEventService extends EventSourceService {

  API_URL = `${environment.apiUrl}/robot/robots`;
  caseFile: CaseFileModel;

  constructor(zone: NgZone, router: Router) {
    super(zone, router);
  }


  getMyRobots(): Observable<RobotModel[]> {
    return this.connectToServerSentEvents(`${this.API_URL}/my-robots/sse`, {}, ['my-robot']).pipe(
      map((res: any) => {
        // Map each robot data to RobotModel
        let parsedData = JSON.parse(res.data)
        return parsedData.map((robot: any) => {
          let newRobot = new RobotModel()

          newRobot.init(
            robot.id,
            robot.robotMasterData,
            robot.robotRealTimeData,
            robot.robotDrivingModeData,
          )
          // newRobot.robotDrivingModeData.robotMode = RobotStatusEnum.FREE_DRIVING
          return newRobot
        });
      })
    )
  }

  getMyRobot(id: string): Observable<TeleOperatorInterface> {
    return this.connectToServerSentEvents(`${this.API_URL}/${id}/sse`, {}, ['robot-details']).pipe(
      map((res: any) => {
        let parsedData = JSON.parse(res.data) as TeleOperatorInterface;
        return parsedData;
      })
    )
  }

}
